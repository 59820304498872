import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  Platform,
  BackHandler,
  Alert,
} from "react-native";
import React, { useEffect } from "react";
// import MapView, { Callout, Marker } from "react-native-maps";
import { StatusBar } from "expo-status-bar";

export default function Map({ navigation, route }) {
  const lat = Number(route.params.latitude);
  const long = Number(route.params.longitude);
  const title = route.params.title;

  // useEffect(() => {
  //   console.log(route.params);
  //   const backAction = () => {
  //     navigation.goBack();
  //     return true;
  //   };
  //   const backHandler = BackHandler.addEventListener(
  //     "hardwareBackPress",
  //     backAction
  //   );

  //   return () => backHandler.remove();
  // }, []);
  return (
    <View style={styles.container}>
      <StatusBar style="auto" />
      {/* <MapView
        style={styles.map}
        initialRegion={{
          latitude: lat,
          longitude: long,
          latitudeDelta: 0.005,
          longitudeDelta: 0.005,
        }}
      >
        <Marker coordinate={{ latitude: lat, longitude: long }}>
          <Callout>
            <Text>{title}</Text>
          </Callout>
        </Marker>
      </MapView> */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  map: {
    height: Dimensions.get("screen").height,
    width: Dimensions.get("window").width,
  },
});
