import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Platform,
} from "react-native";
import React, { useState } from "react";
import Button from "../Button";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export default function StepTwo(props) {
  const [selected, setSelected] = useState(props.selected);
  const [customerName, setCustomerName] = useState(props.customerName);
  const [customerLastName, setCustomerLastName] = useState(
    props.customerLastName
  );
  const [customerPhone, setCustomerPhone] = useState(props.customerPhone);

  const userName = useSelector((state) => state.name);
  const userLastName = useSelector((state) => state.lastname);
  const userPhone = useSelector((state) => state.phone);

  const whoWillTake = () => {
    if (selected == 0) {
      setCustomerName(userName);
      props.changeCustomerName(userName);
      setCustomerLastName(userLastName);
      props.changeCustomerLastName(userLastName);
      setCustomerPhone(userPhone);
      props.changeCustomerPhone(userPhone);
      props.changeStep(3);
    } else {
      props.changeStep(3);
    }
  };

  return (
    <View style={{ justifyContent: "space-between", height: "100%" }}>
      <View>
        <Text
          style={{
            fontWeight: "600",
            fontSize: 14,
            lineHeight: 19.07,
            textAlign: "center",
            marginTop: 18,
          }}
        >
          Kargonu kim teslim alacak?
        </Text>
        <TouchableOpacity
          onPress={() => {
            setSelected(0);
            props.changeSelected(0);
          }}
          style={{ alignItems: "center", marginTop: 21 }}
        >
          <View
            style={[
              styles.input,
              { borderColor: selected == 0 ? "#DA3831" : "#E4E4E4" },
            ]}
          >
            <Text
              style={{ fontWeight: "600", fontSize: 14, lineHeight: 19.07 }}
            >
              Kendim Teslim Alacağım
            </Text>
            <View
              style={{
                backgroundColor: selected == 1 ? "#E0D6D3" : "#DA3831",
                width: 15,
                height: 15,
                borderRadius: 20,
                marginRight: 15,
              }}
            />
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setSelected(1);
            props.changeSelected(1);
            setCustomerName("");
            props.changeCustomerName("");
            setCustomerLastName("");
            props.changeCustomerLastName("");
            setCustomerPhone("");
            props.changeCustomerPhone("");
          }}
          style={{ alignItems: "center" }}
        >
          <View
            style={[
              styles.input,
              { borderColor: selected == 1 ? "#DA3831" : "#E4E4E4" },
            ]}
          >
            <Text
              style={{ fontWeight: "600", fontSize: 14, lineHeight: 19.07 }}
            >
              Bir Arkadaşım Teslim Alacak
            </Text>
            <View
              style={{
                backgroundColor: selected == 1 ? "#DA3831" : "#E0D6D3",
                width: 15,
                height: 15,
                borderRadius: 20,
                marginRight: 15,
              }}
            />
          </View>
        </TouchableOpacity>
        {selected == 1 && (
          <>
            <Text
              style={{
                fontWeight: "600",
                fontSize: 14,
                lineHeight: 19.07,
                marginLeft: 20,
                marginTop: 40,
                marginBottom: 20,
              }}
            >
              Teslim Alacak Kişi Bilgileri
            </Text>
            <View style={{ alignItems: "center" }}>
              <TextInput
                value={customerName}
                onChangeText={(e) => {
                  props.changeCustomerName(
                    e.replace(/[^a-zA-ZğçşüöıĞÇŞÜÖİ ]/g, "")
                  );
                  setCustomerName(e.replace(/[^a-zA-ZğçşüöıĞÇŞÜÖİ ]/g, ""));
                }}
                style={[styles.input]}
                placeholder="İsim"
                keyboardType="ascii-capable"
                maxLength={50}
              />
              <TextInput
                value={customerLastName}
                onChangeText={(e) => {
                  props.changeCustomerLastName(
                    e.replace(/[^a-zA-ZğçşüöıĞÇŞÜÖİ ]/g, "")
                  );
                  setCustomerLastName(e.replace(/[^a-zA-ZğçşüöıĞÇŞÜÖİ ]/g, ""));
                }}
                style={[styles.input]}
                placeholder="Soyisim"
                keyboardType="ascii-capable"
                maxLength={50}
              />
              <TextInput
                value={customerPhone}
                onChangeText={(e) => {
                  props.changeCustomerPhone(e.replace(/[^0-9]/g, ""));
                  setCustomerPhone(e.replace(/[^0-9]/g, ""));
                  console.log(customerPhone);
                }}
                style={[styles.input]}
                placeholder="Telefon Numarası"
                maxLength={10}
                keyboardType="number-pad"
                returnKeyType={"done"}
              />
            </View>
          </>
        )}
      </View>
      <View style={{ marginBottom: Platform.OS == "android" ? 140 : 210 }}>
        <Button
          active={
            selected == 0 ||
            (customerName !== "" &&
              customerLastName !== "" &&
              customerPhone.length == 10)
          }
          title={"Devam Et"}
          handleFunc={() => whoWillTake()}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    width: "90%",
    height: 47,
    backgroundColor: "#FAF6F5",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 6,
    borderColor: "#E4E4E4",
    marginBottom: 10,
    paddingLeft: 11,
    fontSize: 14,
    fontWeight: "600",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    width: "90%",
    height: 54,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 31,
    marginTop: 60,
    marginBottom: 31,
  },
});
