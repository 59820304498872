import {
  StyleSheet,
  View,
  Dimensions,
  Platform,
  ScrollView,
  KeyboardAvoidingView,
  Text,
  BackHandler,
} from "react-native";
import React, { useState } from "react";
import { StatusBar } from "expo-status-bar";
import { SafeAreaView } from "react-native-safe-area-context";
import StepStart from "../../components/createPostPage/StepStart";
import StepOne from "../../components/createPostPage/StepOne";
import StepTwo from "../../components/createPostPage/StepTwo";
import StepThree from "../../components/createPostPage/StepThree";
import StepFour from "../../components/createPostPage/StepFour";
import StepFive from "../../components/createPostPage/StepFive";
import StepSix from "../../components/createPostPage/StepSix";
import CustomHeader from "../../components/CustomHeader";
import StepSeven from "../../components/createPostPage/StepSeven";
import StepEight from "../../components/createPostPage/StepEight";
import { useEffect } from "react";

export default function CreatePostPage({ navigation }) {
  const windowHeight = Dimensions.get("window").height;
  const [step, setStep] = useState(0);
  // Step Start
  const [title, setTitle] = useState("");
  const [sendBy, setSendBy] = useState("");
  const [enabled, setEnabled] = useState(false);
  const [city, setCity] = useState(null);
  const [cities, setCities] = useState([]);
  const [region, setRegion] = useState(null);
  const [regions, setRegions] = useState([]);
  const [point, setPoint] = useState(null);
  const [points, setPoints] = useState([]);
  const [centers, setCenters] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [allCenters, setAllCenters] = useState([]);
  const [cityAndRegion, setCityAndRegion] = useState({});
  const [regionAndPoint, setRegionAndPoint] = useState({});
  const [poiID, setPoiID] = useState("");
  const [shipmentCity, setShipmentCity] = useState("");
  const [shipmentRegion, setShipmentRegion] = useState("");
  const [shipmentNeighbour, setShipmentNeighbour] = useState("");
  const [shipmentAddress, setShipmentAddress] = useState("");
  const [shipmentPhone, setShipmentPhone] = useState("");
  // Step One
  const [addressCheck, setAddressCheck] = useState(false);
  // Step Two
  const [selected, setSelected] = useState(0);
  const [customerName, setCustomerName] = useState("");
  const [customerLastName, setCustomerLastName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  // Step Three
  const [isCargo, setIsCargo] = useState(1);
  const [shipmentFirm, setShipmentFirm] = useState("");
  const [shipmentNo, setShipmentNo] = useState("");
  // Step Four
  const [note, setNote] = useState("");
  const [isChecked, setChecked] = useState(false); // Mesafeli satış sözleşmesi
  const [isChecked2, setChecked2] = useState(false); // Gönderinin 30desi ölçüleri
  const [isChecked3, setChecked3] = useState(false); // Gönderinin teslim adresi
  // Step Five
  const [orderID, setOrderID] = useState("");
  const [invoice, setInvoice] = useState(""); // Fatura kesilecek kişi
  const [corporate, setCorporate] = useState(false); // Fatura bireysel mi, kurumsal mı? (false) ise bireysel
  const [identityNo, setIdentityNo] = useState("");
  const [billingCity, setBillingCity] = useState(""); // Fatura adresi il
  const [billingRegion, setBillingRegion] = useState(""); // Fatura adresi ilçe
  const [billingNeighbour, setBillingNeighbour] = useState(""); // Fatura adresi mahalle
  const [billingPhone, setBillingPhone] = useState(""); // Fatura adresi telefon
  const [billingAddress, setBillingAddress] = useState("");
  const [taxNo, setTaxNo] = useState("");
  const [taxAdministration, setTaxAdministration] = useState("");
  const [email, setEmail] = useState("");
  const [billingName, setBillingName] = useState("");
  const [billingLastName, setBillingLastName] = useState("");
  const [invoiceID, setInvoiceID] = useState("");

  // Step Six
  const [cardName, setCardName] = useState("");
  const [cardNo, setCardNo] = useState("");
  const [cardDate, setCardDate] = useState("");
  const [cardCvv, setCardCvv] = useState("");
  const [html, setHtml] = useState("");
  const [paymentId, setPaymentId] = useState(null);
  const [cardUserKey, setCardUserKey] = useState("");
  const [cardToken, setCardToken] = useState("");
  const [cards, setCards] = useState([]);
  const [isSavedCard, setIsSavedCard] = useState(false);
  const [saveThisCard, setSaveThisCard] = useState(false);
  // Step SevenDen
  const [paymentReferenceID, setPaymentReferenceID] = useState("");

  useEffect(() => {
    const backAction = () => {
      return true;
    };
    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      backAction
    );

    return () => backHandler.remove();
  }, []);

  return (
    <SafeAreaView style={styles.container}>
      <KeyboardAvoidingView behavior="height">
        <StatusBar style="auto" />
        <CustomHeader
          image={false}
          title={"GÖNDERİ OLUŞTUR"}
          avatar={false}
          handleFunc={() => {
            if (step > 0) {
              setStep(step - 1);
            } else {
              navigation.goBack();
            }
          }}
        />
        <ScrollView>
          <View
            style={{
              height: step == 5 ? 900 : windowHeight,
              flexDirection: "column",
            }}
          >
            {/* STEP START */}
            {step === 0 && (
              <StepStart
                title={title}
                sendBy={sendBy}
                enabled={enabled}
                city={city}
                cities={cities}
                region={region}
                regions={regions}
                point={point}
                points={points}
                centers={centers}
                favorites={favorites}
                allCenters={allCenters}
                cityAndRegion={cityAndRegion}
                regionAndPoint={regionAndPoint}
                poiID={poiID}
                shipmentNeighbour={shipmentNeighbour}
                shipmentPhone={shipmentPhone}
                shipmentCity={shipmentCity}
                shipmentRegion={shipmentRegion}
                shipmentAddress={shipmentAddress}
                changeStep={(e) => setStep(e)}
                changeTitle={(e) => setTitle(e)}
                changeSendBy={(e) => setSendBy(e)}
                changeEnabled={(e) => setEnabled(e)}
                changeCity={(e) => setCity(e)}
                changeCities={(e) => setCities(e)}
                changeRegion={(e) => setRegion(e)}
                changeRegions={(e) => setRegions(e)}
                changePoint={(e) => setPoint(e)}
                changePoints={(e) => setPoints(e)}
                changeCenters={(e) => setCenters(e)}
                changeAllCenters={(e) => setAllCenters(e)}
                changeCityAndRegion={(e) => setCityAndRegion(e)}
                changeRegionAndPoint={(e) => setRegionAndPoint(e)}
                changePoiID={(e) => setPoiID(e)}
                changeShipmentCity={(e) => setShipmentCity(e)}
                changeShipmentRegion={(e) => setShipmentRegion(e)}
                changeShipmentAddress={(e) => setShipmentAddress(e)}
                changeShipmentNeighbour={(e) => setShipmentNeighbour(e)}
                changeShipmentPhone={(e) => setShipmentPhone(e)}
              />
            )}
            {/* STEP ONE */}
            {step === 1 && (
              <StepOne
                point={point}
                shipmentCity={shipmentCity}
                shipmentRegion={shipmentRegion}
                shipmentAddress={shipmentAddress}
                shipmentNeighbour={shipmentNeighbour}
                shipmentPhone={shipmentPhone}
                addressCheck={addressCheck}
                changeAddressCheck={(e) => setAddressCheck(e)}
                changeStep={(e) => setStep(e)}
              />
            )}
            {/* STEP TWO */}
            {step === 2 && (
              <StepTwo
                selected={selected}
                customerName={customerName}
                customerLastName={customerLastName}
                customerPhone={customerPhone}
                changeSelected={(e) => setSelected(e)}
                changeCustomerName={(e) => setCustomerName(e)}
                changeCustomerLastName={(e) => setCustomerLastName(e)}
                changeCustomerPhone={(e) => setCustomerPhone(e)}
                changeStep={(e) => setStep(e)}
              />
            )}
            {/* STEP THREE */}
            {step === 3 && (
              <StepThree
                isCargo={isCargo}
                shipmentFirm={shipmentFirm}
                shipmentNo={shipmentNo}
                changeShipmentFirm={(e) => setShipmentFirm(e)}
                changeShipmentNo={(e) => setShipmentNo(e)}
                changeIsCargo={(e) => setIsCargo(e)}
                changeStep={(e) => setStep(e)}
              />
            )}
            {/* STEP FOUR */}
            {step === 4 && (
              <StepFour
                note={note}
                isChecked={isChecked}
                isChecked2={isChecked2}
                isChecked3={isChecked3}
                point={point}
                changeNote={(e) => setNote(e)}
                changeChecked={(e) => setChecked(e)}
                changeChecked2={(e) => setChecked2(e)}
                changeChecked3={(e) => setChecked3(e)}
                changeStep={(e) => setStep(e)}
              />
            )}
            {/* STEP FİVE */}
            {step === 5 && (
              <StepFive
                title={title}
                isCargo={isCargo}
                orderID={orderID}
                invoice={invoice}
                corporate={corporate}
                identityNo={identityNo}
                billingPhone={billingPhone}
                billingCity={billingCity}
                billingRegion={billingRegion}
                billingNeighbour={billingNeighbour}
                billingAddress={billingAddress}
                billingName={billingName}
                billingLastName={billingLastName}
                taxNo={taxNo}
                taxAdministration={taxAdministration}
                email={email}
                shipmentCity={shipmentCity}
                shipmentRegion={shipmentRegion}
                shipmentAddress={shipmentAddress}
                shipmentFirm={shipmentFirm}
                shipmentNo={shipmentNo}
                customerPhone={customerPhone}
                invoiceID={invoiceID}
                changeOrderID={(e) => setOrderID(e)}
                changeInvoice={(e) => setInvoice(e)}
                changeCorporate={(e) => setCorporate(e)}
                changeIdentityNo={(e) => setIdentityNo(e)}
                changeBillingPhone={(e) => setBillingPhone(e)}
                changeBillingCity={(e) => setBillingCity(e)}
                changeBillingRegion={(e) => setBillingRegion(e)}
                changeBillingNeighbour={(e) => setBillingNeighbour(e)}
                changeBillingAddress={(e) => setBillingAddress(e)}
                changeTaxNo={(e) => setTaxNo(e)}
                changeTaxAdministration={(e) => setTaxAdministration(e)}
                changeEmail={(e) => setEmail(e)}
                changeBillingName={(e) => setBillingName(e)}
                changeBillingLastName={(e) => setBillingLastName(e)}
                changeInvoiceID={(e) => setInvoiceID(e)}
                changeStep={(e) => setStep(e)}
              />
            )}
            {/* STEP SIX */}
            {step === 6 && (
              <StepSix
                html={html}
                paymentId={paymentId}
                orderID={orderID}
                cardUserKey={cardUserKey}
                cardToken={cardToken}
                cards={cards}
                isSavedCard={isSavedCard}
                saveThisCard={saveThisCard}
                changeCardUserKey={(e) => setCardUserKey(e)}
                changeCardToken={(e) => setCardToken(e)}
                changeCards={(e) => setCards(e)}
                changeIsSavedCard={(e) => setIsSavedCard(e)}
                changeSaveThisCard={(e) => setSaveThisCard(e)}
                changeHtml={(e) => {
                  setHtml(e);
                }}
                changePaymentId={(e) => setPaymentId(e)}
                changeStep={(e) => setStep(e)}
              />
            )}
            {/* STEP SEVEN */}
            {step === 7 && (
              <StepSeven
                html={html}
                paymentId={paymentId}
                isSavedCard={isSavedCard}
                changeStep={(e) => setStep(e)}
                changePaymentReferenceID={(e) => setPaymentReferenceID(e)}
              />
            )}
            {/* STEP EIGHT */}
            {step === 8 && (
              <StepEight
                point={point}
                poiID={poiID}
                title={title}
                sendBy={sendBy}
                customerName={customerName}
                customerLastName={customerLastName}
                customerPhone={customerPhone}
                paymentReferenceID={paymentReferenceID}
                invoiceID={invoiceID}
                shipmentFirm={shipmentFirm}
                shipmentNo={shipmentNo}
                corporate={corporate}
                isCargo={isCargo}
                billingName={billingName}
                billingLastName={billingLastName}
                identityNo={identityNo}
                invoice={invoice}
                taxNo={taxNo}
                taxAdministration={taxAdministration}
                email={email}
                orderID={orderID}
                billingCity={billingCity}
                billingRegion={billingRegion}
                billingNeighbour={billingNeighbour}
                billingAddress={billingAddress}
                billingPhone={billingPhone}
                shipmentCity={shipmentCity}
                shipmentRegion={shipmentRegion}
                shipmentAddress={shipmentAddress}
                changeStep={(e) => setStep(e)}
                changeInvoiceID={(e) => setInvoiceID(e)}
              />
            )}
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    marginTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
  },
});
