import { StyleSheet, Text, TextInput, View, Platform } from "react-native";
import React, { useState } from "react";
import Checkbox from "expo-checkbox";
import Button from "../Button";
import Modal from "react-native-modal";
import { FontAwesome } from "@expo/vector-icons";
import WebView from "react-native-webview";

export default function StepFour(props) {
  const [note, setNote] = useState(props.note);
  const [isChecked, setChecked] = useState(props.isChecked); // Mesafeli satış sözleşmesi
  const [isChecked2, setChecked2] = useState(props.isChecked2); // Gönderinin 30desi ölçüleri
  const [isChecked3, setChecked3] = useState(props.isChecked3); // Gönderinin teslim adresi onay
  const [point, setPoint] = useState(props.point); // Gönderinin teslim adresi
  const [modalVisible, setModalVisible] = useState(false);
  const URL = "https://www.easypoint.com.tr/mesafeli-satis-sozlesmesi/";

  return (
    <View style={{ justifyContent: "space-between", height: "100%" }}>
      <View style={{ alignItems: "center" }}>
        <Text
          style={{
            fontWeight: "700",
            fontSize: 14,
            lineHeight: 19.07,
            marginBottom: 20,
            marginTop: 18,
          }}
        >
          Lütfen bir notun varsa paylaş
        </Text>
        <TextInput
          value={note}
          onChangeText={(e) => {
            props.changeNote(
              e.replace(
                /[^a-zA-Z0-9ğçşüöıĞÇŞÜÖİ.,\/#!$%\^&\*;:{}=\-_`~() ]/g,
                ""
              )
            );
            setNote(
              e.replace(
                /[^a-zA-Z0-9ğçşüöıĞÇŞÜÖİ.,\/#!$%\^&\*;:{}=\-_`~() ]/g,
                ""
              )
            );
          }}
          blurOnSubmit={true}
          multiline
          style={styles.input}
          placeholder="Bir not yazmaya başla"
        />
        <View style={styles.checkBoxContainer}>
          <Checkbox
            style={{ borderRadius: 4, borderColor: "#E0DEE4" }}
            value={isChecked}
            onValueChange={() => {
              setChecked(!isChecked);
              props.changeChecked(!isChecked);
            }}
            color={isChecked ? "#DA3831" : undefined}
          />
          <Text
            style={{
              fontWeight: "600",
              fontSize: 14,
              lineHeight: 19.5,
              paddingLeft: 10,
              paddingRight: 20,
            }}
          >
            <Text
              onPress={() => setModalVisible(true)}
              style={{
                lineHeight: 19.5,
                fontWeight: "700",
                color: "#DA3831",
                textDecorationLine: "underline",
              }}
            >
              Mesafeli satış sözleşmesini
            </Text>{" "}
            onaylıyorum.
          </Text>
        </View>
        <View style={styles.checkBoxContainer}>
          <Checkbox
            style={{ borderRadius: 4, borderColor: "#E0DEE4" }}
            value={isChecked2}
            onValueChange={() => {
              setChecked2(!isChecked2);
              props.changeChecked2(!isChecked2);
            }}
            color={isChecked2 ? "#DA3831" : undefined}
          />
          <Text
            style={{
              fontWeight: "600",
              fontSize: 14,
              lineHeight: 19.5,
              paddingLeft: 10,
              paddingRight: 20,
            }}
          >
            Gönderinin 30desi (30*30*30) ölçülerinde olduğunu onaylıyorum.
          </Text>
        </View>
        <Modal
          isVisible={modalVisible}
          onBackdropPress={() => setModalVisible(false)}
        >
          <View style={styles.modal}>
            <FontAwesome
              name="times"
              onPress={() => setModalVisible(false)}
              size={24}
              color="black"
              style={{
                alignSelf: "flex-end",
                marginRight: 10,
                marginVertical: 10,
              }}
            />
            {Platform.OS == "web" ? (
              <iframe src={URL} height={"100%"} width={"100%"} />
            ) : (
              <WebView source={{ uri: URL }} />
            )}
          </View>
        </Modal>
        <View style={[styles.checkBoxContainer, { marginBottom: 210 }]}>
          <Checkbox
            style={{ borderRadius: 4, borderColor: "#E0DEE4" }}
            value={isChecked3}
            onValueChange={() => {
              setChecked3(!isChecked3);
              props.changeChecked3(!isChecked3);
            }}
            color={isChecked3 ? "#DA3831" : undefined}
          />
          <Text
            style={{
              fontWeight: "600",
              fontSize: 14,
              lineHeight: 19.5,
              paddingLeft: 10,
              paddingRight: 20,
            }}
          >
            Gönderimi {point} noktasından teslim alacağımı onaylıyorum.
          </Text>
        </View>
      </View>
      <View style={{ marginBottom: Platform.OS == "android" ? 140 : 210 }}>
        <Button
          active={
            isChecked === true && isChecked2 === true && isChecked3 === true
          }
          title={"Devam Et"}
          handleFunc={() => props.changeStep(5)}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    width: "90%",
    height: 128,
    backgroundColor: "#FAF6F5",
    borderWidth: 1,
    borderRadius: 6,
    borderColor: "#E4E4E4",
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    paddingBottom: 15,
    fontSize: 14,
    fontWeight: "600",
    textAlignVertical: "top",
    marginBottom: 25,
  },
  checkBoxContainer: {
    width: "90%",
    flexDirection: "row",
    marginBottom: 25,
  },
  modal: {
    backgroundColor: "white",
    borderRadius: 6,
    height: "80%",
    width: "100%",
  },
});
