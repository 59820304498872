import {
  ScrollView,
  StyleSheet,
  Text,
  View,
  Dimensions,
  Image,
  RefreshControl,
  ActivityIndicator,
  Platform,
} from "react-native";
import React, { useState, useEffect } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CustomHeader from "../../../components/CustomHeader";
import SwitchSelector from "react-native-switch-selector";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Services from "../../../services/Services";

export default function PastPosts({ navigation }) {
  const windowHeight = Dimensions.get("window").height;
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState("1");
  const [completed, setCompleted] = useState([]);
  const [canceled, setCanceled] = useState([]);

  const options = [
    { label: "Tamamlananlar", value: "1" },
    { label: "İptal Olanlar", value: "2" },
  ];

  const fetchOrders = async () => {
    setRefresh(true);
    const token = await AsyncStorage.getItem("token");
    const id = await AsyncStorage.getItem("userID");
    Services.GetOrders(id, token)
      .then((res) => {
        const completedItems = [];
        const canceledItems = [];
        for (let i = 0; i < Object.keys(res.data.result).length; i++) {
          if (res.data.result[i].logs[0].status === "1") {
            completedItems.push(res.data.result[i]);
          } else if (res.data.result[i].logs[0].status === 2) {
            canceledItems.push(res.data.result[i]);
          }
        }
        setCompleted(completedItems);
        setCanceled(canceledItems);
        setLoading(false);
        setRefresh(false);
      })
      .catch((err) => {
        setLoading(false);
        setRefresh(false);
      });
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <SafeAreaView>
      <CustomHeader
        image={false}
        title={"TÜM SİPARİŞLER"}
        handleFunc={() => navigation.goBack()}
      />

      <SwitchSelector
        fontSize={12}
        bold
        textStyle={{ color: "#868686" }}
        buttonColor={"#DA3831"}
        style={{
          width: 337,
          alignSelf: "center",
          marginVertical: 15,
        }}
        options={options}
        initial={0}
        onPress={(e) => {
          setValue(e);
        }}
      />
      {loading === true ? (
        <View
          style={{
            height: 600,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator size={"large"} color="#DA3831" />
        </View>
      ) : (
        <ScrollView
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={refresh}
              onRefresh={() => fetchOrders()}
            />
          }
        >
          <View
            style={{
              alignItems: "center",
              marginBottom: 120,
            }}
          >
            {value === "1" ? (
              <>
                {completed.map((order, index) => (
                  <View
                    key={index}
                    style={{
                      width: "87%",
                      height: 75,
                      backgroundColor: "white",
                      borderRadius: 6,
                      marginTop: 15,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Image
                      style={{
                        width: 50,
                        height: 50,
                        borderRadius: 50 / 2,
                        flex: 1.1,
                        marginLeft: 10,
                      }}
                      source={require("../../../assets/images/order.png")}
                    />
                    <View
                      style={{
                        flexDirection: "column",
                        flex: 4,
                        paddingLeft: 10,
                        justifyContent: "space-around",
                        height: 45,
                      }}
                    >
                      <Text
                        numberOfLines={1}
                        style={{ fontWeight: "700", lineHeight: 19.07 }}
                      >
                        {order.post.title}
                      </Text>
                      <Text
                        style={{
                          alignSelf: "flex-start",
                          fontSize: 12,
                          fontWeight: "400",
                          lineHeight: 16.34,
                          marginTop: 8,
                        }}
                      >
                        {order.post.id}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                        flex: 2,
                        marginRight: 10,
                        justifyContent: "space-between",
                        height: 45,
                      }}
                    >
                      <Text
                        style={{
                          lineHeight: 16.34,
                          fontWeight: "600",
                          fontSize: 12,
                          alignSelf: "flex-end",
                          paddingTop: 4,
                        }}
                      >
                        Tamamlandı!
                      </Text>
                      <Text
                        numberOfLines={1}
                        style={{
                          fontSize: 12,
                          fontWeight: "400",
                          lineHeight: 16.34,
                          alignSelf: "flex-end",
                        }}
                      >
                        {order.post.sentToEasyPointDetails.title.substring(11)}
                      </Text>
                    </View>
                  </View>
                ))}
              </>
            ) : (
              <>
                {canceled.map((order, index) => (
                  <View
                    key={index}
                    style={{
                      width: "87%",
                      height: 75,
                      backgroundColor: "white",
                      borderRadius: 6,
                      marginTop: 15,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Image
                      style={{
                        width: 50,
                        height: 50,
                        borderRadius: 50 / 2,
                        flex: 1.1,
                        marginLeft: 10,
                      }}
                      source={require("../../../assets/images/order.png")}
                    />
                    <View
                      style={{
                        flexDirection: "column",
                        flex: 4,
                        paddingLeft: 10,
                        justifyContent: "space-around",
                        height: 45,
                      }}
                    >
                      <Text
                        numberOfLines={1}
                        style={{ fontWeight: "700", lineHeight: 19.07 }}
                      >
                        {order.post.title}
                      </Text>
                      <Text
                        style={{
                          alignSelf: "flex-start",
                          fontSize: 12,
                          fontWeight: "400",
                          lineHeight: 16.34,
                          marginTop: 8,
                        }}
                      >
                        {order.post.id}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                        flex: 2,
                        marginRight: 10,
                        justifyContent: "space-between",
                        height: 45,
                      }}
                    >
                      <Text
                        style={{
                          lineHeight: 16.34,
                          fontWeight: "600",
                          fontSize: 12,
                          color: "#DA3831",
                          paddingTop: 4,
                          alignSelf: "flex-end",
                        }}
                      >
                        İptal!
                      </Text>
                      <Text
                        numberOfLines={1}
                        style={{
                          fontSize: 12,
                          fontWeight: "400",
                          lineHeight: 16.34,
                          alignSelf: "flex-end",
                        }}
                      >
                        {order.post.sentToEasyPointDetails.title.substring(11)}
                      </Text>
                    </View>
                  </View>
                ))}
              </>
            )}
          </View>
        </ScrollView>
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({});
