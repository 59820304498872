import { Platform, StyleSheet, Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Services from "../../services/Services";
import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { StackActions } from "@react-navigation/native";

export default function StepEight(props) {
  const navigation = useNavigation();
  const [token, setToken] = useState("");
  const [invoiceID, setInvoiceID] = useState(props.invoiceID);

  const poiID = props.poiID;
  const title = props.title;
  const sendBy = props.sendBy;
  const name = props.customerName;
  const lastname = props.customerLastName;
  const phone = props.customerPhone;
  const paymentReferenceID = props.paymentReferenceID;
  const shipmentFirm = props.shipmentFirm;
  const shipmentNo = props.shipmentNo;
  const additionalNote = props.note;
  const payment = true;

  // Fatura için gerekli veriler
  const corporate = props.corporate;
  const isCargo = props.isCargo;
  const billingName = props.billingName;
  const billingLastName = props.billingLastName;
  const [userID, setUserID] = useState(useSelector((state) => state.id));
  const identityNo = props.identityNo;
  const invoice = props.invoice;
  const taxNo = props.taxNo;
  const taxAdministration = props.taxAdministration;
  const email = props.email;
  const orderID = `${props.orderID}}`;
  const billingCity = props.billingCity;
  const billingRegion = props.billingRegion;
  const billingNeighbour = props.billingNeighbour;
  const billingAddress = props.billingAddress;
  const billingPhone = props.billingPhone;
  const shipmentCity = props.shipmentCity;
  const shipmentRegion = props.shipmentRegion;
  const shipmentAddress = props.shipmentAddress;
  const customerPhone = props.customerPhone;

  const createInvoice = async () => {
    await AsyncStorage.getItem("token").then((tok) => {
      setToken(tok);
      Services.AddInvoice(
        tok,
        corporate,
        isCargo,
        billingName,
        billingLastName,
        userID,
        identityNo,
        invoice,
        taxNo,
        taxAdministration,
        email,
        orderID,
        title,
        billingCity,
        billingRegion,
        billingNeighbour,
        billingAddress,
        billingPhone,
        shipmentCity,
        shipmentRegion,
        shipmentAddress,
        customerPhone,
        shipmentFirm,
        shipmentNo
      )
        .then((res) => {
          props.changeInvoiceID(res.data.result.invoiceID);
          setInvoiceID(res.data.result.invoiceID);
          createOrder(res.data.result.invoiceID);
          console.log(
            `Fatura oluşturuldu ${res.data.status} ${res.data.result.invoiceID}`
          );
        })
        .catch((err) => {
          console.log(err);
          alert(
            "Fatura oluştururken bir hata meydana geldi. Lütfen tekrar deneyiniz."
          );
        });
    });
  };

  const createOrder = async (invoiceID) => {
    if (Platform.OS === "web") {
      const webUserID = await AsyncStorage.getItem("userID");
      setUserID(webUserID);
    }
    await AsyncStorage.getItem("token").then((tok) => {
      Services.CreateOrder(
        tok,
        poiID,
        title,
        sendBy,
        userID,
        name,
        lastname,
        phone,
        paymentReferenceID,
        invoiceID,
        shipmentFirm,
        shipmentNo,
        additionalNote,
        payment
      )
        .then((res) => {
          // props.changeStep(1);
          console.log(
            `Gönderi oluşturuldu ${res.data.status} ${res.data.result.id}`
          );
          navigation.replace("StackNavigation", {
            screen: "SuccessPostPage",
            params: { pointTitle: props.point },
          });
          // navigation.dispatch(
          //   StackActions.replace("SuccessPostPage", { pointTitle: props.point })
          // );
        })
        .catch((err) => {
          console.log(err);
          navigation.navigate("BottomTabNavigator", { screen: "HomePage" });
          alert("Gönderi oluşturulamadı. Lütfen tekrar deneyiniz.");
        });
    });
  };

  useEffect(() => {
    createInvoice();

    // AsyncStorage.getItem("token").then((token) => {
    //   Services.CreateOrder(
    //     token,
    //     poiID,
    //     title,
    //     sendBy,
    //     name,
    //     lastname,
    //     phone,
    //     paymentReferenceID,
    //     accountantReferenceID,
    //     shipmentFirm,
    //     shipmentNo,
    //     additionalNote
    //   )
    //     .then((res) => {
    //       navigation.navigate("SuccessPostPage", { pointTitle: props.point });
    //       props.changeStep(1);
    //     })
    //     .catch((err) => console.log(err));
    // });
  }, []);

  return (
    <View style={{ alignItems: "center", justifyContent: "center" }}>
      <Text style={{ textAlign: "center" }}>
        Gönderi oluşturuluyor, biraz uzun sürebilir. Lütfen bekleyiniz...
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({});
