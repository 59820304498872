import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React from "react";

const Avatar = ({ name, surname, uri }) => {
  return (
    <>
      <TouchableOpacity style={styles.circle}>
        <Text style={styles.title}>
          {name}
          {surname}
        </Text>
      </TouchableOpacity>
    </>
  );
};

export default Avatar;
const styles = StyleSheet.create({
  circle: {
    height: 30,
    width: 30,
    borderRadius: 50,
    backgroundColor: "#D33630",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 25,
  },
  title: {
    fontSize: 14,
    fontWeight: "700",
    lineHeight: 19.07,
    color: "white",
  },
});
