import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  TextInput,
  Dimensions,
  Platform,
  KeyboardAvoidingView,
  ScrollView,
} from "react-native";
import React, { useState, useEffect } from "react";
import { StatusBar } from "expo-status-bar";
import CountryPicker from "react-native-country-picker-modal";
import Button from "../../components/Button";
import { useDispatch } from "react-redux";
import {
  setPhone as setReduxPhone,
  setSMS as setReduxSMS,
} from "../../redux/registerReducer";
import AsyncStorage from "@react-native-async-storage/async-storage";
import AuthService from "../../services/AuthService";
import { useBackHandler } from "@react-native-community/hooks";

export default function CreateAccountPage({ navigation }) {
  const dispatch = useDispatch();
  const windowHeight = Dimensions.get("window").height;
  const [countryCode, setCountryCode] = useState("TR");
  const [callingCode, setCallingCode] = useState("90");
  const [phone, setPhone] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const SendingSMS = async (phone) => {
    if (phone.length < 10) {
      alert("Lütfen geçerli bir telefon numarası giriniz.");
      return;
    }
    setButtonLoading(true);
    const sms = Math.floor(Math.random() * (999999 - 100000)) + 100000;
    dispatch(setReduxSMS(sms));
    console.log(sms);
    const token = await AsyncStorage.getItem("token");
    AuthService.SendSMS(phone, sms, token)
      .then((res) => {
        console.log(res.data.status);
        navigation.navigate("MobileConfirmationPage");
        setButtonLoading(false);
      })
      .catch((err) => {
        setButtonLoading(false);
        alert("Bir hata oluştu. Lütfen tekrar deneyiniz.");
      });
  };

  function backActionHandler() {
    navigation.goBack();
    return true;
  }

  useBackHandler(backActionHandler);

  return (
    <KeyboardAvoidingView style={styles.container}>
      <StatusBar style="auto" />
      <ScrollView>
        <View
          style={{
            flexDirection: "column",
            height: windowHeight,
            justifyContent: "space-between",
          }}
        >
          {/* flex-start */}
          <Image
            source={require("../../assets/images/logo.png")}
            style={styles.logo}
            resizeMode="contain"
          />
          {/* center */}
          <View style={{ alignItems: "center", justifyContent: "center" }}>
            <View>
              <Text style={styles.title}>Hesap Oluştur</Text>
              <Text style={styles.context}>
                Lütfen cep telefonu numaranızı giriniz.
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 52,
              }}
            >
              <TouchableOpacity onPress={() => setIsOpen(true)}>
                <View style={styles.callingCode}>
                  <CountryPicker
                    countryCode={countryCode}
                    withFlag
                    withAlphaFilter={true}
                    withCurrencyButton={false}
                    withCallingCode
                    onSelect={(country) => {
                      const { cca2, callingCode } = country;
                      setCountryCode(cca2);
                      setCallingCode(callingCode[0]);
                    }}
                  />
                  <Text style={{ fontSize: 16, fontWeight: "600" }}>
                    + {callingCode}
                  </Text>
                </View>
              </TouchableOpacity>
              <TextInput
                value={phone}
                onChangeText={(x) => {
                  setPhone(x);
                  dispatch(setReduxPhone(x));
                }}
                style={[
                  styles.callingCode,
                  {
                    justifyContent: "flex-start",
                    width: 227,
                    paddingLeft: 11,
                    marginLeft: 6,
                    fontSize: 16,
                    fontWeight: "600",
                    marginBottom: 40,
                  },
                ]}
                placeholder="Telefon Numarası"
                placeholderTextColor={"#A7A7A7"}
                maxLength={10}
                keyboardType="number-pad"
                returnKeyType={"done"}
              />
            </View>
            <Button
              active={phone !== ""}
              title={"Devam Et"}
              handleFunc={() => {
                SendingSMS(phone);
              }}
              isLoad={buttonLoading}
            />
            {/* <Text
              style={{
                color: "#5E596D",
                fontSize: 16,
                fontWeight: "600",
                marginTop: 40,
              }}
            >
              Ya da şunlarla devam et
            </Text>
            <View
              style={{
                flexDirection: "row",
                width: 182,
                justifyContent: "space-between",
              }}
            >
              <View style={styles.socialCircles}>
                <Image
                  source={require("../../assets/images/google.png")}
                  style={{ width: 24, height: 24 }}
                />
              </View>
              <View style={styles.socialCircles}>
                <Image
                  source={require("../../assets/images/face.png")}
                  style={{ width: 24, height: 24 }}
                />
              </View>
              <View style={styles.socialCircles}>
                <Image
                  source={require("../../assets/images/apple.png")}
                  style={{ width: 24, height: 24 }}
                />
              </View>
            </View> */}
          </View>
          {/* flex-end */}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              marginBottom: Platform.OS === "ios" ? 90 : 30,
            }}
          >
            <Text style={{ fontWeight: "300", fontSize: 14, marginRight: 20 }}>
              Zaten bir hesabın var mı?
            </Text>
            <TouchableOpacity onPress={() => navigation.navigate("LoginPage")}>
              <Text
                style={{ color: "#DA3831", fontWeight: "700", fontSize: 14 }}
              >
                Giriş Yap
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  logo: {
    width: 120,
    alignSelf: "center",
    marginTop: 30,
  },
  title: {
    fontSize: 22,
    fontWeight: "700",
    textAlign: "center",
    // marginTop: 50,
  },
  context: {
    fontSize: 14,
    fontWeight: "400",
    textAlign: "center",
    color: "#5E596D",
    marginTop: 8,
    lineHeight: 19.07,
  },
  callingCode: {
    width: 105,
    height: 47,
    backgroundColor: "#FAF6F5",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 6,
    borderColor: "#E4E4E4",
    flexDirection: "row",
  },
  socialCircles: {
    marginTop: 14,
    height: 54,
    width: 54,
    borderWidth: 1,
    borderColor: "#E0DEE4",
    borderRadius: 31,
    backgroundColor: "rgba(9, 36, 32, 0.0494564)",
    justifyContent: "center",
    alignItems: "center",
  },
});
