import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  Dimensions,
  Platform,
  ActivityIndicator,
} from "react-native";
import React, { useState, useEffect } from "react";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import SwitchToggle from "react-native-switch-toggle";
import SelectDropdown from "react-native-select-dropdown";
import Button from "../Button";
import collect from "collect.js";
import Services from "../../services/Services";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useIsFocused } from "@react-navigation/native";

export default function StepStart(props) {
  const isFocused = useIsFocused();
  const [loading, setLoading] = useState(false);
  const windowHeight = Dimensions.get("window").height;
  const [deliveryInfo, setDeliveryInfo] = useState(true); // Teslimat bilgilerinin gözüküp gözükmeyeceğini belirleyen state
  const [easyPointInfo, setEasyPointInfo] = useState(false); // Easy Point Noktalarının gözüküp gözükmeyeceğini belirleyen state

  const [title, setTitle] = useState(props.title); // Kargo ismi
  const [sendBy, setSendBy] = useState(props.sendBy); // Gönderen ismi
  const [enabled, setEnabled] = useState(props.enabled); // Favori nokta kısmını gösterir
  const [favorites, setFavorites] = useState(props.favorites);

  const [city, setCity] = useState(props.city);
  const [cities, setCities] = useState(props.cities);
  const [region, setRegion] = useState(props.region);
  const [regions, setRegions] = useState(props.regions);
  const [point, setPoint] = useState(props.point);
  const [points, setPoints] = useState(props.points);
  const [centers, setCenters] = useState(props.centers);
  const [allCenters, setAllCenters] = useState(props.allCenters);
  const [cityAndRegion, setCityAndRegion] = useState(props.cityAndRegion);
  const [regionAndPoint, setRegionAndPoint] = useState(props.regionAndPoint);
  const [poiID, setPoiID] = useState(props.poiID);
  const [shipmentCity, setShipmentCity] = useState(props.shipmentCity);
  const [shipmentRegion, setShipmentRegion] = useState(props.shipmentRegion);
  const [shipmentAddress, setShipmentAddress] = useState(props.shipmentAddress);
  const [shipmentPhone, setShipmentPhone] = useState(props.shipmentPhone);
  const [shipmentNeighbour, setShipmentNeighbour] = useState(
    props.shipmentNeighbour
  );

  function fetchDatas() {
    setLoading(true);
    AsyncStorage.getItem("token").then((token) => {
      Services.GetCenters(token).then((res) => {
        const resCollect = collect(res.data.result);
        const cityList = resCollect
          .map((row) => row.city)
          .unique()
          .toArray();
        cityList.forEach((cityName) => {
          const cityRegions = resCollect
            .where("city", cityName)
            .map((row) => row.region)
            .unique()
            .toArray();
          cityAndRegion[cityName] = cityRegions;
          setCityAndRegion(cityAndRegion);
          props.changeCityAndRegion(cityAndRegion);
          cityRegions.forEach((regionName) => {
            const regionPoints = resCollect
              .where("region", regionName)
              .map((row) => row.title)
              .unique()
              .toArray();
            regionAndPoint[regionName] = regionPoints;
            setRegionAndPoint(regionAndPoint);
            props.changeRegionAndPoint(regionAndPoint);
          });
        });
        setCities(cityList);
        props.changeCities(cityList);
        setCenters(res.data.result);
        props.changeCenters(res.data.result);
        setAllCenters(res.data.result);
        props.changeAllCenters(res.data.result);
        setLoading(false);
        // setTimeout(() => {
        //   setLoading(false);
        // }, 1000);
      });
    });
  }

  useEffect(() => {
    AsyncStorage.getItem("favorites").then((items) => {
      if (JSON.parse(items).length != favorites.length) {
        setFavorites(JSON.parse(items));
      }
    });
  }, [isFocused]);

  useEffect(() => {
    AsyncStorage.getItem("favorites").then((items) => {
      if (items == null) {
        setFavorites([]);
      } else {
        try {
          setFavorites(JSON.parse(items));
        } catch (e) {
          console.log(e);
        }
      }
    });
    if (point == null) {
      fetchDatas();
    }
  }, [point]);

  return (
    <View
      style={{
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      {loading === true ? (
        <View
          style={{
            height: 600,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator size={"large"} color="#DA3831" />
        </View>
      ) : (
        <>
          <View>
            {/* Delivery Informations */}
            <TouchableOpacity
              onPress={() => setDeliveryInfo(!deliveryInfo)}
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 25,
                marginTop: 18,
              }}
            >
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <MaterialCommunityIcons
                  name="cube-send"
                  size={24}
                  color="black"
                  style={{ paddingLeft: 20, marginRight: 6 }}
                />
                <Text
                  style={{ fontWeight: "700", fontSize: 14, lineHeight: 19.07 }}
                >
                  Teslimat Bilgileri
                </Text>
              </View>
              {deliveryInfo == true ? (
                <Ionicons
                  name="chevron-up"
                  size={20}
                  color="black"
                  style={{ paddingRight: 15 }}
                />
              ) : (
                <Ionicons
                  name="chevron-down"
                  size={20}
                  color="black"
                  style={{ paddingRight: 15 }}
                />
              )}
            </TouchableOpacity>
            {deliveryInfo == true ? (
              <View style={{ alignItems: "center" }}>
                <TextInput
                  value={title}
                  onChangeText={(e) => {
                    props.changeTitle(
                      e.replace(/[^a-zA-Z0-9ğçşüöıĞÇŞÜÖİ ]/g, "")
                    );
                    setTitle(e.replace(/[^a-zA-Z0-9ğçşüöıĞÇŞÜÖİ ]/g, ""));
                  }}
                  style={styles.input}
                  placeholder="Kargonuza bir isim verin"
                  keyboardType="ascii-capable"
                  maxLength={50}
                />
                <TextInput
                  value={sendBy}
                  onChangeText={(e) => {
                    props.changeSendBy(
                      e.replace(/[^a-zA-Z0-9ğçşüöıĞÇŞÜÖİ ]/g, "")
                    );
                    setSendBy(e.replace(/[^a-zA-Z0-9ğçşüöıĞÇŞÜÖİ ]/g, ""));
                  }}
                  style={styles.input}
                  placeholder="Sipariş Edilen Firma / Gönderen Kişi"
                  keyboardType="ascii-capable"
                  maxLength={50}
                />
              </View>
            ) : (
              <></>
            )}
            {/* Favorite Spot */}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontWeight: "700",
                  fontSize: 14,
                  lineHeight: 19.07,
                  marginTop: 10,
                  paddingLeft: 20,
                  marginBottom: 20,
                }}
              >
                Favori Noktama Gönderilsin
              </Text>
              <SwitchToggle
                switchOn={enabled}
                onPress={() => {
                  setEnabled(!enabled);
                  props.changeEnabled(!enabled);
                }}
                circleColorOff="white"
                circleColorOn="white"
                backgroundColorOn="#DA3831"
                backgroundColorOff="#B7B7B7"
                containerStyle={{
                  marginTop: 10,
                  marginBottom: 20,
                  width: 44,
                  height: 26,
                  borderRadius: 25,
                  padding: 5,
                  marginRight: 20,
                }}
                circleStyle={{
                  width: 18,
                  height: 18,
                  borderRadius: 20,
                }}
              />
            </View>
            {/* Easy Point */}
            {enabled == false ? (
              <>
                <TouchableOpacity
                  onPress={() => setEasyPointInfo(!easyPointInfo)}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: 15,
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "700",
                        fontSize: 14,
                        lineHeight: 19.07,
                        paddingLeft: 20,
                      }}
                    >
                      Easy Point Noktası
                    </Text>
                    {easyPointInfo == false ? (
                      <Ionicons
                        name="chevron-down"
                        size={20}
                        color="black"
                        style={{ paddingRight: 15 }}
                      />
                    ) : (
                      <Ionicons
                        name="chevron-up"
                        size={20}
                        color="black"
                        style={{ paddingRight: 15 }}
                      />
                    )}
                  </View>
                  <Text
                    style={{
                      paddingLeft: 20,
                      fontSize: 12,
                      fontWeight: "300",
                      lineHeight: 16.34,
                      paddingTop: 3,
                      paddingRight: 70,
                      marginBottom: 20,
                    }}
                  >
                    Gönderinizi teslim almak istediğiniz Easy Point noktasını
                    seçin.
                  </Text>
                </TouchableOpacity>
                {easyPointInfo == true ? (
                  <View style={{ alignItems: "center" }}>
                    <SelectDropdown
                      data={cities}
                      defaultValue={city}
                      onSelect={(selectedItem, index) => {
                        setRegions(cityAndRegion[selectedItem]);
                        props.changeRegions(cityAndRegion[selectedItem]);
                        setCity(selectedItem);
                        props.changeCity(selectedItem);
                      }}
                      buttonStyle={styles.input}
                      buttonTextStyle={{
                        textAlign: "left",
                        fontWeight: "600",
                        fontSize: 14,
                        lineHeight: 19.07,
                        color: city === null ? "#848484" : "#000",
                      }}
                      defaultButtonText="İl"
                      renderDropdownIcon={() => {
                        return (
                          <Ionicons
                            name="chevron-down"
                            size={20}
                            color="black"
                          />
                        );
                      }}
                    />
                    <SelectDropdown
                      data={regions}
                      defaultValue={region}
                      onSelect={(selectedItem, index) => {
                        const selectedCenters = collect(allCenters)
                          .where("city", city)
                          .where("region", selectedItem)
                          .all();
                        setPoints(
                          selectedCenters.map((center) => center.title)
                        );
                        props.changePoints(regionAndPoint[selectedItem]);
                        setRegion(selectedItem);
                        props.changeRegion(selectedItem);
                      }}
                      buttonStyle={styles.input}
                      buttonTextStyle={{
                        textAlign: "left",
                        fontWeight: "600",
                        fontSize: 14,
                        lineHeight: 19.07,
                        color: region === null ? "#848484" : "#000",
                      }}
                      defaultButtonText="İlçe"
                      renderDropdownIcon={() => {
                        return (
                          <Ionicons
                            name="chevron-down"
                            size={20}
                            color="black"
                          />
                        );
                      }}
                    />
                    <SelectDropdown
                      data={points}
                      defaultValue={point}
                      onSelect={(selectedItem, index) => {
                        const poi = centers.find((obj) => {
                          return obj.title === selectedItem;
                        });
                        setPoiID(poi.id);
                        props.changePoiID(poi.id);
                        setPoint(selectedItem);
                        props.changePoint(selectedItem);
                        setShipmentCity(poi.city);
                        props.changeShipmentCity(poi.city);
                        setShipmentRegion(poi.region);
                        props.changeShipmentRegion(poi.region);
                        setShipmentAddress(poi.address);
                        props.changeShipmentAddress(poi.address);
                        setShipmentPhone(poi.phone);
                        props.changeShipmentPhone(poi.phone);
                        setShipmentNeighbour(poi.neighbour);
                        props.changeShipmentNeighbour(poi.neighbour);
                      }}
                      buttonStyle={styles.input}
                      buttonTextStyle={{
                        textAlign: "left",
                        fontWeight: "600",
                        fontSize: 14,
                        lineHeight: 19.07,
                        color: point === null ? "#848484" : "#000",
                      }}
                      defaultButtonText="Easy Point Noktanı Seç"
                      renderDropdownIcon={() => {
                        return (
                          <Ionicons
                            name="chevron-down"
                            size={20}
                            color="black"
                          />
                        );
                      }}
                    />
                  </View>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <View style={{ alignItems: "center" }}>
                {favorites.length != 0 ? (
                  <SelectDropdown
                    data={favorites.map((row) => row.title)}
                    onSelect={(selectedItem) => {
                      const favoriteItem = favorites.find(
                        (row) => row.title == selectedItem
                      );
                      setPoint(favoriteItem.title);
                      props.changePoint(favoriteItem.title);
                      setPoiID(favoriteItem.id);
                      props.changePoiID(favoriteItem.id);
                      setShipmentCity(favoriteItem.city);
                      props.changeShipmentCity(favoriteItem.city);
                      setShipmentRegion(favoriteItem.region);
                      props.changeShipmentRegion(favoriteItem.region);
                      setShipmentAddress(favoriteItem.address);
                      props.changeShipmentAddress(favoriteItem.address);
                      setShipmentPhone(favoriteItem.phone);
                      props.changeShipmentPhone(favoriteItem.phone);
                      setShipmentNeighbour(favoriteItem.neighbour);
                      props.changeShipmentNeighbour(favoriteItem.neighbour);
                    }}
                    buttonStyle={styles.input}
                    buttonTextStyle={{
                      textAlign: "left",
                      fontWeight: "600",
                      fontSize: 14,
                      lineHeight: 19.07,
                      color: poiID === "" ? "#848484" : "#000",
                    }}
                    defaultButtonText="Favori Noktanızı Seçin"
                    renderDropdownIcon={() => {
                      return (
                        <Ionicons name="chevron-down" size={20} color="black" />
                      );
                    }}
                  />
                ) : (
                  <Text>Favori noktanız bulunmamaktadır.</Text>
                )}
              </View>
            )}
          </View>
          <View style={{ marginBottom: Platform.OS == "android" ? 140 : 210 }}>
            <Button
              handleFunc={() => props.changeStep(1)}
              title={"Devam Et"}
              // active={title !== "" && sendBy !== "" && poiID !== ""}
              active={
                enabled == true
                  ? title != "" &&
                    sendBy != "" &&
                    poiID != "" &&
                    favorites.length != 0
                  : title != "" &&
                    sendBy != "" &&
                    city != null &&
                    region != null &&
                    point != null
              }
            />
          </View>
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    width: "90%",
    height: 47,
    backgroundColor: "#FAF6F5",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 6,
    borderColor: "#E4E4E4",
    marginBottom: 10,
    paddingLeft: 11,
    fontSize: 14,
    fontWeight: "600",
  },
  button: {
    width: "90%",
    height: 54,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 31,
    marginTop: 60,
    marginBottom: 31,
  },
});
