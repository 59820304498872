import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Image,
  Platform,
} from "react-native";
import React from "react";
import { Ionicons } from "@expo/vector-icons";
import Avatar from "./Avatar";
import { useSelector } from "react-redux";

export default function CustomHeader({ image, title, avatar, handleFunc }) {
  const firstLetter = useSelector((state) => state.name[0].toUpperCase());
  const secondLetter = useSelector((state) => state.lastname[0].toUpperCase());

  return (
    <View style={styles.header}>
      {image === true ? (
        <>
          <TouchableOpacity onPress={handleFunc}>
            <Ionicons
              name="notifications"
              size={24}
              color={"#DA3831"}
              style={{ paddingLeft: 25 }}
            />
          </TouchableOpacity>
          <Image
            source={require("../assets/images/logo2.png")}
            style={{ width: 120, marginTop: Platform.OS === "ios" ? 10 : 10 }}
            resizeMode="contain"
          />
          <Avatar name={firstLetter} surname={secondLetter} />
        </>
      ) : (
        <>
          <TouchableOpacity onPress={handleFunc}>
            <Ionicons
              name="chevron-back"
              size={24}
              color={"#000"}
              style={{ paddingLeft: 25 }}
            />
          </TouchableOpacity>
          <Text style={styles.headerTitle}>{title}</Text>
          {avatar === true ? (
            <Avatar name={firstLetter} surname={secondLetter} />
          ) : (
            <View style={{ width: 45 }} />
          )}
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    alignItems: "center",
    height: 45,
    width: "100%",
    justifyContent: "space-between",
  },
  headerTitle: {
    fontWeight: "700",
    fontSize: 14,
    lineHeight: 19.07,
    minWidth: 120,
    textAlign: "center",
  },
});
