import {
  ScrollView,
  StyleSheet,
  Text,
  Linking,
  View,
  Platform,
} from "react-native";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CustomHeader from "../../../components/CustomHeader";

export default function Contracts({ navigation }) {
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <CustomHeader
        image={false}
        title={"SÖZLEŞMELER"}
        handleFunc={() => navigation.goBack()}
      />
      <ScrollView style={{ backgroundColor: "white", marginBottom: -40 }}>
        <View style={{ marginBottom: Platform.OS == "web" ? 90 : 30 }}>
          {/* TARAFLAR */}
          <Text style={styles.title}>TARAFLAR</Text>
          <Text style={styles.subTitle}>MADDE 1</Text>
          <Text style={styles.content}>
            İşbu sözleşme, Easy Point faaliyetlerini yürüten ve ETİLER MAH.
            ERGİN SK. YILDIZ BLOKLARI 3.BLOK NO: 4 İÇKAPI NO: 10 BEŞİKTAŞ/
            İSTANBUL adresinde mukim olan AVM Lojistik Ticaret Anonim Şirketi
            ile siteye üye olan KULLANICI arasında, kullanıcının siteye üye
            olması ve onaylanması anında düzenlenmiştir. Taraflar sözleşmede
            kısaca “Easy Point” ve “KULLANICI” olarak anılacaktır. Siteye üye
            olmakla Kullanıcı Sözleşmesi'nin tamamını okuduğunuzu, anladığınızı
            ve maddeleri onayladığınızı kabul, beyan ve taahhüt etmiş
            sayılırsınız.
          </Text>
          {/* TANIMLAR */}
          <Text style={styles.title}>TANIMLAR</Text>
          <Text style={styles.subTitle}>MADDE 2</Text>
          <Text style={styles.content}>
            KULLANICI: Easy Point sanal platformuna çevrimiçi ortamdan erişen
            kayıtlı her gerçek ve tüzel kişidir. KURYE: Taşımacılık faaliyetini
            gerçekleştiren gerçek veya tüzel kişi. PLATFORM: Kullanıcı ve
            Kurye’nin{" "}
            <Text
              style={{ color: "blue", textDecorationLine: "underline" }}
              onPress={() => Linking.openURL("http://www.easypoint.com.tr")}
            >
              www.easypoint.com.tr
            </Text>{" "}
            ve Easy Point mobil uygulaması üzerinden bir araya geldiği sanal
            ortamdır. SİTE:{" "}
            <Text
              style={{ color: "blue", textDecorationLine: "underline" }}
              onPress={() => Linking.openURL("http://www.easypoint.com.tr")}
            >
              www.easypoint.com.tr
            </Text>{" "}
            isimli alan adından ve bu alan adına bağlı alt alan adlarından
            oluşan internet sitesidir.
          </Text>
          {/* SÖZLEŞMENİN KONUSU VE KAPSAMI */}
          <Text style={styles.title}>SÖZLEŞMENİN KONUSU VE KAPSAMI</Text>
          <Text style={styles.subTitle}>MADDE 3</Text>
          <Text style={styles.content}>
            3.1. İşbu Sözleşme’nin konusu Platforma üye olan kullanıcının
            hizmetlerden faydalanma şartları ile tarafların karşılıklı hak ve
            yükümlülüklerinin belirlenmesidir.
          </Text>
          <Text style={styles.content}>
            3.2. Easy Point, KURYE ile bu hizmeti almak isteyen Kullanıcıların
            bir araya geldiği, taraflara yazılım desteğinin verildiği dijital
            bir platformdur.
          </Text>
          <Text style={styles.content}>
            3.3. KULLANICI, KURYE ile arasındaki hizmet ilişkisindeEasy
            Point’nin işbu ilişkinin tarafi olmadiğini, hizmet ilişkisi ve
            hizmet konusu faaliyete ilişkin herhangi taahhüt ve yükümlülükte
            bulunmadiğini onaylar.
          </Text>
          <Text style={styles.content}>
            3.4.Easy Point, 4925 sayılı Karayolu Taşıma Kanunu’na istinaden bir
            taşımacılık faaliyeti yürütmemektedir.
          </Text>
          <Text style={styles.content}>
            3.5. KURYE’ler,Easy Point’nin sayılanlarla sınırlı olmamak üzere
            personeli, çalışanı, acentesi, iş ortağı değildir. Kullanıcı
            Platform aracılığıyla muhatap olduğu ve şikâyetçi olduğu kuryeleri,
            Easy Point’ye bildirebilir. Fakat Easy Point, kuryelerin
            davranışlarından ötürü doğrudan herhangi bir şekilde sorumlu
            tutulamaz. KURYE’ler tarafindan verilen hizmet ile ilgili Easy
            Point’nin hiçbir sorumluluğu yoktur. Easy Point verilen hizmet ile
            ilgili hiçbir garanti veremez.
          </Text>
          {/* HİZMET KULLANIM ŞARTLARI */}
          <Text style={styles.title}>HİZMET KULLANIM ŞARTLARI</Text>
          <Text style={styles.subTitle}>MADDE 4</Text>
          <Text style={styles.content}>
            4.1. Platforma üye olabilmek için 18 yaşını doldurmuş olmak ve
            siteden geçici olarak üyelikten uzaklaştırılmamış veya süresiz
            yasaklanmamış olmak gerekir.
          </Text>
          <Text style={styles.content}>
            4.2. KULLANICI, üyelik kaydı için gerekli olan bölümleri doldurup
            GSM numarasına gelen aktivasyon kodunu ilgili forma yazarak kayıt
            olur. İşbu sözleşmede belirtilen şartlara uymak koşulu ile GSM
            numarasını ve şifresini girerek Platformu kullanmaya başlar. Üyelik
            işlemi tamamlanmadan, Üyelik Sözleşmesi'nde tanımlanan Üye olma hak
            ve yetkisine sahip olunamaz.
          </Text>
          <Text style={styles.content}>
            4.3. Platforma üye olabilmek için, Easy Point tarafından, geçici
            olarak üyelikten uzaklaştırılmış veya üyelikten süresiz yasaklanmış
            olmamak gerekmektedir. Bu kişilerin uygulama üyelik işlemlerini
            tamamlamış olmaları, KULLANICI olmaları sonucunu doğurmayacaktır.
          </Text>
          <Text style={styles.content}>
            4.4. KULLANICI, Platform üzerinden KURYE talep ettiğinde, kendisine
            yakın konumdaki KURYE’lere iletilecektir. KULLANICI ile KURYE
            arasındaki hukuksal ilişki KURYE’nin kendisine çağrı geldiğinde
            kabul tuşuna basması ile başlar.
          </Text>
          <Text style={styles.content}>
            4.5. KULLANICI, KURYE çağrı yapılan adrese ulaştığında gönderiyi
            hazır bulundurmayı kabul ve taahhüt eder.
          </Text>
          <Text style={styles.content}>
            4.6. KULLANICI, Platform üzerinden çağırdığı kuryenin hizmet
            bedelinin tamamını Platform vasıtasıyla kendisine sunulmuş olan
            ödeme yöntemleri aracılığıyla, eğer sunulan herhangi bir ödeme
            yöntemi yoksa, gelen kuryeye veya kuryenin bağlı olduğu kurye
            şirketine peşin olarak ödemekle yükümlü olduğunu kabul eder.
          </Text>
          <Text style={styles.content}>
            4.7. Easy Point, KULLANICI ile Kurye şirketini bir araya getiren bir
            pazar yeridir ve ödemeden (özellikle de peşin ödemeli) kaynaklı
            anlaşmazlıkların tarafı değildir. Easy Point, Tarafların arasındaki
            ödeme ve tahsilat nedenli sorunlardan dolayı muhatap tutulamaz. Easy
            Point, Platform dahilinde hizmetin kullanımı sırasında kaybolacak
            ve/veya eksik alınacak, yanlış adrese iletilecek, hasar görecek
            gönderilerden sorumlu değildir.
          </Text>
          <Text style={styles.content}>
            4.8. KULLANICI, kredi kartı ile ödemeyi tercih ettiğinde bir kereye
            mahsus olmak üzere kredi kartı bilgilerini Platforma üzerinden
            sisteme girecektir. Kullanıcının güvenli olarak saklanan kredi kartı
            bilgileri, daha sonra tekrar bilgi girmeyi gerektirmeksizin
            kullanılacaktır.
          </Text>
          <Text style={styles.content}>
            4.9. KULLANICI’nın Kredi kartı ile ödemeyi tercih etmesi halinde ve
            sistemde o an kullanılan kredi kartının limitinin dolu olması veya
            herhangi bir nedenle ödemenin yapılamaması halinde KULLANICI
            sistemde daha önce tanımladığı başka bir kredi kartı varsa onu
            kullanarak veya yeni bir kredi kartı tanımlayarak tekrar ödeme
            yapabilir.
          </Text>
          <Text style={styles.content}>
            4.10. KULLANICI’nın gelen KURYE’den hizmet almaması durumunda dahi
            kendisinden 10 TL tutarında bir bedelin cezai şart olarak
            kendisinden kesileceğini, oluşan masraf sebebiyle kendisinden
            kesilen haklı cezai şartın tahsil edilmesi sebebiyle itirazda
            bulunamayacağını bildiğini kabul ve taahhüt etmektedir. Bu durumda
            Easy Point, KULLANICI’yı Platformdan çıkartma hakkını saklı tutar.
          </Text>
          <Text style={styles.content}>
            4.11. Easy Point, KULLANICI’nın her talebinde kendisine bir
            KURYE’nin yanıt vereceğini taahhüt edemez. Easy Point, çağrı alan
            KURYE’lerin olumlu yanıt vermesi için gereken girişimleri yapar.
          </Text>
          <Text style={styles.content}>
            4.12. Süre ve benzeri temel bilgiler KURYE tarafından bildirilmiş
            olup, doğruluğu hususunda platformun yapısı gereği Easy Point’nin
            herhangi bir yükümlüğü yoktur. Easy Point aracılığıyla alınan kurye
            hizmetlerinde, kurye çağırma öncesinde, KULLANICI talep ettiği kurye
            hizmetinin nereden-nereye yapılacağı ve semt bilgisinin girişini
            yapar. KULLANICI tarafından Platform üzerinden girişi yapılan
            bilgilere istinaden, KULLANICI’ya sunulacak olan kurye hizmetine
            dair ücret bilgisi belirtilmektedir. KULLANICI, kendisine sunulan
            ücret bilgisini onaylaması halinde SİPARİŞİ ONAYLA butonuna basarak
            ödemesini yapar ve talebini ileterek tamamlamış olur. Bu onay ile
            KULLANICI, kendisine teklif edilen ücret teklifini (icabı) kabul
            etmiş olur.
          </Text>
          <Text style={styles.content}>
            4.13. KURYE yönlendirilen araca ilişkin verilen bilgilerin (tahmini
            varış süresi, aracın KULLANICI’ya olan uzaklığı vb.) gerçeği
            yansıtması adına elinden geleni azami çabayı gösterir ve fakat bu
            bilgilerin %100 gerçeği yansıtmamasından dolayı sorumlu tutulamaz.
          </Text>
          <Text style={styles.content}>
            4.14. KURYE’ye verilen hedef teslimat adresinin tam ve eksiksiz
            olması KULLANICI’nın sorumluluğundadır. Gidilen adreste teslimatın
            kabul edilmemesi, adresin hatalı olması ve benzeri sebeplerle
            teslimatın gerçekleştirilememesi durumunda KULLANICI ile irtibata
            geçilecek ve eşyanın iadesi yapılacaktır. Bu durumda KULLANICI geri
            getirme sebebiyle, teslimat ödemesi kadar geri getirme ödemesini
            yapacağını kabul ve taahhüt eder.
          </Text>
          <Text style={styles.content}>
            4.15. KULLANICI, Easy Point üzerinden gönderi oluşturma sırasında
            Gönderenin marka adlarını, ticari markasını veya adını kullanırsa
            onay vermiş ve aşağıda yer alan durumlar kabul etmiş sayılır:
          </Text>
          <Text style={styles.content}>
            4.15.1. Gönderide belirtilen fikri faaliyet sonuçlarını ve
            bireyselleştirme araçlarını (ticari markalar, marka isimleri,
            semboller, vb.) kullanmak için tüm haklara ve onaylara sahiptir
          </Text>
          <Text style={styles.content}>
            4.15.2. Böyle bir sözle bağlantılı olarak üçüncü şahısların herhangi
            bir hakkını ihlal etmez; kelime / sembol / kelime öbeği / kelime /
            gizli anlamların yanısıra şeref ve haysiyete zarar verebilecek veya
            aşağılayabilecek diğer kombinasyonları kullanmaz
          </Text>
          <Text style={styles.content}>
            4.15.3. Aşağıdakiler dahil ancak bunlarla sınırlı olmamak üzere
            bilgileri kullanmaz: Türkiye Cumhuriyeti topraklarında dağıtımı
            yasak olan kuruluşlardan bahsetmek, pornografik anlam içeren
            bilgilerin yanı sıra dağıtılması ve Türk yasalarına göre
            belirtilmesi yasak olan diğer bilgiler; sosyal, ırksal, ulusal veya
            dini nefret ve düşmanlığı kışkırtan propaganda veya ajitasyona neden
            olabilecek kombinasyon(lar) kullanmaz
          </Text>
          <Text style={styles.content}>
            4.15.4. Tamamen yasal / ehliyetli ve açık rızanın ihlallerinin
            sonuçlarını anlar
          </Text>
          <Text style={styles.content}>
            4.15.5. Talimatlarında rıza göstermeyen kişinin verilerini yasa dışı
            olarak kullanmaz
          </Text>
          <Text style={styles.content}>
            4.15.6. Türkiye Cumhuriyeti'nin mevcut mevzuatını böyle zikrederek /
            yazarak ihlal etmediğini; işbu iznin ihlalinden doğan her türlü
            sorumluluğu üstlenmeye ve AVM LOJİSTİK TİCARET ANONİM ŞİRKETİ'nin
            zararlarını tazmin etmeye hazırdır.
          </Text>
          <Text style={styles.content}>
            4.15.7. Alınan bu hizmet 0.25 TL/SMS şeklinde ek ücretlendirmeye
            tabidir.
          </Text>
          {/* TARAFLARIN HAK VE YÜKÜMLÜLÜKLERİ */}
          <Text style={styles.title}>TARAFLARIN HAK VE YÜKÜMLÜLÜKLERİ</Text>
          <Text style={styles.subTitle}>MADDE 5</Text>
          <Text style={styles.content}>
            5.1. KULLANICI, üyelik prosedürünü yerine getirirken, uygulamadan
            faydalanırken ve uygulamadaki veya sitedeki hizmetlerle ilgili
            herhangi bir işlemi yerine getirirken, Sözleşme'de yer alan tüm
            şartlara, uygulama ve sitenin ilgili yerlerinde belirtilen kurallara
            ve yukarıda belirtilen tüm şart ve kuralları anladığını ve
            onayladığını kabul, beyan ve taahhüt eder
          </Text>
          <Text style={styles.content}>
            5.2. KULLANICI’nın, siteye üye olurken oluşturduğu kullanıcı ismi,
            şifre v.b. her türlü bilginin güvenliği, saklanması, üçüncü
            kişilerin bilgisinden uzak tutulması tamamen kendi
            sorumluluğundadır. Bu bilgilerin muhafazasından kaynaklanacak tüm
            ihmal ve kusurlarından dolayı Easy Point’nin doğrudan veya dolaylı,
            herhangi bir sorumluluğu bulunmamaktadır.
          </Text>
          <Text style={styles.content}>
            5.3. Nakit para, çek, senet, altın vb. değerli madde ve taşlar,
            döviz gibi kıymetli evrak ve maddeler ile taşınması yasaklanmış olan
            hiçbir gönderi kabul edilmeyecektir. Bu sayılanlarla sınırlı olmamak
            üzere taşınması yasaklanmış eşyaların taşınması sebebiyle doğacak
            her türlü hukuki ve cezai sorumluluk ve dolaylı ve doğrudan bütün
            zararın tazmin yükümlülüğü KULLANICI’ya aittir.
          </Text>
          <Text style={styles.content}>
            5.4. KULLANICI’nin işbu sözleşmeye aykırı davranışları ve Platform
            üzerinden faaliyetlerinin site için ve diğer kullanıcılar için bir
            risk oluşturduğunun tespiti halinde üyelik Easy Point tarafından tek
            taraflı olarak feshedilir.
          </Text>
          <Text style={styles.content}>
            5.5. KULLANICI, Platform aracılığı ile işlem yaptığı KURYE’ler
            hakkındaki şikayetlerini Platform üzerinden Easy Point’ye
            bildirebilir. Easy Point, zarar ve şikayetle ilgili olarak KURYE’ye
            rücu edebilir. Easy Point, KURYE’lerin davranışlarından herhangi bir
            şekilde sorumlu tutulamaz.
          </Text>
          <Text style={styles.content}>
            5.6. Teknik sorunlar sebebiyle uygulamaya erişimde yaşanan
            kesintilerden dolayı KULLANICI’nın yaşayacağı sorunlardan Easy Point
            sorumlu tutulamaz.
          </Text>
          <Text style={styles.content}>
            5.7. Easy Point, ileride doğacak teknik zaruretler ve mevzuata uyum
            amacıyla işbu Sözleşmenin uygulamasında değişiklikler yapabileceği
            gibi mevcut maddelerini değiştirebilir veya yeni maddeler ilave
            edebilir.
          </Text>
          {/* FİKRİ MÜLKİYET HAKLARI */}
          <Text style={styles.title}>FİKRİ MÜLKİYET HAKLARI</Text>
          <Text style={styles.subTitle}>MADDE 6</Text>
          <Text style={styles.content}>
            6.1. Platformun tüm fikri ve sınai hakları Easy Point’ye aittir.
          </Text>
          <Text style={styles.content}>
            6.2. Bu bağlamda, Easy Point tarafından açıkça izin verilmediği
            sürece sitenin ve uygulamanın içeriği hiç bir şekilde kopyalanamaz,
            çoğaltılamaz, yeniden üretilemez, değiştirilemez, satılamaz
            bunlardan türemiş çalışmalar yapılamaz veya dağıtılamaz. Platformda
            yer alan herhangi bir içerik Easy Point'nin fikri mülkiyet hakkını
            kullanma hakkını verecek şekilde yorumlanamaz.
          </Text>
          {/* KİŞİSEL VERİLER */}
          <Text style={styles.title}>KİŞİSEL VERİLER</Text>
          <Text style={styles.subTitle}>MADDE 7</Text>
          <Text style={styles.content}>
            7.1. Platforma üye olmak suretiyle mobil cep telefonu numarasını
            bildiren KULLANICI, Easy Point’nin işlem ile sınırlı cep
            telefonlarına SMS mesajları gönderebileceğini kabul etmiş olur.
            Üyeler, cep telefonu numaraları değiştiğinde Üye Hesaplarını
            güncellemek suretiyle değişiklikten Easy Point’yi haberdar etmeyi
            kabul eder. Easy Point, bahsi geçen mesajları Platformdaki hukuki
            güvenliği sağlamak amacıyla hassas veriler hariç üyelik süresince
            depolayabilir.
          </Text>
          <Text style={styles.content}>
            7.2. KULLANICI, Easy Point tarafından isim, adres, cep telefonu
            numarası, e-posta adresi, vatandaşlık numarası, kullanılan mobil
            aygıtın işletim sistemi versiyonu bilgisi, konum bilgileri, teslimat
            sonunda KURYE’ye verilen oylama bilgisinin ilgili faaliyeti
            gerçekleştirmek için meşru bir hak olarak işlenebileceğini kabul
            eder. Easy Point, bu bilgileri KURYE dışında başka hiçbir kimseyle
            paylaşmayacağını taahhüt eder.
          </Text>
          {/* DİĞER HÜKÜMLER */}
          <Text style={styles.title}>DİĞER HÜKÜMLER</Text>
          <Text style={styles.subTitle}>MADDE 8</Text>
          <Text style={styles.content}>
            8.1. Uygulanacak Hukuk ve Yetki: İşbu Kullanıcı Sözleşmesi'nin
            uygulanmasında, yorumlanmasında ve hükümleri dahilinde doğan hukuki
            ilişkilerin yönetiminde Türk Hukuku uygulanacaktır. İşbu Kullanıcı
            Sözleşmesi'nden doğan veya doğabilecek her türlü ihtilaf halinde,
            İstanbul Mahkemeleri ve İcra Daireleri yetkilidir.
          </Text>
          <Text style={styles.content}>
            8.2. Mücbir Sebep ve İnternet Zaafları: Herhangi bir tarafça, bu
            Sözleşme hükmü altındaki yükümlülüklerinin icrasındaki herhangi bir
            gecikme veya gerçekleştirmemesinin nedeni mücbir sebepler olması
            halinde affedilecektir. Tarafların hiçbirisi internet
            kesintilerinden kaynaklanan zafiyetlerden yükümlü tutulmayacaktır.
          </Text>
          <Text style={styles.content}>
            8.3. Bölünebilirlik: Eğer herhangi bir uygulanabilir mevzuat veya
            kanun kuralı gereğince, bu Sözleşmenin herhangi bir hükmünün
            geçersiz veya uygulanamaz olduğuna karar verilirse, bu durumda o
            hüküm geçerli ve uygulanabilir hale gelmesi için gerekli olan
            minimum kapsamda değiştirilecektir. Eğer değiştirme mümkün değilse,
            o hüküm çıkarılmış kabul edilecek ve Sözleşmenin geri kalanı geçerli
            ve uygulanabilir olarak kalacaktır.
          </Text>
          <Text style={styles.content}>
            8.4. Geçerliliği Koruma: Bu Sözleşmenin gizlilikle, sahiplik
            haklarıyla, zararı tazminle ve yükümlülük kısıtlamalarıyla ilgili
            tüm hükümleri bu Sözleşmenin sona erdirildiği zaman da geçerliliğini
            koruyacaktır.
          </Text>
          <Text style={styles.content}>
            8.5. Delil Anlaşması: Taraflar, bu Sözleşme’den doğabilecek
            ihtilaflarda, çalışmanın bir bilişim sistemi olması nedeniyle
            bilgisayar kayıtlarının HMK. 193. madde anlamında muteber bağlayıcı
            kesin ve münhasır delil teşkil edeceğini ve bu maddenin delil
            sözleşmesi niteliğinde olduğunu kabul beyan ve taahhüt eder.
          </Text>
          <Text style={styles.content}>
            8.6. Easy Point, tamamen kendi takdirine bağlı ve tek taraflı
            olarak, sözleşmeyi, uygun göreceği herhangi bir zamanda, Uygulama
            üzerinden ilan ederek değiştirebilir. İşbu sözleşmenin değişen
            hükümleri, ilan edildikleri tarihte veya sonrasında, KULLANICI
            tarafından Uygulama üzerinden on-line kabul edilmesi ile geçerlilik
            kazanacak, geri kalan hükümler aynen yürürlükte kalarak hüküm ve
            sonuçlarını doğurmaya devam edecektir.
          </Text>
          <Text style={styles.content}>
            8.7. Yürürlük: İşbu sözleşme KULLANICI’nın elektronik olarak onay
            vermesi ile karşılıklı olarak kabul edilerek yürürlüğe girmiştir.
          </Text>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  title: {
    fontSize: 14,
    fontWeight: "600",
    alignSelf: "flex-start",
    marginTop: 15,
    paddingLeft: 15,
  },
  subTitle: {
    fontSize: 14,
    fontWeight: "500",
    alignSelf: "flex-start",
    marginTop: 15,
    paddingLeft: 15,
  },
  content: {
    paddingHorizontal: 15,
    marginTop: 5,
    textAlign: "left",
  },
});
