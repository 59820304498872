import { Image, StyleSheet, Text, BackHandler } from "react-native";
import React, { useEffect } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CustomHeader from "../../../components/CustomHeader";

export default function CampaignDetail({ navigation, route }) {
  const _title = route.params.title;
  const _description = route.params.description;
  const _images = route.params.images;

  useEffect(() => {
    console.log(route.params);
    const backAction = () => {
      navigation.goBack();
      return true;
    };
    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      backAction
    );

    return () => backHandler.remove();
  }, []);

  return (
    <SafeAreaView style={styles.container}>
      <CustomHeader
        image={false}
        title={"KAMPANYA"}
        avatar={true}
        handleFunc={() => navigation.goBack()}
      />
      <Image
        style={styles.image}
        source={{
          // uri: "https://www.peple.com.tr/wp-content/uploads/2021/12/gittigidiyor-993_520.png",
          uri: _images[0],
        }}
      />
      <Text style={styles.title}>{_title}</Text>
      <Text style={styles.context}>{_description}</Text>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
  },
  image: {
    width: "100%",
    height: 208,
  },
  title: {
    fontSize: 14,
    lineHeight: 19.07,
    fontWeight: "700",
    paddingHorizontal: 33,
    paddingTop: 11,
    marginBottom: 17,
  },
  context: {
    color: "#4F4F4F",
    fontSize: 14,
    fontWeight: "400",
    lineHeight: 19.07,
    letterSpacing: -0.25,
    paddingHorizontal: 33,
  },
});
