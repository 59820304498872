import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Platform,
} from "react-native";
import React, { useState } from "react";
import Button from "../Button";

export default function StepThree(props) {
  const [isCargo, setIsCargo] = useState(props.isCargo);
  const [shipmentFirm, setShipmentFirm] = useState(props.shipmentFirm);
  const [shipmentNo, setShipmentNo] = useState(props.shipmentNo);

  return (
    <View style={{ justifyContent: "space-between", height: "100%" }}>
      <View>
        <Text
          style={{
            fontWeight: "600",
            fontSize: 14,
            lineHeight: 19.07,
            textAlign: "center",
            paddingHorizontal: 100,
            marginTop: 18,
          }}
        >
          Kargo Teslimat Bilgilerini paylaşmak ister misin?
        </Text>
        <TouchableOpacity
          onPress={() => {
            setIsCargo(0);
            props.changeIsCargo(0);
          }}
          style={{ alignItems: "center", marginTop: 15 }}
        >
          <View
            style={[
              styles.input,
              { borderColor: isCargo == 0 ? "#DA3831" : "#E4E4E4" },
            ]}
          >
            <Text
              style={{ fontWeight: "700", fontSize: 14, lineHeight: 19.07 }}
            >
              Evet İsterim
            </Text>
            <View
              style={{
                backgroundColor: isCargo == 1 ? "#E0D6D3" : "#DA3831",
                width: 15,
                height: 15,
                borderRadius: 20,
                marginRight: 15,
              }}
            />
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setIsCargo(1);
            props.changeIsCargo(1);
          }}
          style={{ alignItems: "center" }}
        >
          <View
            style={[
              styles.input,
              { borderColor: isCargo == 1 ? "#DA3831" : "#E4E4E4" },
            ]}
          >
            <Text
              style={{ fontWeight: "700", fontSize: 14, lineHeight: 19.07 }}
            >
              Paylaşmadan devam et
            </Text>
            <View
              style={{
                backgroundColor: isCargo == 1 ? "#DA3831" : "#E0D6D3",
                width: 15,
                height: 15,
                borderRadius: 20,
                marginRight: 15,
              }}
            />
          </View>
        </TouchableOpacity>

        {isCargo == 0 && (
          <>
            <Text
              style={{
                marginTop: 40,
                fontWeight: "600",
                fontSize: 14,
                lineHeight: 19.07,
                marginLeft: 20,
                marginBottom: 20,
              }}
            >
              Teslimat Bilgileri
            </Text>
            <View style={{ justifyContent: "center", alignItems: "center" }}>
              <TextInput
                value={shipmentFirm}
                onChangeText={(e) => {
                  props.changeShipmentFirm(
                    e.replace(/[^a-zA-Z0-9ğçşüöıĞÇŞÜÖİ ]/g, "")
                  );
                  setShipmentFirm(e.replace(/[^a-zA-Z0-9ğçşüöıĞÇŞÜÖİ ]/g, ""));
                }}
                style={[styles.input]}
                placeholder="Kargo Şirketi"
                keyboardType="ascii-capable"
                maxLength={25}
              />
              <TextInput
                value={shipmentNo}
                onChangeText={(e) => {
                  props.changeShipmentNo(
                    e.replace(/[^a-zA-Z0-9ğçşüöıĞÇŞÜÖİ]+/g, "")
                  );
                  setShipmentNo(e.replace(/[^a-zA-Z0-9ğçşüöıĞÇŞÜÖİ]+/g, ""));
                }}
                style={[styles.input]}
                placeholder="Kargo Takip No"
                keyboardType="ascii-capable"
                maxLength={20}
              />
            </View>
          </>
        )}
      </View>

      <View style={{ marginBottom: Platform.OS == "android" ? 140 : 210 }}>
        <Button
          title={"Devam Et"}
          handleFunc={() => props.changeStep(4)}
          active={
            isCargo == 0
              ? shipmentFirm.length >= 1 && shipmentNo.length >= 4
              : true
          }
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    width: "90%",
    height: 47,
    backgroundColor: "#FAF6F5",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 6,
    borderColor: "#E4E4E4",
    marginBottom: 10,
    paddingLeft: 11,
    fontSize: 14,
    fontWeight: "600",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    width: "90%",
    height: 54,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 31,
    marginTop: 60,
    marginBottom: 31,
  },
});
