import {
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  Dimensions,
  Platform,
  KeyboardAvoidingView,
  ScrollView,
} from "react-native";
import React, { useState } from "react";
import { StatusBar } from "expo-status-bar";
import { AntDesign, FontAwesome } from "@expo/vector-icons";
import Checkbox from "expo-checkbox";
import Button from "../../components/Button";
import { useDispatch } from "react-redux";
import {
  setName as setReduxName,
  setLastname as setReduxLastname,
  setEmail as setReduxEmail,
} from "../../redux/registerReducer";
import Modal from "react-native-modal";
import UserAgreement from "../../components/contentTexts/UserAgreement";
import WebView from "react-native-webview";

export default function PersonalInformationPage({ navigation }) {
  const dispatch = useDispatch();
  const windowHeight = Dimensions.get("window").height;
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false); // Email uyarı
  const [isChecked, setChecked] = useState(false); // Elektronik ileti izni
  const [isChecked2, setChecked2] = useState(false); // Kullanıcı sözleşmesi
  const [agreement, setAgreement] = useState(false);
  const [consent, setConsent] = useState(false);

  const URL = "https://www.easypoint.com.tr/aydinlatma-metni/";

  const validateMail = (text) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      setEmail(text.toLowerCase());
      setError(true);
    } else {
      setEmail(text.toLowerCase());
      dispatch(setReduxEmail(text.toLowerCase()));
      setError(false);
    }
    if (text === "") {
      setError(false);
    }
  };

  return (
    <KeyboardAvoidingView style={styles.container} behavior={"height"}>
      <StatusBar style="auto" />
      <ScrollView style={{ flexDirection: "column" }}>
        <View>
          <Image
            source={require("../../assets/images/logo.png")}
            style={styles.logo}
            resizeMode="contain"
          />
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              marginTop: Platform.OS === "ios" ? 60 : 20,
            }}
          >
            <View>
              <Text style={styles.title}>Kişisel Bilgilerin</Text>
              <Text style={styles.context}>
                Lütfen kişisel bilgilerinizi giriniz.
              </Text>
            </View>
            <TextInput
              style={styles.input}
              value={name}
              placeholder="İsim"
              keyboardType="ascii-capable"
              placeholderTextColor={"#A7A7A7"}
              maxLength={50}
              onChangeText={(x) => {
                setName(x.replace(/[^a-zA-ZğçşüöıĞÇŞÜÖİ]/g, ""));
                dispatch(setReduxName(x.replace(/[^a-zA-ZğçşüöıĞÇŞÜÖİ]/g, "")));
              }}
            />
            <TextInput
              style={styles.input}
              value={lastname}
              placeholder="Soyad"
              keyboardType="ascii-capable"
              maxLength={50}
              placeholderTextColor={"#A7A7A7"}
              onChangeText={(x) => {
                setLastname(x.replace(/[^a-zA-ZğçşüöıĞÇŞÜÖİ]/g, ""));
                dispatch(
                  setReduxLastname(x.replace(/[^a-zA-ZğçşüöıĞÇŞÜÖİ]/g, ""))
                );
              }}
            />
            <View
              style={[
                styles.input,
                {
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 30,
                },
              ]}
            >
              <TextInput
                value={email}
                style={{
                  width: 270,
                  color: error ? "red" : "black",
                  fontSize: 16,
                  fontWeight: "600",
                }}
                placeholder="Email"
                keyboardType="email-address"
                placeholderTextColor={"#A7A7A7"}
                onChangeText={(text) => {
                  setEmail(text);
                }}
                onEndEditing={() => {
                  validateMail(`${email}`);
                }}
              />
              {error ? (
                <AntDesign
                  style={{ paddingRight: 15 }}
                  name="closecircle"
                  size={20}
                  color="red"
                />
              ) : (
                <></>
              )}
            </View>
            <View style={styles.checkBoxContainer}>
              <Checkbox
                style={{ borderRadius: 4, borderColor: "#E0DEE4" }}
                value={isChecked}
                onValueChange={setChecked}
                color={isChecked ? "#DA3831" : undefined}
              />
              <Text
                style={{
                  fontWeight: "400",
                  fontSize: 12,
                  lineHeight: 19.5,
                  paddingLeft: 10,
                  paddingRight: 20,
                }}
              >
                Önemli kampanyalardan haberdar olmak için{" "}
                <Text
                  onPress={() => setConsent(true)}
                  style={{
                    fontSize: 12,
                    lineHeight: 19.5,
                    fontWeight: "700",
                    color: "#DA3831",
                  }}
                >
                  Rıza Metni
                </Text>{" "}
                kapsamında elektronik ileti almak istiyorum.
              </Text>
            </View>
            <Modal
              isVisible={consent}
              onBackdropPress={() => setConsent(false)}
            >
              <View style={styles.modal}>
                <FontAwesome
                  name="times"
                  onPress={() => setConsent(false)}
                  size={24}
                  color="black"
                  style={{
                    alignSelf: "flex-end",
                    marginRight: 10,
                    marginVertical: 10,
                  }}
                />
                <View style={{ height: "100%" }}>
                  <WebView source={{ uri: URL }} />
                </View>
              </View>
            </Modal>
            <View style={[styles.checkBoxContainer, { marginBottom: 54 }]}>
              <Checkbox
                style={{ borderRadius: 4, borderColor: "#E0DEE4" }}
                value={isChecked2}
                onValueChange={setChecked2}
                color={isChecked2 ? "#DA3831" : undefined}
              />
              <Text
                style={{
                  fontWeight: "400",
                  fontSize: 12,
                  lineHeight: 19.5,
                  paddingLeft: 10,
                  paddingRight: 20,
                }}
              >
                <Text
                  onPress={() => setAgreement(!agreement)}
                  style={{
                    fontSize: 12,
                    lineHeight: 19.5,
                    fontWeight: "700",
                    color: "#DA3831",
                  }}
                >
                  Kullanıcı Sözleşmesini ve KVKK Politikası'nı
                </Text>{" "}
                okudum ve onaylıyorum.
              </Text>
            </View>
            <Modal
              isVisible={agreement}
              onBackdropPress={() => setAgreement(false)}
            >
              <View style={styles.modal}>
                <FontAwesome
                  name="times"
                  onPress={() => setAgreement(false)}
                  size={24}
                  color="black"
                  style={{
                    alignSelf: "flex-end",
                    marginRight: 10,
                    marginVertical: 10,
                  }}
                />
                <UserAgreement />
              </View>
            </Modal>
            {/*  */}
            <Button
              active={
                name !== "" &&
                lastname !== "" &&
                email !== "" &&
                error === false &&
                isChecked2 === true
              }
              title={"Devam Et"}
              handleFunc={() => navigation.navigate("CreatePasswordPage")}
            />
          </View>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  logo: {
    width: 120,
    alignSelf: "center",
    marginTop: 30,
  },
  title: {
    fontSize: 22,
    fontWeight: "700",
    textAlign: "center",
    marginTop: 50,
  },
  context: {
    fontSize: 14,
    fontWeight: "400",
    textAlign: "center",
    color: "#5E596D",
    marginTop: 8,
    marginBottom: 20,
    lineHeight: 19.07,
  },
  input: {
    width: 337,
    height: 47,
    backgroundColor: "#FAF6F5",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 6,
    borderColor: "#E4E4E4",
    marginTop: 12,
    paddingLeft: 11,
    fontSize: 16,
    fontWeight: "600",
  },
  checkBoxContainer: {
    width: 337,
    flexDirection: "row",
    marginBottom: 27,
  },
  modal: {
    backgroundColor: "white",
    borderRadius: 6,
    height: "80%",
    width: "100%",
  },
});
