import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CustomHeader from "../../../components/CustomHeader";
import { SimpleLineIcons, Ionicons, AntDesign } from "@expo/vector-icons";
import { useSelector } from "react-redux";

export default function EditProfile({ navigation }) {
  const windowHeight = Dimensions.get("window").height;
  const email = useSelector((state) => state.email);
  const phone = useSelector((state) => state.phone);
  return (
    <SafeAreaView>
      <CustomHeader
        image={false}
        title={"PROFİLİM"}
        handleFunc={() => navigation.goBack()}
      />
      <View style={{ height: windowHeight, backgroundColor: "white" }}>
        <TouchableOpacity
          onPress={() => navigation.navigate("EditPhone")}
          style={[styles.button, { marginTop: 20 }]}
        >
          <View style={{ flexDirection: "column" }}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <SimpleLineIcons
                name="phone"
                size={24}
                color="black"
                style={{ paddingRight: 13 }}
              />
              <Text style={styles.title}>Telefon Numarası</Text>
            </View>
            <Text style={styles.value}>
              +90 ({phone.slice(0, 3)}) {phone.slice(3, 6)} {phone.slice(6, 10)}
            </Text>
          </View>
          <Ionicons
            name="chevron-forward"
            size={15}
            color="black"
            style={{ paddingRight: 22 }}
          />
        </TouchableOpacity>
        <View style={styles.divider} />
        <TouchableOpacity
          onPress={() => navigation.navigate("EditEmail")}
          style={[styles.button, { marginTop: 20 }]}
        >
          <View style={{ flexDirection: "column" }}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <AntDesign
                name="mail"
                size={24}
                color="black"
                style={{ paddingRight: 13 }}
              />
              <Text style={styles.title}>E-posta Adresi</Text>
            </View>
            <Text style={styles.value}>{email}</Text>
          </View>
          <Ionicons
            name="chevron-forward"
            size={15}
            color="black"
            style={{ paddingRight: 22 }}
          />
        </TouchableOpacity>
        <View style={styles.divider} />
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  button: {
    flexDirection: "row",
    paddingLeft: 24,
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontWeight: "600",
    fontSize: 14,
    lineHeight: 19.07,
    letterSpacing: -0.25,
  },
  value: {
    paddingLeft: 36,
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 16.34,
    letterSpacing: -0.25,
    color: "#7D8290",
    paddingTop: 5,
  },
  divider: {
    marginLeft: 37.5,
    backgroundColor: "#F2F2F2",
    height: 1,
    width: "82%",
    marginTop: 14.5,
    alignSelf: "flex-end",
  },
});
