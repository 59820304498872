import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { BottomTabNavigator } from "./BottomTabNavigation";
import SplashPage from "../pages/auth/SplashPage";
import CreateAccountPage from "../pages/auth/CreateAccountPage";
import MobileConfirmationPage from "../pages/auth/MobileConfirmationPage";
import PersonalInformationPage from "../pages/auth/PersonalInformationPage";
import CreatePasswordPage from "../pages/auth/CreatePasswordPage";
import LoginPage from "../pages/auth/LoginPage";
import Map from "../pages/bottomTabs/locationTab/Map";
import SuccessPostPage from "../pages/SuccessPostPage";

const Stack = createNativeStackNavigator();

export const StackNavigation = () => {
  return (
    <SafeAreaProvider>
      <Stack.Navigator initialRouteName="SplashPage">
        <Stack.Screen
          name="SplashPage"
          component={SplashPage}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="LoginPage"
          component={LoginPage}
          options={{ headerShown: false, gestureEnabled: false }}
        />
        <Stack.Screen
          name="CreateAccountPage"
          component={CreateAccountPage}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="MobileConfirmationPage"
          component={MobileConfirmationPage}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="PersonalInformationPage"
          component={PersonalInformationPage}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="CreatePasswordPage"
          component={CreatePasswordPage}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="HomePage"
          component={BottomTabNavigator}
          options={{ headerShown: false, gestureEnabled: false }}
        />
        <Stack.Screen
          name="Map"
          component={Map}
          options={{
            headerShown: true,
            headerBackTitleVisible: false,
            headerTitle: "",
            headerTransparent: true,
          }}
        />
        <Stack.Screen
          name="SuccessPostPage"
          component={SuccessPostPage}
          options={{ headerShown: false, gestureEnabled: false }}
        />
      </Stack.Navigator>
    </SafeAreaProvider>
  );
};
