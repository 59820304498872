import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Platform,
} from "react-native";
import React, { useState } from "react";
import Button from "../Button";
import Checkbox from "expo-checkbox";

export default function StepOne(props) {
  const [isChecked, setChecked] = useState(props.addressCheck);
  return (
    <View style={{ height: "100%" }}>
      <Text
        style={{ paddingHorizontal: 20, textAlign: "center", marginTop: 18 }}
      >
        Paketinizi <Text style={{ fontWeight: "800" }}>{props.point}</Text>{" "}
        noktasından alabilmeniz için girmeniz gereken adres bilgileri:
      </Text>
      <View style={{ marginTop: 20, paddingHorizontal: 20 }}>
        <Text>{props.point}</Text>
        <Text>
          {props.shipmentNeighbour} {props.shipmentAddress}
        </Text>
        <Text>
          {props.shipmentRegion} / {props.shipmentCity.toUpperCase()}
        </Text>
        <Text>{props.shipmentPhone}</Text>
      </View>
      <View style={{ marginTop: 300 }}>
        <View style={styles.checkBoxContainer}>
          <Checkbox
            style={{ borderRadius: 4, borderColor: "#E0DEE4" }}
            value={isChecked}
            onValueChange={() => {
              setChecked(!isChecked);
              props.changeAddressCheck(!isChecked);
            }}
            color={isChecked ? "#DA3831" : undefined}
          />
          <Text
            style={{
              fontWeight: "600",
              fontSize: 14,
              lineHeight: 19.5,
              paddingLeft: 10,
              paddingRight: 20,
            }}
          >
            Easy Point Noktasına gönderilecek olan pakete yukarıdaki adresi
            girdiğimi onaylıyorum.
          </Text>
        </View>
        <Button
          active={isChecked}
          title={"Devam Et"}
          handleFunc={() => props.changeStep(2)}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    width: "90%",
    height: 47,
    backgroundColor: "#FAF6F5",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 6,
    borderColor: "#E4E4E4",
    marginBottom: 10,
    paddingLeft: 11,
    fontSize: 14,
    fontWeight: "600",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    width: "90%",
    height: 54,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 31,
    marginTop: 60,
    marginBottom: 31,
  },
  checkBoxContainer: {
    flexDirection: "row",
    marginBottom: 25,
    marginLeft: 20,
  },
});
