import { SafeAreaProvider } from "react-native-safe-area-context";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import { Text, Platform, Image } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import HomePage from "../pages/bottomTabs/HomePage";
import CreatePostPage from "../pages/bottomTabs/CreatePostPage";
import Avatar from "../components/Avatar";
import CampaignPage from "../pages/bottomTabs/campaignTab/CampaignPage";
import LocationsPage from "../pages/bottomTabs/locationTab/LocationsPage";
import MenuPage from "../pages/bottomTabs/menuTab/MenuPage";
import CampaignDetail from "../pages/bottomTabs/campaignTab/CampaignDetail";
import PastPosts from "../pages/bottomTabs/menuTab/PastPosts";
import EditProfile from "../pages/bottomTabs/menuTab/EditProfile";
import EditPassword from "../pages/bottomTabs/menuTab/EditPassword";
import Cards from "../pages/bottomTabs/menuTab/Cards";
import Contracts from "../pages/bottomTabs/menuTab/Contracts";
import Contact from "../pages/bottomTabs/menuTab/Contact";
import EditPhone from "../pages/bottomTabs/menuTab/EditPhone";
import EditEmail from "../pages/bottomTabs/menuTab/EditEmail";
import OrderDetail from "../pages/bottomTabs/homeTab/OrderDetail";
import AddCard from "../pages/bottomTabs/menuTab/cards/AddCard";

const Tab = createBottomTabNavigator();
const Stack = createNativeStackNavigator();

function HomeStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Overview"
        component={HomePage}
        options={{ headerShown: false, gestureEnabled: false }}
      />
      <Stack.Screen
        name="CreatePostPage"
        component={CreatePostPage}
        options={{
          headerTitle: "Anasayfa",
          headerTitleAlign: "center",
          headerShown: false,
          headerStyle: {
            backgroundColor: "#DA3831",
          },
          headerTitleStyle: {
            color: "white",
            fontSize: 16,
          },
          headerTintColor: "white",
        }}
      />
      <Stack.Screen
        name="OrderDetail"
        component={OrderDetail}
        options={{
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
}

function CampaignStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Overview"
        component={CampaignPage}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="CampaignDetail"
        component={CampaignDetail}
        options={{
          headerTitle: "Anasayfa",
          headerTitleAlign: "center",
          headerShown: false,
          headerStyle: {
            backgroundColor: "#DA3831",
          },
          headerTitleStyle: {
            color: "white",
            fontSize: 16,
          },
          headerTintColor: "white",
        }}
      />
    </Stack.Navigator>
  );
}

function MenuStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Overview"
        component={MenuPage}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="PastPosts"
        component={PastPosts}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="EditProfile"
        component={EditProfile}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="EditPassword"
        component={EditPassword}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="Cards"
        component={Cards}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="Contracts"
        component={Contracts}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="Contact"
        component={Contact}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="EditPhone"
        component={EditPhone}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="EditEmail"
        component={EditEmail}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="AddCard"
        component={AddCard}
        options={{
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
}

export const BottomTabNavigator = () => {
  return (
    <SafeAreaProvider>
      <Tab.Navigator
        screenOptions={({ route }) => ({
          tabBarHideOnKeyboard: true,
          tabBarStyle: {
            height: Platform.OS === "ios" ? 85 : 65,
            paddingBottom: Platform.OS === "ios" ? 25 : 15,
          },
          headerStyle: {
            backgroundColor: "#E5E5E5",
          },
          headerShown: true,
          tabBarShowLabel: true,
          tabBarIcon: ({ focused, color }) => {
            if (route.name == "HomePage") {
              color = focused ? "#DA3831" : "#000";
            }
            return (
              <Ionicons
                name="home-outline"
                size={18}
                color={color}
                style={{ marginBottom: -10 }}
              />
            );
          },
          tabBarLabel: ({ focused, color }) => {
            if (route.name == "HomePage") {
              color = focused ? "#DA3831" : "#000";
            }
            return <Text style={{ color: color, fontSize: 8 }}>Anasayfa</Text>;
          },
        })}
      >
        <Tab.Screen
          name="HomePage"
          component={HomeStack}
          options={{
            headerShown: false,
            headerTitleAlign: "center",
            headerTitle: () => (
              <Image
                source={require("../assets/images/logo2.png")}
                style={{
                  width: 120,
                  marginTop: Platform.OS === "ios" ? 10 : 10,
                }}
                resizeMode="contain"
              />
            ),
            headerLeft: () => (
              <Ionicons
                name="notifications"
                size={24}
                color={"#DA3831"}
                style={{ paddingLeft: 15 }}
              />
            ),
            headerRight: () => <Avatar name={"H"} surname={"T"} />,
          }}
        />

        <Tab.Screen
          name="CampaignPage"
          component={CampaignStack}
          options={({ route }) => ({
            tabBarLabel: ({ focused, color }) => {
              if (route.name == "CampaignPage") {
                color = focused ? "#DA3831" : "#000";
              }
              return (
                <Text style={{ color: color, fontSize: 8 }}>Kampanya</Text>
              );
            },

            tabBarIcon: ({ focused, color }) => {
              if (route.name == "CampaignPage") {
                color = focused ? "#DA3831" : "#000";
              }
              return (
                <Ionicons
                  name="gift-outline"
                  size={24}
                  color={color}
                  style={{ marginBottom: -10 }}
                />
              );
            },
            headerTitleAlign: "center",
            headerShown: false,
            headerTitle: () => {
              if (route.name === "CampaignPage") {
                return (
                  <Image
                    source={require("../assets/images/logo2.png")}
                    style={{
                      width: 120,
                      marginTop: Platform.OS === "ios" ? 10 : 10,
                    }}
                    resizeMode="contain"
                  />
                );
              } else {
                return <></>;
              }
            },
            headerLeft: () => (
              <Ionicons
                name="notifications"
                size={24}
                color={"#DA3831"}
                style={{ paddingLeft: 15 }}
              />
            ),
            headerRight: () => <Avatar name={"H"} surname={"T"} />,
          })}
        />
        <Tab.Screen
          name="CreatePostPage"
          component={CreatePostPage}
          options={({ route }) => ({
            headerShown: false,
            tabBarLabel: ({ focused, color }) => {
              if (route.name == "CreatePostPage") {
                color = focused ? "#DA3831" : "#000";
              }
              return (
                <Text style={{ color: color, fontSize: 8 }}>Yeni Gönderi</Text>
              );
            },

            tabBarIcon: ({ focused, color }) => {
              if (route.name == "CreatePostPage") {
                color = focused ? "#DA3831" : "#000";
              }
              return (
                <Ionicons
                  name="add-circle-outline"
                  size={24}
                  color={color}
                  style={{ marginBottom: -10 }}
                />
              );
            },
          })}
        />
        <Tab.Screen
          name="LocationsPage"
          component={LocationsPage}
          options={({ route }) => ({
            headerShown: false,
            tabBarLabel: ({ focused, color }) => {
              if (route.name == "LocationsPage") {
                color = focused ? "#DA3831" : "#000";
              }
              return (
                <Text style={{ color: color, fontSize: 8 }}>Lokasyonlar</Text>
              );
            },

            tabBarIcon: ({ focused, color }) => {
              if (route.name == "LocationsPage") {
                color = focused ? "#DA3831" : "#000";
              }
              return (
                <Ionicons
                  name="location-outline"
                  size={24}
                  color={color}
                  style={{ marginBottom: -10 }}
                />
              );
            },
          })}
        />
        <Tab.Screen
          name="MenuPage"
          component={MenuStack}
          options={({ route }) => ({
            tabBarLabel: ({ focused, color }) => {
              if (route.name == "MenuPage") {
                color = focused ? "#DA3831" : "#000";
              }
              return <Text style={{ color: color, fontSize: 8 }}>Menü</Text>;
            },

            tabBarIcon: ({ focused, color }) => {
              if (route.name == "MenuPage") {
                color = focused ? "#DA3831" : "#000";
              }
              return (
                <Ionicons
                  name="menu"
                  size={24}
                  color={color}
                  style={{ marginBottom: -10 }}
                />
              );
            },
            headerShown: false,
          })}
        />
      </Tab.Navigator>
    </SafeAreaProvider>
  );
};
