import { ScrollView, StyleSheet, Text, View } from "react-native";
import React from "react";

export default function UserAgreement() {
  return (
    <ScrollView>
      <View style={{ alignItems: "center" }}>
        <Text style={styles.heading}>
          AVM LOJİSTİK TİC. A.Ş KİŞİSEL VERİLERİN KORUNMASI AYDINLATMA METNİ
        </Text>
        <Text style={styles.subHeading}>
          Kişisel verilerin Korunması Balmumcu Mah Gazi Umur Paşa Sokak Bimar
          Plaza No:38/13 Beşiktaş/İstanbul adresinde mukim, AVM Lojistik Tic.
          A.Ş’nin (“AVM LOJİSTİK”) önemli öncelikleri arasındadır. İşbu AVM
          LOJİSTİK TİC. A.Ş Kişisel Verilerin Korunması ve İşlenmesi Politikası
          Aydınlatma Metni çerçevesinde aşağıda yer alan, AVM Lojistik Tic. A.Ş
          tarafından gerçekleştirilen kişisel veri işleme faaliyetlerinin
          yürütülmesinde benimsenen ilkeler ve Şirketimizin veri işleme
          faaliyetlerinin 6698 sayılı kişisel verilerin korunması Kanunu’nda yer
          alan düzenlemelere uyumu bakımından benimsenen temel prensipler
          açıklamaktadır.
        </Text>
        {/* Tanımlar */}
        <Text style={styles.title}>TANIMLAR</Text>
        {/* Veri Sorumlusu */}
        <Text style={styles.content}>
          <Text style={styles.emphasis}>Veri Sorumlusu:</Text> AVM Lojistik Tic.
          A.Ş olarak Kişisel verileriniz veri sorumlusu sıfatıyla (“
          <Text style={styles.emphasis}>PROFINE</Text>”) tarafından, 7 Nisan
          2016 tarihli ve 29677 sayılı Resmi Gazete’de yayımlanan 6698 sayılı
          Kişisel Verilerin Korunması Kanunu (“
          <Text style={styles.emphasis}>Kanun</Text>”) uyarınca aşağıda
          açıklanan kapsamda işlenebilecektir.
        </Text>
        {/* İlgili Kişi */}
        <Text style={styles.content}>
          <Text style={styles.emphasis}>İlgili Kişi:</Text> Çeşitli amaçlarla{" "}
          <Text style={styles.emphasis}>AVM LOJİSTİK</Text> ile kişisel
          verilerini paylaşan ve bu kapsamda Kişisel verisi işlenen kişidir.
          İlgili kişilerin başında sözlü, yazılı veya elektronik olarak doğrudan
          veya dolaylı kişisel verilerini paylaşan veya{" "}
          <Text style={styles.emphasis}>AVM LOJİSTİK</Text> tarafından elde
          edilmesine olanak sağlamış çalışanlar, tedarikçiler, müşteriler{" "}
          <Text style={styles.emphasis}>AVM LOJİSTİK</Text> ile iş ilişkisi
          içinde bulunanlar, bayiiler, ziyaretçiler, iş başvurusu yapanlar yer
          almaktadır.
        </Text>
        {/* Kişisel Veriler */}
        <Text style={styles.content}>
          <Text style={styles.emphasis}>Kişisel Veriler:</Text> Kimliği belirli
          veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi ifade
          etmektedir. Bu bilgilerin başında TC Kimlik numarası ve nüfus cüzdanı
          kopyası, vukuatlı nüfus kaydı bilgisi, adres, telefon, e-posta
          adresleri gibi iletişim bilgileri; doğum yeri, cinsiyet, ikametgah,
          sabıka kaydı, medeni durum, araç bilgileri, iş düzeyine ilişkin
          bilgiler, özgeçmiş öğrenim bilgileri, transkript, gelir kaydına
          ilişkin bilgiler, not bilgileri, araç plaka bilgileri, banka hesap
          numaraları, log kayıtları, fatura bilgileri, kredi kartı bilgileri,
          varsa anne- baba kısaca aileye ait bilgiler, fotoğraf ve görüntü
          bilgileri gibi veriler yer almaktadır.{" "}
          <Text style={styles.emphasis}>
            Kişisel Verilerin Korunması Kanunu (“Kanun”) :
          </Text>{" "}
          7 Nisan 2016 tarihli ve 29677 sayılı Resmi Gazete’de yayımlanan 6698
          sayılı Kişisel Verilerin Korunması Kanunu’nu ifade eder.
        </Text>
        {/* KİŞİSEL VERİLERİNİZİN TOPLANMASI YÖNTEMİ, HUKUKİ SEBEBİ VE İŞLENME ŞARTLARI */}
        <Text style={styles.title}>
          KİŞİSEL VERİLERİNİZİN TOPLANMASI YÖNTEMİ, HUKUKİ SEBEBİ VE İŞLENME
          ŞARTLARI
        </Text>
        <Text style={styles.subHeading}>
          Kişisel verileriniz, Kanun’un 5. ve 6. maddelerinde belirtilen kişisel
          veri işleme şartları ve amaçları dâhilinde, AVM LOJİSTİK tarafından
          AVM LOJİSTİK’e gönderilen e-postalar, sosyal mecralarındaki
          paylaşımlar, AVM LOJİSTİK tarafından düzenlenen etkinlikler,
          tanıtımlar, reklam filmleri, paylaşılan formlar, anketler,
          sempozyumlar, konferanslarda yer alan kayıt formları ile iş
          başvurusunda bulunanlar tarafından paylaşılan bilgiler aracılığı ile
          toplanabilir.
        </Text>
        <View style={{ marginTop: 10 }} />
        <Text style={styles.subHeading}>
          İşbu amaçlarla toplanan kişisel verileriniz, kimlik bilgileriniz,
          iletişim bilgileriniz, özlük bilgileriniz, gerekli olduğu kadarıyla
          sağlık bilgileriniz, aile bireyleri ve yakınlarının bilgileri, çalışma
          bilgileriniz, eğitim bilgileriniz, banka hesap numarası ve malvarlığı
          verisi gibi finansal bilgileriniz, teknik, görsel ve işitsel bilgiler,
          referans bilgileri vb gibi bilgilerdir.
        </Text>
        <View style={{ marginTop: 10 }} />
        <Text style={styles.subHeading}>
          Kanunların emredici hükümleri gereği gerçek kişilerin bilgilerinin
          doğru ve güncel tutulması esastır. Bu nedenle, belirli zaman
          aralıkları ile AVM LOJİSTİK tarafından kişisel bilgilerinizin
          güncellenmesi talep edilebilir. Tarafınızdan da, bilgilerinizde
          herhangi bir değişiklik olduğu takdirde söz konusu bilgileri AVM
          LOJİSTİK’e iletmeniz beklenmektedir. AVM LOJİSTİK, hiçbir koşulda
          tarafınızca sunulan yanlış bilgiden sorumlu tutulamaz.
        </Text>
        <View style={{ marginTop: 10 }} />
        <Text style={styles.subHeading}>
          Kişisel verileriniz aşağıda yer alan amaçlar doğrultusunda
          işlenebilecektir:
        </Text>
        <View style={{ marginTop: 10 }} />
        <Text style={styles.content}>
          <Text style={styles.emphasis}>AVM LOJİSTİK</Text>’nin insan kaynakları
          politikaları ve süreçlerinin planlanması ve icra edilmesi{" "}
          <Text style={styles.emphasis}>AVM LOJİSTİK</Text> tarafından sunulan
          hizmetlerden ilgili kişileri faydalandırmak için gerekli çalışmaların
          iş birimlerimiz, bayiilerimiz tarafından yapılması ve ilgili iş
          süreçlerinin yürütülmesi{" "}
          <Text style={styles.emphasis}>AVM LOJİSTİK</Text> tarafından program
          ve hizmetlerinin ilgili kişilerin beğenisine sunulması, önerilmesi ve
          tanıtılması için gerekli olan aktivitelerin planlanması ve icrası{" "}
          <Text style={styles.emphasis}>AVM LOJİSTİK</Text> tarafından yürütülen
          faaliyetlerin gerçekleştirilmesi için ilgili iş birimlerimiz
          tarafından gerekli çalışmaların yapılması ve buna bağlı iş
          süreçlerinin yürütülmesi{" "}
          <Text style={styles.emphasis}>AVM LOJİSTİK</Text>’nin ticari ve/veya
          iş stratejilerinin planlanması ve icrası{" "}
          <Text style={styles.emphasis}>AVM LOJİSTİK</Text>‘in ve{" "}
          <Text style={styles.emphasis}>AVM LOJİSTİK</Text> ile iş ilişkisi
          içerisinde olan ilgili kişilerin hukuki, teknik ve ticari-iş
          güvenliğinin temin edilmesi Kimlik doğrulama ve kayıt oluşturulması.
          <Text style={styles.emphasis}>AVM LOJİSTİK</Text>’in kalite, bilgi
          güvenliği ve gizlilik politikalarının ve standartlarının sağlıklı
          olarak temini ve denetimi. Üst yönetime yapılacak raporlama ve
          analizlerin hazırlanması. Yazılım, kurumsal kaynak planlaması,
          raporlama, pazarlama vs. gibi işlevlerin yerine getirilmesi. Kanun ve
          yönetmelikler ile belirlenmiş gerekliliklerin ve yasal yükümlülüklerin
          ifa edilmesi (İş Mevzuatı, borçlar hukuku mevzuatı, ticaret hukuku
          mevzuatı, elektronik iletişim ile ilgili mevzuat ve sair ilgili
          mevzuat). E-fatura, e-arşiv ve e-irsaliye ile ilgili yükümlülüklerin
          yerine getirilmesi. Yasal düzenlemelerin gerektirdiği veya zorunlu
          kıldığı şekilde kamu kurum ve kuruluşlarının taleplerinin yerine
          getirilmesi.
        </Text>
        {/* İŞLENEN KİŞİSEL VERİLERİNİZİN KİMLERE VE HANGİ AMAÇLARLA AKTARILABİLECEĞİ */}
        <Text style={styles.title}>
          İŞLENEN KİŞİSEL VERİLERİNİZİN KİMLERE VE HANGİ AMAÇLARLA
          AKTARILABİLECEĞİ
        </Text>
        <Text style={styles.subHeading}>
          Paylaşılmış ve bu kapsamda toplanan kişisel verileriniz, iş
          birimlerimiz tarafından Kanun tarafından öngörülen temel ilkelere
          uygun olarak ve Kanun’un 8. ve 9. maddelerinde belirtilen kişisel veri
          işleme şartları ve amaçları dâhilinde, aşağıda yer alan amaçlar
          doğrultusunda iş ortaklarımıza, tedarikçilerimize, müşterilerimize,
          Pazar araştırma şirketlerine, çağrı hizmeti veren şirketlere, kargo
          şirketlerine, hukuk bürolarına, muhasebe bürolarına, denetim
          şiketlerine, AVM LOJİSTİK
        </Text>
        {/* KİŞİSEL VERİ SAHİBİ OLARAK KANUN’UN 11. MADDESİNDE SAYILAN HAKLARI */}
        <Text style={styles.title}>
          KİŞİSEL VERİ SAHİBİ OLARAK KANUN’UN 11. MADDESİNDE SAYILAN HAKLARI
        </Text>
        <Text style={styles.subHeading}>
          İşbu aydınlatma Metninde yer alan düzenlemeler ile ilgili olarak
          tarafımızca, aşağıda yer alan haklarınıza ilişkin taleplerinizi AVM
          LOJİSTİK’e iletmeniz halinde AVM LOJİSTİK talebin niteliğine göre en
          geç otuz gün içerisinde ilgili talebinizi sonuçlandıracaktır. Ancak
          kurulca bir ücret öngörüldüğü takdirde, AVM LOJİSTİK, tarafından
          belirlenen tarifedeki ücret alınacaktır. Bu anlamda Kişisel veri
          sahibi olarak Kanun’un 11. maddesi uyarınca aşağıdaki haklara
          sahipsiniz:
        </Text>
        <View style={{ marginTop: 10 }} />
        <Text style={styles.content}>
          Kişisel verilerinizin işlenip işlenmediğini öğrenme, Kişisel
          verileriniz işlenmişse buna ilişkin bilgi talep etme, Kişisel
          verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp
          kullanılmadığını öğrenme, Yurt içinde veya yurt dışında kişisel
          verilerinizin aktarıldığı üçüncü kişileri bilme, Kişisel verilerinizin
          eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini
          isteme ve bu kapsamda yapılan işlemin kişisel verilerinizin
          aktarıldığı üçüncü kişilere bildirilmesini isteme, Kanun’a ve ilgili
          diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen,
          işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel
          verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda
          yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere
          bildirilmesini isteme, İşlenen verilerin münhasıran otomatik sistemler
          vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya
          çıkması durumunda buna itiraz etme, Kişisel verilerinizin kanuna
          aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın
          giderilmesini talep etme.
        </Text>
        <View style={{ marginTop: 10 }} />
        <Text style={styles.subHeading}>
          Yukarıda belirtilen haklarınızı kullanmak için kimliğinizi tespit
          edici gerekli bilgi ve belgeler ile KVK Kanunu’nun 11. Maddesinde
          belirtilen haklardan kullanmayı talep ettiğiniz hakkınıza yönelik
          açıklamalarınızı içeren talebinizi,
        </Text>
        <View style={{ marginTop: 10 }} />
        <Text style={styles.subHeading}>
          Balmumcu Mah Gazi Umur Paşa Sokak Bimar Plaza No:38/13
          Beşiktaş/İstanbul adresine bizzat elden iletebilir, noter kanalıyla
          veya KVK Kanununda belirtilen diğer yöntemler ile gönderebilir veya
          talebinizi info@malllogistics.com adresine güvenli elektronik imzalı
          olarak iletebilirsiniz.
        </Text>
        {/* METİNDEKİ DEĞİŞİKLİKLER */}
        <Text style={styles.title}>METİNDEKİ DEĞİŞİKLİKLER</Text>
        <Text style={styles.subHeading}>
          AVM LOJİSTİK, işbu Metin’de yer alan hükümleri dilediği zaman
          yayımlamak suretiyle değiştirebilir. AVM LOJİSTİK ‘in değişiklik
          yaptığı hükümler yayınlandığı tarihte yürürlük kazanır.
          Saygılarımızla,
        </Text>
        <View style={{ marginTop: 30 }} />
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  heading: {
    fontSize: 14,
    fontWeight: "600",
    textAlign: "center",
    paddingHorizontal: 5,
    marginBottom: 10,
  },
  subHeading: {
    fontSize: 13,
    fontWeight: "500",
    paddingHorizontal: 25,
    textAlign: "center",
  },
  title: {
    fontSize: 14,
    fontWeight: "700",
    paddingHorizontal: 15,
    textAlign: "center",
    marginVertical: 10,
  },
  content: {
    fontSize: 12,
    fontWeight: "400",
    paddingHorizontal: 30,
    textAlign: "center",
  },
  emphasis: {
    fontWeight: "500",
    fontSize: 14,
  },
});
