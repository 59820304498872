import axios from "axios";
import { BASE_URL } from ".";

// const instance = axios.create({
//   baseURL: BASE_URL,
//   timeout: 1000,
// });

export default {
  GetProfile(id, token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/get-profile`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: `${id}`,
      },
    });
  },

  SetPhone(id, phone, token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/set-profile`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: `${id}`,
        phone: `${phone}`,
      },
    });
  },

  SetEmail(id, email, token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/set-profile`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: `${id}`,
        email: `${email}`,
      },
    });
  },

  CheckPassword(id, password, token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/check-password`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: `${id}`,
        password: `${password}`,
      },
    });
  },

  SetPassword(id, password, token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/set-profile`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: `${id}`,
        password: `${password}`,
      },
    });
  },

  GetCenters(token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/easypoint-centers`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  },

  GetCampaigns(token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/campaigns/get`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  },

  GetOrders(id, token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/get-orders`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: `${id}`,
      },
    });
  },

  AddInvoice(
    token,
    corporate,
    isCargo,
    name,
    lastname,
    uID,
    tckn,
    cTitle,
    taxNo,
    taxAdministration,
    email,
    oID,
    oTitle,
    bCity,
    bRegion,
    bNeighbour,
    bAddress,
    bPhone,
    sCity,
    sRegion,
    sAddress,
    sPhone,
    firm,
    trackNo
  ) {
    console.log({
      customer: {
        InvoiceCustomerID: `${uID}`,
      },
    });
    // console.log({
    //   token: token,
    //   corporate: corporate,
    //   isCargo: isCargo,
    //   name: name,
    //   lastname: lastname,
    //   uID: uID,
    //   tckn: tckn,
    //   cTitle: cTitle,
    //   taxNo: taxNo,
    //   taxAdministration: taxAdministration,
    //   email: email,
    //   oID: oID,
    //   oTitle: oTitle,
    //   bStreet: bAddress,
    //   bNeighbour: bNeighbour,
    //   bRegion: bRegion,
    //   bCity: bCity,
    //   bPhone: bPhone,
    //   sRegion: sRegion,
    //   sCity: sCity,
    //   sAddress: sAddress,
    //   sPhone: sPhone,
    //   firm: firm,
    //   trackNo: trackNo,
    // });
    return axios({
      method: "post",
      url: `${BASE_URL}/invoices/add`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        customer: {
          name: corporate === false ? `${name}` : `${cTitle}`,
          lastname: corporate === false ? `${lastname}` : `${cTitle}`,
          uID: `${uID}`,
          tckn: corporate === false ? `${tckn}` : `${taxNo}`,
          isCorporational: false,
        },
        company: {
          title: corporate === false ? `${name} ${lastname}` : `${cTitle}`,
          taxNo: corporate === false ? `${tckn}` : `${taxNo}`,
          taxAdministration:
            corporate === false ? `Kişisel Fatura` : `${taxAdministration}`,
          email: corporate === false ? `Kişisel Fatura` : `${email}`,
        },
        order: {
          id: `${oID}`,
          title: `${oTitle}`,
          deliveryType: "Paket",
          barcode: `${oID}`,
          quantity: 1,
          unit: "Piece",
          couponCode: false,
          status: 3,
          sku: "EP01",
          taxRate: 18,
          options: false,
        },
        billing: {
          id: `${oID}`,
          street: `${bAddress}`,
          neighbour: `${bNeighbour}`,
          region: `${bRegion}`,
          city: `${bCity}`,
          country: `Türkiye`,
          address: `${bAddress}`,
          phone: `${bPhone}`,
        },
        shipment: {
          id: `${oID}`,
          street: `${sAddress}`,
          neighbour: `mahalle`,
          region: `${sRegion}`,
          city: `${sCity}`,
          country: `Türkiye`,
          address: `${sAddress}`,
          phone: `${sPhone}`,
        },
        cargo: {
          firm: isCargo === 0 ? `${firm}` : `EasyPoint`,
          trackNo: isCargo === 0 ? `${trackNo}` : `${oID}`,
        },
      },
    });
  },

  Payment(sessionId, customerId, cardName, cardNumber, cardDate, cardCvv) {
    console.log({
      sessionId: sessionId,
      customerId: customerId,
      cardName: `${cardName}`,
      cardNumber: `${cardNumber}`,
      cardDate: `${cardDate}`,
      cardCvv: `${cardCvv}`,
      price: "1",
      callbackUrl: "https://box.easypoint.com.tr/sonuc.html",
    });
    return axios({
      method: "post",
      url: `https://api.easypoint.com.tr/payment-api`,
      headers: {
        Authorization: `Basic RWFzeVBheW1lbnRBcGlVc2VyOk03dXJNJU81M1hNJA==`,
        "Content-Type": "application/json",
      },
      data: {
        sessionId: sessionId,
        customerId: customerId,
        cardName: cardName.toString(),
        cardNumber: cardNumber.toString(),
        cardDate: cardDate.toString(),
        cardCvv: cardCvv.toString(),
        price: "1",
        callbackUrl: "https://box.easypoint.com.tr/sonuc.html",
      },
    });
  },

  PaymentSavedCard(cardUserKey, userId, cardToken) {
    console.log({
      cardUserKey: cardUserKey,
      userId: userId,
      cardToken: cardToken,
    });
    return axios({
      method: "post",
      url: `https://api.easypoint.com.tr/payment-saved-card-api`,
      headers: {
        Authorization: `Basic RWFzeVBheW1lbnRBcGlVc2VyOk03dXJNJU81M1hNJA==`,
        "Content-Type": "application/json",
      },
      data: {
        cardUserKey: cardUserKey,
        userId: userId,
        cardToken: cardToken,
        price: "1",
        callbackUrl: "https://box.easypoint.com.tr/sonuc.html",
      },
    });
  },

  CheckPayment(paymentId) {
    return axios({
      method: "get",
      url: `https://api.easypoint.com.tr/payment-check/${paymentId}`,
      headers: {
        Authorization: `Basic RWFzeVBheW1lbnRBcGlVc2VyOk03dXJNJU81M1hNJA==`,
        "Content-Type": "application/json",
      },
    });
  },

  CheckSavedCardPayment(paymentId) {
    return axios({
      method: "get",
      url: `https://api.easypoint.com.tr/payment-saved-check/${paymentId}`,
      headers: {
        Authorization: `Basic RWFzeVBheW1lbnRBcGlVc2VyOk03dXJNJU81M1hNJA==`,
        "Content-Type": "application/json",
      },
    });
  },

  CreateOrder(
    token,
    poiID,
    title,
    sendBy,
    uID,
    name,
    lastname,
    phone,
    paymentReferenceID,
    accountantReferenceID,
    shipmentFirm,
    shipmentNo,
    additionalNote,
    payment
  ) {
    console.log({
      token: token,
      poiID: poiID,
      title: title,
      sendBy: sendBy,
      uID: uID,
      name: name,
      lastname: lastname,
      phone: phone,
      paymentReferenceID: paymentReferenceID,
      accountantReferenceID: accountantReferenceID,
      shipmentFirm: shipmentFirm,
      shipmentNo: shipmentNo,
      additionalNote: additionalNote,
      payment: payment,
    });
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/create-order`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        poiID: `${poiID}`,
        title: `${title}`,
        sendBy: `${sendBy}`,
        uID: `${uID}`,
        customer: {
          name: `${name}`,
          lastname: `${lastname}`,
          phone: `${phone}`,
        },
        paymentReferenceID: `${paymentReferenceID}`,
        accountantReferenceID: `${accountantReferenceID}`,
        shipmentFirm: `${shipmentFirm}`,
        shipmentNo: `${shipmentNo}`,
        additionalNote: `${additionalNote}`,
        payment: payment,
      },
    });
  },

  CancelOrder(token, id, reason) {
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/cancel-order`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: `${id}`,
        reason: `${reason}`,
      },
    });
  },

  SaveCard(
    cardAlias,
    cardHoldername,
    cardNumber,
    expireMonth,
    expireYear,
    email,
    userId
  ) {
    console.log({
      cardAlias: cardAlias,
      cardHoldername: cardHoldername,
      cardNumber: cardNumber,
      expireMonth: expireMonth,
      expireYear: expireYear,
      email: email,
      userId: userId,
    });
    return axios({
      method: "post",
      url: "https://api.easypoint.com.tr/Box/CreateUserAndCard",
      headers: {
        Authorization: `Basic RWFzeVBheW1lbnRBcGlVc2VyOk03dXJNJU81M1hNJA==`,
        "Content-Type": "application/json",
      },
      data: {
        cardAlias: `${cardAlias}`,
        cardHoldername: `${cardHoldername}`,
        cardNumber: `${cardNumber}`,
        expireMonth: `${expireMonth}`,
        expireYear: `${expireYear}`,
        email: `${email}`,
        userId: `${userId}`,
      },
    });
  },

  GetCards(userId) {
    return axios({
      method: "get",
      url: `https://api.easypoint.com.tr/Box/CCListByUserId?userId=${userId}`,
      headers: {
        Authorization: `Basic RWFzeVBheW1lbnRBcGlVc2VyOk03dXJNJU81M1hNJA==`,
        "Content-Type": "application/json",
      },
    });
  },

  DeleteCard(userId, cardToken, cardUserKey) {
    console.log(userId, cardToken, cardUserKey);
    return axios({
      method: "post",
      url: `https://api.easypoint.com.tr/Box/RemoveCc`,
      headers: {
        Authorization: `Basic RWFzeVBheW1lbnRBcGlVc2VyOk03dXJNJU81M1hNJA==`,
        "Content-Type": "application/json",
      },
      data: {
        userId: `${userId}`,
        cardToken: `${cardToken}`,
        cardUserKey: `${cardUserKey}`,
      },
    });
  },
};
