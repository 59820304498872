import { Image, View, Platform } from "react-native";
import React from "react";
import { useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Services from "../../services/Services";
import { useDispatch } from "react-redux";
import {
  setName,
  setLastname,
  setEmail,
  setPhone,
  setID,
} from "../../redux/registerReducer";
import { StatusBar } from "expo-status-bar";
import { StackActions } from "@react-navigation/native";

export default function SplashPage({ navigation }) {
  const dispatch = useDispatch();

  const LoginControl = async () => {
    const token = await AsyncStorage.getItem("token");
    const userID = await AsyncStorage.getItem("userID");
    if (userID === null) {
      // navigation.navigate("LoginPage");
      navigation.dispatch(StackActions.replace("LoginPage"));
    } else {
      Services.GetProfile(userID, token).then(async (res) => {
        dispatch(setName(res.data.result.name));
        dispatch(setLastname(res.data.result.lastname));
        dispatch(setEmail(res.data.result.email));
        dispatch(setPhone(res.data.result.phone));
        dispatch(setID(res.data.result.id));
        console.log("kullanıcı id:", res.data.result.id);
        navigation.dispatch(StackActions.replace("HomePage"));
      });
    }
  };

  // {
  //   Platform.OS === "web"
  //     ? useEffect(() => {
  //         window.open("https://www.google.com.tr", "_self");
  //       }, [])
  //     : useEffect(() => {
  //         setTimeout(() => {
  //           LoginControl();
  //         }, 1500);
  //       }, []);
  // }

  useEffect(() => {
    setTimeout(() => {
      LoginControl();
    }, 1500);
  }, []);

  return (
    <View
      style={{
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <StatusBar style="auto" />
      <Image
        source={require("../../assets/images/logo.png")}
        style={{ width: 160 }}
        resizeMode="contain"
      />
    </View>
  );
}
