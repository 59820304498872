import * as React from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { BottomTabNavigator } from "./BottomTabNavigation";
import { StackNavigation } from "./StackNavigation";
import { NavigationContainer } from "@react-navigation/native";
import { Provider } from "react-redux";
import store from "../redux/store";

const AppStack = createNativeStackNavigator();

export default function Navigation() {
  return (
    <Provider store={store}>
      <NavigationContainer>
        <SafeAreaProvider>
          <AppStack.Navigator>
            <AppStack.Screen
              name="StackNavigation"
              component={StackNavigation}
              options={{ headerShown: false }}
            />
            <AppStack.Screen
              name="BottomTabNavigator"
              component={BottomTabNavigator}
              options={{ headerShown: false, gestureEnabled: false }}
            />
          </AppStack.Navigator>
        </SafeAreaProvider>
      </NavigationContainer>
    </Provider>
  );
}
