import axios from "axios";
import { BASE_URL, KEY, SECRET } from ".";

export default {
  GetToken() {
    return axios({
      method: "post",
      url: `${BASE_URL}/get-token`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        key: KEY,
        secret: SECRET,
      },
    });
  },
};
