import {
  StyleSheet,
  Text,
  TextInput,
  View,
  TouchableOpacity,
} from "react-native";
import React from "react";
import { useState } from "react";
import { FontAwesome } from "@expo/vector-icons";
import Button from "../Button";
import { useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Services from "../../services/Services";
import { MaskedTextInput } from "react-native-mask-text";
import SelectDropdown from "react-native-select-dropdown";
import { Ionicons } from "@expo/vector-icons";
import Checkbox from "expo-checkbox";

export default function StepSix(props) {
  const [buttonLoading, setButtonLoading] = useState(false);

  const [cardName, setCardName] = useState("");
  const [cardNo, setCardNo] = useState("");
  const [cardDate, setCardDate] = useState("");
  const [cardCvv, setCardCvv] = useState("");

  const [customerId, setCustomerId] = useState("");
  const [orderID, setOrderID] = useState(props.orderID);
  const [html, setHtml] = useState(props.html);
  const [paymentId, setPaymentId] = useState(props.paymentId);

  const [cardUserKey, setCardUserKey] = useState(props.cardUserKey);
  const [cardToken, setCardToken] = useState(props.cardToken);
  const [cards, setCards] = useState(props.cards);
  const [selectItems, setSelectItems] = useState([]);
  const [isSavedCard, setIsSavedCard] = useState(props.isSavedCard); // Kayıtlı kartı ile ödeme yapılsın mı?
  const [saveThisCard, setSaveThisCard] = useState(props.saveThisCard); // Kart Bilgileri Kaydedilsin mi ?

  const [cardAlias, setCardAlias] = useState("");
  const [cardEmail, setCardEmail] = useState("");

  const SaveCard = () => {
    const month = cardDate.slice(0, 2);
    const year = `20${cardDate.slice(2, 4)}`;
    Services.SaveCard(
      cardAlias,
      cardName,
      cardNo,
      month,
      year,
      cardEmail,
      customerId
    ).then((res) => {
      console.log(res.data);
      if (res.data.status === "success") {
        console.log(res.data);
        alert("Kartınız başarıyla kaydedildi. Ödeme gerçekleşiyor.");
        console.log(res.data);
        CompletePayment();
      } else {
        if (res.data.errorCode === "conflict") {
          CompletePayment();
        } else {
          alert(`${res.data.errorMessage}`);
        }
      }
    });
    // .catch((err) => {
    //   console.log(err);
    //   alert("Kartınız kaydedilemedi. Lütfen tekrar deneyiniz.");
    // });
  };

  const CompletePayment = () => {
    setButtonLoading(true);
    if (isSavedCard) {
      Services.PaymentSavedCard(cardUserKey, customerId, cardToken)
        .then((res) => {
          setPaymentId(res.data.paymentId);
          props.changePaymentId(res.data.paymentId);
          console.log(res.data.paymentId);
          console.log("customerID", customerId);
          setHtml(res.data.htmlContent);
          props.changeHtml(res.data.htmlContent);

          props.changeStep(7);
          setButtonLoading(false);
        })
        .catch((err) => {
          setButtonLoading(false);
          alert("Ödeme işlemi başarısız. Lütfen tekrar deneyiniz.");
        });
    } else {
      Services.Payment(
        Number(orderID),
        Number(customerId),
        cardName,
        cardNo,
        cardDate,
        cardCvv
      )
        .then((res) => {
          console.log("customerID", customerId);
          console.log("orderID", orderID);
          setPaymentId(res.data.paymentId);
          props.changePaymentId(res.data.paymentId);
          setHtml(res.data.htmlContent);
          props.changeHtml(res.data.htmlContent);
          props.changeStep(7);
          setButtonLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setButtonLoading(false);
          alert("Ödeme işlemi başarısız. Lütfen tekrar deneyiniz.");
        });
    }
  };

  const SelectCard = (number) => {
    const token = cards[number].cardToken;
    setCardToken(token);
    props.changeCardToken(token);
    console.log(token, " ", cardUserKey, customerId);
  };

  const FetchCards = async () => {
    const id = await AsyncStorage.getItem("userID");
    setCustomerId(id);
    Services.GetCards(id)
      .then((res) => {
        setCards(res.data.cardDetails);
        props.changeCards(res.data.cardDetails);
        setCardUserKey(res.data.cardUserKey);
        props.changeCardUserKey(res.data.cardUserKey);
        const items = [];
        for (let i = 0; i < res.data.cardDetails.length; i++) {
          items.push(
            res.data.cardDetails[i].cardAlias +
              " - " +
              res.data.cardDetails[i].binNumber.substring(0, 4) +
              " " +
              res.data.cardDetails[i].binNumber.substring(4, 8)
          );
        }
        setSelectItems(items);
        console.log(items);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    FetchCards();
  }, []);

  return (
    <View style={{ height: "100%" }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: 20,
          marginTop: 18,
        }}
      >
        <Text
          style={{
            fontWeight: "700",
            fontSize: 14,
            lineHeight: 19.07,
            paddingLeft: 20,
          }}
        >
          Ödeme Tutarınız
        </Text>
        <Text
          style={{
            paddingRight: 20,
            lineHeight: 19.07,
            fontWeight: "700",
            color: "#DA3831",
          }}
        >
          15,00 TL
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            props.changeIsSavedCard(false);
            setIsSavedCard(false);
            setCardToken("");
            props.changeCardToken("");
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View
              style={{
                width: 20,
                height: 20,
                borderWidth: 1,
                borderRadius: 10,
                borderColor: isSavedCard === false ? "#DA3831" : "#000",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: 20,
              }}
            >
              <View
                style={{
                  height: 13,
                  width: 13,
                  borderRadius: 7,
                  backgroundColor: isSavedCard == false ? "#DA3831" : "white",
                }}
              />
            </View>
            <Text
              style={{
                fontWeight: "600",
                fontSize: 12,
                lineHeight: 16.34,
                paddingLeft: 5,
                color: isSavedCard === false ? "#DA3831" : undefined,
              }}
            >
              Yeni kart ile öde
            </Text>
          </View>
        </TouchableOpacity>

        <TouchableOpacity
          onPress={() => {
            props.changeIsSavedCard(true);
            setIsSavedCard(true);
            setSaveThisCard(false);
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View
              style={{
                width: 20,
                height: 20,
                borderWidth: 1,
                borderRadius: 10,
                borderColor: isSavedCard === true ? "#DA3831" : "#000",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: 20,
              }}
            >
              <View
                style={{
                  height: 13,
                  width: 13,
                  borderRadius: 7,
                  backgroundColor: isSavedCard == true ? "#DA3831" : "white",
                }}
              />
            </View>
            <Text
              style={{
                fontWeight: "600",
                fontSize: 12,
                lineHeight: 16.34,
                paddingLeft: 5,
                color: isSavedCard === true ? "#DA3831" : undefined,
              }}
            >
              Kayıtlı kart ile öde
            </Text>
          </View>
        </TouchableOpacity>
      </View>
      {isSavedCard == true ? (
        <>
          {cards.length == 0 ? (
            <Text style={{ textAlign: "center", marginBottom: 20 }}>
              Kayıtlı kart bulunmamaktadır.
            </Text>
          ) : (
            <SelectDropdown
              data={selectItems}
              defaultButtonText="Kart Seçiniz"
              buttonTextStyle={{
                textAlign: "left",
                fontWeight: "600",
                fontSize: 14,
                lineHeight: 19.07,
                color: cardToken == "" ? "#848484" : "#000",
              }}
              onSelect={(selectedItem, index) => {
                SelectCard(index);
              }}
              buttonStyle={styles.input}
              renderDropdownIcon={() => {
                return <Ionicons name="chevron-down" size={20} color="black" />;
              }}
            />
          )}
        </>
      ) : (
        <>
          {saveThisCard && (
            <>
              <TextInput
                value={cardAlias}
                onChangeText={(e) => {
                  setCardAlias(e);
                }}
                placeholder="Kartınızın İsmi"
                style={styles.input}
                maxLength={50}
              />
              <TextInput
                value={cardEmail}
                onChangeText={(e) => {
                  setCardEmail(e);
                }}
                placeholder="E-posta"
                style={styles.input}
              />
            </>
          )}
          <TextInput
            value={cardName}
            onChangeText={(e) => {
              setCardName(e);
            }}
            placeholder="Kart Üzerindeki İsim/Soyad"
            style={styles.input}
          />
          <View style={[styles.input, { flexDirection: "row" }]}>
            <FontAwesome name="credit-card" size={18} color="black" />
            <MaskedTextInput
              mask="9999 9999 9999 9999"
              value={cardNo}
              onChangeText={(formatted, extracted) => {
                setCardNo(extracted);
              }}
              style={{
                paddingLeft: 10,
                fontSize: 14,
                color: "black",
                fontWeight: "600",
                height: 47,
              }}
              maxLength={19}
              textBold
              placeholder="Kart Numarası"
              keyboardType="number-pad"
              returnKeyType={"done"}
            />
          </View>
          <View style={{ flexDirection: "row" }}>
            <View
              style={[
                styles.input,
                {
                  width: "20%",
                  alignSelf: "center",
                  marginLeft: "5%",
                  paddingLeft: 0,
                  flexDirection: "row",
                  justifyContent: "center",
                },
              ]}
            >
              <MaskedTextInput
                mask="99/99"
                value={cardDate}
                onChangeText={(formatted, text) => {
                  setCardDate(text);
                }}
                placeholder="AA/YY"
                maxLength={5}
                textBold
                keyboardType="number-pad"
                returnKeyType={"done"}
              />
            </View>

            <TextInput
              value={cardCvv}
              onChangeText={(text) => {
                setCardCvv(text);
              }}
              style={[
                styles.input,
                {
                  width: "20%",
                  alignSelf: "flex-start",
                  marginLeft: 15,
                  textAlign: "center",
                  paddingLeft: 0,
                },
              ]}
              placeholder="CVV"
              maxLength={3}
              keyboardType="number-pad"
              returnKeyType={"done"}
            />
          </View>
        </>
      )}

      <View style={{ marginTop: 50 }}>
        {!isSavedCard && (
          <View
            style={{
              flexDirection: "row",
              marginBottom: 20,
              marginHorizontal: 20,
            }}
          >
            <Checkbox
              style={{ borderRadius: 4, borderColor: "#E0DEE4" }}
              value={saveThisCard}
              onValueChange={() => {
                setSaveThisCard(!saveThisCard);
                props.changeSaveThisCard(!saveThisCard);
              }}
              color={saveThisCard ? "#DA3831" : undefined}
            />
            <Text style={{ paddingLeft: 10 }}>
              Kart bilgilerimi daha sonraki ödemeler için kaydet
            </Text>
          </View>
        )}

        <Button
          isLoad={buttonLoading}
          active={
            isSavedCard === false
              ? cardName !== "" &&
                cardNo.length === 16 &&
                cardDate.length === 4 &&
                cardCvv.length === 3
              : cardToken !== ""
          }
          title={saveThisCard ? "ÖDE VE KAYDET" : "ÖDE"}
          handleFunc={() => (saveThisCard ? SaveCard() : CompletePayment())}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    width: "90%",
    height: 47,
    backgroundColor: "#FAF6F5",
    alignItems: "center",
    borderRadius: 6,
    paddingLeft: 11,
    fontSize: 14,
    fontWeight: "700",
    marginBottom: 17,
    alignSelf: "center",
  },
});
