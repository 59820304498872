import axios from "axios";
import { BASE_URL } from ".";

export default {
  GetLogin(username, password, token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/login`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        username: `${username}`,
        password: `${password}`,
      },
    });
  },

  GetRegister(name, lastname, email, phone, password, token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/register`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        name: `${name}`,
        lastname: `${lastname}`,
        email: `${email}`,
        phone: `${phone}`,
        password: `${password}`,
      },
    });
  },

  SendSMS(phone, sms, token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/send-sms`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        phone: `${phone}`,
        sms: `${sms}`,
      },
    });
  },
};
