import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React from "react";

export default function CampaignCard({ uri, title, _navigation }) {
  return (
    <TouchableOpacity onPress={_navigation} style={styles.card}>
      <Image style={styles.image} resizeMode="stretch" source={uri} />
      <Text style={[styles.text]}>{title}</Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  card: {
    width: "90%",
    height: 288,
    backgroundColor: "#fff",
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    marginTop: 10,
    marginBottom: 20,
    alignSelf: "center",
  },
  image: {
    width: "100%",
    height: 208,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  text: {
    maxHeight: 70,
    fontSize: 14,
    fontWeight: "700",
    lineHeight: 19.07,
    paddingHorizontal: 15,
    paddingVertical: 5,
  },
});
