import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  TextInput,
  Dimensions,
  Platform,
  Alert,
  BackHandler,
  KeyboardAvoidingView,
  ScrollView,
} from "react-native";
import React, { useEffect, useState } from "react";
import { StatusBar } from "expo-status-bar";
import { Ionicons } from "@expo/vector-icons";
import Button from "../../components/Button";
import AsyncStorage from "@react-native-async-storage/async-storage";
import AuthService from "../../services/AuthService";
import { useDispatch } from "react-redux";
import {
  setName,
  setLastname,
  setEmail,
  setPhone,
  setID,
} from "../../redux/registerReducer";
import { useBackHandler } from "@react-native-community/hooks";
import { StackActions } from "@react-navigation/native";

export default function LoginPage({ navigation }) {
  const dispatch = useDispatch();
  const windowHeight = Dimensions.get("window").height;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const Login = async (username, password) => {
    setButtonLoading(true);
    const token = await AsyncStorage.getItem("token");
    if (username !== "" && password !== "") {
      AuthService.GetLogin(username, password, token).then(async (res) => {
        if (res.data.status) {
          await AsyncStorage.setItem("userID", res.data.result.id);
          await AsyncStorage.setItem("name", res.data.result.name);
          await AsyncStorage.setItem("lastname", res.data.result.lastname);
          dispatch(setName(res.data.result.name));
          dispatch(setLastname(res.data.result.lastname));
          dispatch(setEmail(res.data.result.email));
          dispatch(setPhone(res.data.result.gsm));
          dispatch(setID(res.data.result.id));
          console.log("kullanıcı id:", res.data.result.id);
          navigation.navigate("BottomTabNavigator", { screen: "HomePage" });
          // navigation.dispatch(
          //   StackActions.replace("BottomTabNavigator", { screen: "HomePage" })
          // );
          setButtonLoading(false);
          setUsername("");
          setPassword("");
        } else {
          alert("Kullanıcı adı veya şifre hatalıdır.");
          setButtonLoading(false);
        }
      });
    } else {
      alert("Kullanıcı adı veya şifre boş olmamalıdır.");
      setButtonLoading(false);
    }
  };

  // const logControl = () => {
  //   try {
  //     AsyncStorage.getItem("userID").then((value) => {
  //       if (value !== null) {
  //         navigation.navigate("HomePage");
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  function backActionHandler() {
    Alert.alert("", "Uygulamayı kapatmak istediğinize emin misiniz?", [
      {
        text: "Hayır",
        onPress: () => null,
        style: "cancel",
      },
      {
        text: "Evet",
        onPress: () => BackHandler.exitApp(),
      },
    ]);
    return true;
  }

  useBackHandler(backActionHandler);

  useEffect(() => {
    // logControl();
  }, []);

  return (
    <KeyboardAvoidingView style={styles.container} behavior={"height"}>
      <StatusBar style="auto" />
      <ScrollView>
        <View
          style={{
            height: windowHeight,
            justifyContent: "space-between",
          }}
        >
          {/* flex-start */}
          <Image
            source={require("../../assets/images/logo.png")}
            style={styles.logo}
            resizeMode="contain"
          />
          {/* center */}
          <View style={{ alignItems: "center", justifyContent: "center" }}>
            <View>
              <Text style={styles.title}>Giriş Yap</Text>
              <Text style={styles.context}>
                Lütfen kullanıcı adı ve şifrenizi giriniz.
              </Text>
            </View>
            <View style={{ justifyContent: "center", marginTop: 30 }}>
              <TextInput
                style={styles.input}
                value={username}
                placeholder="Kullanıcı adı"
                keyboardType="ascii-capable"
                placeholderTextColor={"#A7A7A7"}
                onChangeText={(x) => {
                  setUsername(x);
                }}
              />
              <View
                style={[
                  styles.input,
                  { flexDirection: "row", marginBottom: 40 },
                ]}
              >
                <TextInput
                  style={{
                    flex: 1,
                    justifyContent: "flex-start",
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                  value={password}
                  placeholder="Şifre"
                  secureTextEntry={!show}
                  onChangeText={(x) => setPassword(x)}
                  maxLength={6}
                  keyboardType="number-pad"
                  placeholderTextColor={"#A7A7A7"}
                  returnKeyType={"done"}
                />
                <TouchableOpacity onPress={() => setShow(!show)}>
                  {show == true ? (
                    <Ionicons
                      name="eye-off"
                      size={24}
                      color="black"
                      style={{ paddingRight: 15, alignSelf: "center" }}
                    />
                  ) : (
                    <Ionicons
                      name="eye"
                      size={24}
                      color="black"
                      style={{ paddingRight: 15, alignSelf: "center" }}
                    />
                  )}
                </TouchableOpacity>
              </View>
            </View>
            <Button
              handleFunc={() => Login(username, password)}
              active={true}
              title={"Giriş Yap"}
              isLoad={buttonLoading}
            />
            {/* <Text
              style={{
                color: "#5E596D",
                fontSize: 16,
                fontWeight: "600",
                marginTop: 40,
              }}
            >
              Ya da şunlarla devam et
            </Text>
            <View
              style={{
                flexDirection: "row",
                width: 182,
                justifyContent: "space-between",
              }}
            >
              <View style={styles.socialCircles}>
                <Image
                  source={require("../../assets/images/google.png")}
                  style={{ width: 24, height: 24 }}
                />
              </View>
              <View style={styles.socialCircles}>
                <Image
                  source={require("../../assets/images/face.png")}
                  style={{ width: 24, height: 24 }}
                />
              </View>
              <View style={styles.socialCircles}>
                <Image
                  source={require("../../assets/images/apple.png")}
                  style={{ width: 24, height: 24 }}
                />
              </View>
            </View> */}
          </View>
          {/* flex-end */}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              marginBottom: Platform.OS === "ios" ? 90 : 30,
            }}
          >
            <Text style={{ fontWeight: "300", fontSize: 14, marginRight: 20 }}>
              Henüz bir hesabın yok mu?
            </Text>
            <TouchableOpacity
              onPress={() => navigation.navigate("CreateAccountPage")}
            >
              <Text
                style={{ color: "#DA3831", fontWeight: "700", fontSize: 14 }}
              >
                Kayıt Ol
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  logo: {
    width: 120,
    alignSelf: "center",
    marginTop: 30,
  },
  title: {
    fontSize: 22,
    fontWeight: "700",
    textAlign: "center",
    // marginTop: 50,
  },
  context: {
    fontSize: 14,
    fontWeight: "400",
    textAlign: "center",
    color: "#5E596D",
    marginTop: 8,
    lineHeight: 19.07,
  },
  input: {
    width: 337,
    height: 47,
    backgroundColor: "#FAF6F5",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 6,
    borderColor: "#E4E4E4",
    marginTop: 12,
    paddingLeft: 11,
    fontSize: 16,
    fontWeight: "600",
  },
  socialCircles: {
    marginTop: 14,
    height: 54,
    width: 54,
    borderWidth: 1,
    borderColor: "#E0DEE4",
    borderRadius: 31,
    backgroundColor: "rgba(9, 36, 32, 0.0494564)",
    justifyContent: "center",
    alignItems: "center",
  },
});
