import { StyleSheet, Text, View, Dimensions, TextInput } from "react-native";
import React, { useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CustomHeader from "../../../components/CustomHeader";
import Button from "../../../components/Button";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Services from "../../../services/Services";

export default function EditPassword({ navigation }) {
  const windowHeight = Dimensions.get("window").height;
  const [isChecked, setIsChecked] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const checkPassword = async (password) => {
    const token = await AsyncStorage.getItem("token");
    const id = await AsyncStorage.getItem("userID");
    Services.CheckPassword(id, password, token)
      .then((res) => {
        console.log(res.data.status);
        if (res.data.status === true) {
          setIsChecked(true);
        } else {
          setIsChecked(false);
        }
      })
      .catch(() => alert("Hata oluştu"));
  };

  const changePassword = async (password) => {
    if (isChecked === true && newPassword === confirmPassword) {
      const token = await AsyncStorage.getItem("token");
      const id = await AsyncStorage.getItem("userID");
      Services.SetPassword(id, password, token)
        .then(() => {
          alert("Parolanız başarıyla değiştirildi");
          navigation.goBack();
        })
        .catch((err) => console.log(err));
    } else {
      alert("Mevcut şifreniz hatalıdır.");
    }
  };

  return (
    <SafeAreaView>
      <CustomHeader
        image={false}
        title={"ŞİFRE YENİLEME"}
        handleFunc={() => navigation.goBack()}
      />
      <View
        style={{
          alignItems: "center",
          paddingTop: 35,
          height: windowHeight,
          backgroundColor: "white",
        }}
      >
        <Text style={styles.title}>Mevcut Şifre</Text>
        <TextInput
          value={password}
          onChangeText={(x) => setPassword(x)}
          onEndEditing={() => checkPassword(password)}
          secureTextEntry
          placeholder="Mevcut Şifreni Gir"
          style={[styles.input]}
          placeholderTextColor="#A7A7A7"
          maxLength={6}
          keyboardType="number-pad"
          returnKeyType="done"
        />
        <Text style={[styles.title, { paddingTop: 28 }]}>Yeni Şifre</Text>
        <TextInput
          value={newPassword}
          onChangeText={(x) => setNewPassword(x)}
          secureTextEntry
          placeholder="Yeni Şifreni Gir"
          style={[styles.input]}
          placeholderTextColor="#A7A7A7"
          maxLength={6}
          keyboardType="number-pad"
          returnKeyType="done"
        />
        <TextInput
          value={confirmPassword}
          onChangeText={(x) => setConfirmPassword(x)}
          secureTextEntry
          placeholder="Yeni Şifreni Onayla"
          style={[styles.input, { marginBottom: 42 }]}
          placeholderTextColor="#A7A7A7"
          maxLength={6}
          keyboardType="number-pad"
          returnKeyType="done"
        />
        <Button
          active={newPassword !== "" && newPassword === confirmPassword}
          title={"Devam Et"}
          handleFunc={() => changePassword(newPassword)}
        />
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  title: {
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 16.34,
    letterSpacing: -0.25,
    alignSelf: "flex-start",
    paddingLeft: 26,
  },
  input: {
    width: "90%",
    height: 47,
    backgroundColor: "#FAF6F5",
    borderRadius: 6,
    color: "black",
    fontSize: 14,
    fontWeight: "500",
    marginVertical: 14,
    padding: 15,
    lineHeight: 17,
    letterSpacing: -0.248,
  },
});
