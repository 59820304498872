import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Dimensions,
  Platform,
  ActivityIndicator,
  Linking,
  Alert,
} from "react-native";
import React, { useEffect, useState, useRef } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CustomHeader from "../../../components/CustomHeader";
import { FontAwesome } from "@expo/vector-icons";
import SelectDropdown from "react-native-select-dropdown";
import { Ionicons, AntDesign } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Services from "../../../services/Services";
import collect from "collect.js";

export default function LocationsPage({ navigation }) {
  const [loading, setLoading] = useState(true);

  const [showFilter, setShowFilter] = useState(false);
  const [city, setCity] = useState(null);
  const [cities, setCities] = useState([]);
  const [region, setRegion] = useState(null);
  const [regions, setRegions] = useState([]);
  const [point, setPoint] = useState(null);
  const [points, setPoints] = useState([]);
  const [favorites, setFavorites] = useState([]);

  const [centers, setCenters] = useState([]);
  const [allCenters, setAllCenters] = useState([]);

  const [cityAndRegion, setCityAndRegion] = useState({});
  const [regionAndPoint, setRegionAndPoint] = useState({});
  const cityDropdownRef = useRef({});
  const regionDropdownRef = useRef({});
  const pointDropdownRef = useRef({});

  const AddFavorite = (id, title, neighbour, address, region, city, phone) => {
    const favoriteExist = favorites.find((row) => row.id == id);
    if (favoriteExist) {
      return;
    }
    setFavorites([
      ...favorites,
      { id, title, neighbour, address, region, city, phone },
    ]);
    AsyncStorage.setItem(
      "favorites",
      JSON.stringify([
        ...favorites,
        { id, title, neighbour, address, region, city, phone },
      ])
    );
  };

  const RemoveFavorite = (id) => {
    const favoriteExist = favorites.find((row) => row.id == id);
    if (!favoriteExist) {
      return;
    }
    setFavorites(favorites.filter((row) => row.id != id));
    AsyncStorage.setItem(
      "favorites",
      JSON.stringify(favorites.filter((row) => row.id != id))
    );
  };

  const GetFavorites = async () => {
    await AsyncStorage.getItem("favorites").then((items) => {
      if (items == null) {
        setFavorites([]);
      } else {
        if (city != null) {
          cityDropdownRef.current.reset();
          regionDropdownRef.current.reset();
          pointDropdownRef.current.reset();
          setCity(null);
          setRegion(null);
          setPoint(null);
        }
        const favCollect = collect(JSON.parse(items));
        const favList = favCollect.map((row) => row.id).toArray();
        const newCenters = allCenters.filter((row) => favList.includes(row.id));
        setCenters(newCenters);
      }
    });
  };

  useEffect(() => {
    AsyncStorage.getItem("token").then((token) => {
      Services.GetCenters(token).then((res) => {
        const resCollect = collect(res.data.result);
        const cityList = resCollect
          .map((row) => row.city)
          .unique()
          .toArray();
        cityList.forEach((cityName) => {
          const cityRegions = resCollect
            .where("city", cityName)
            .map((row) => row.region)
            .unique()
            .toArray();
          cityAndRegion[cityName] = cityRegions;
          setCityAndRegion(cityAndRegion);
          cityRegions.forEach((regionName) => {
            const regionPoints = resCollect
              .where("region", regionName)
              .map((row) => row.title)
              .unique()
              .toArray();
            regionAndPoint[regionName] = regionPoints;
            setRegionAndPoint(regionAndPoint);
          });
        });
        setCities(cityList);
        setCenters(res.data.result);
        setAllCenters(res.data.result);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
    });

    AsyncStorage.getItem("favorites").then((items) => {
      if (items == null) {
        setFavorites([]);
      } else {
        setFavorites(JSON.parse(items));
      }
    });
  }, []);

  return (
    <>
      <SafeAreaView style={{ backgroundColor: "#fafafa", flex: 1 }}>
        {loading === true ? (
          <>
            <View
              style={{
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ActivityIndicator size={"large"} color="#DA3831" />
            </View>
          </>
        ) : (
          <>
            <CustomHeader image={true} />
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={{ marginBottom: -35 }}
            >
              <View
                style={{
                  alignSelf: "flex-end",
                  flexDirection: "row",
                  marginRight: 20,
                  marginBottom: showFilter ? 20 : 0,
                  marginTop: 15,
                }}
              >
                <TouchableOpacity
                  style={{ alignSelf: "center" }}
                  onPress={() => {
                    if (showFilter) {
                      if (city != null) {
                        cityDropdownRef.current.reset();
                        regionDropdownRef.current.reset();
                        pointDropdownRef.current.reset();
                        setCity(null);
                        setRegion(null);
                        setPoint(null);
                        setCenters(allCenters);
                      } else {
                        setCenters(allCenters);
                      }
                    } else {
                      if (city != null) {
                        setCity(null);
                        setRegion(null);
                        setPoint(null);
                        setCenters(allCenters);
                      } else {
                        setCenters(allCenters);
                      }
                    }
                  }}
                >
                  <Text
                    style={{
                      textDecorationLine: "underline",
                      paddingRight: 10,
                      color: "#DA3831",
                    }}
                  >
                    Filtreleri Sıfırla
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => setShowFilter(!showFilter)}
                  style={styles.filter}
                >
                  <FontAwesome name="sliders" size={18} color="white" />
                  {showFilter === false ? (
                    <Text style={styles.filterText}>Filtreleri Göster</Text>
                  ) : (
                    <Text style={styles.filterText}>Filtreleri Gizle</Text>
                  )}
                </TouchableOpacity>
              </View>

              <ScrollView>
                {showFilter && (
                  <>
                    <View style={{ alignItems: "center" }}>
                      <SelectDropdown
                        ref={cityDropdownRef}
                        data={cities}
                        defaultValue={city}
                        onSelect={(selectedItem, index) => {
                          setRegions(cityAndRegion[selectedItem]);
                          setCity(selectedItem);
                          regionDropdownRef.current.reset();
                          pointDropdownRef.current.reset();
                          setRegion(null);
                          setPoint(null);
                          setCenters(
                            collect(allCenters)
                              .where("city", selectedItem)
                              .all()
                          );
                        }}
                        buttonStyle={styles.input}
                        buttonTextStyle={{
                          textAlign: "left",
                          fontWeight: "600",
                          fontSize: 14,
                          lineHeight: 19.07,
                          color: city === null ? "#848484" : "#000",
                        }}
                        defaultButtonText="İl"
                        renderDropdownIcon={() => {
                          return (
                            <Ionicons
                              name="chevron-down"
                              size={20}
                              color="black"
                            />
                          );
                        }}
                      />
                      <SelectDropdown
                        ref={regionDropdownRef}
                        data={regions}
                        defaultValue={region}
                        onSelect={(selectedItem, index) => {
                          const selectedCenters = collect(allCenters)
                            .where("city", city)
                            .where("region", selectedItem)
                            .all();
                          setPoints(
                            selectedCenters.map((center) => center.title)
                          );
                          setRegion(selectedItem);
                          pointDropdownRef.current.reset();
                          setPoint(null);
                          setCenters(selectedCenters);
                        }}
                        buttonStyle={styles.input}
                        buttonTextStyle={{
                          textAlign: "left",
                          fontWeight: "600",
                          fontSize: 14,
                          lineHeight: 19.07,
                          color: region === null ? "#848484" : "#000",
                        }}
                        defaultButtonText={"İlçe"}
                        disabled={city === null}
                        renderDropdownIcon={() => {
                          return (
                            <Ionicons
                              name="chevron-down"
                              size={20}
                              color="black"
                            />
                          );
                        }}
                      />
                      <SelectDropdown
                        ref={pointDropdownRef}
                        data={points}
                        defaultValue={point}
                        onSelect={(selectedItem, index) => {
                          setPoint(index);
                          setCenters(
                            collect(allCenters)
                              .where("city", city)
                              .where("region", region)
                              .where("title", selectedItem)
                              .all()
                          );
                        }}
                        buttonStyle={styles.input}
                        buttonTextStyle={{
                          textAlign: "left",
                          fontWeight: "600",
                          fontSize: 14,
                          lineHeight: 19.07,
                          color: point === null ? "#848484" : "#000",
                        }}
                        defaultButtonText="Nokta Özelliği"
                        disabled={region === null}
                        renderDropdownIcon={() => {
                          return (
                            <Ionicons
                              name="chevron-down"
                              size={20}
                              color="black"
                            />
                          );
                        }}
                      />
                      <TouchableOpacity
                        onPress={() => {
                          GetFavorites();
                        }}
                      >
                        <Text
                          style={{
                            color: "#FFB100",
                            textDecorationLine: "underline",
                          }}
                        >
                          Favorileri Listele
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </>
                )}
                <View style={{ marginBottom: 120 }}>
                  {centers.map((center, index) => {
                    return (
                      <View key={index} style={[styles.card]}>
                        <View
                          style={{
                            flex: 0.8,
                            marginLeft: 12,
                            justifyContent: "center",
                          }}
                        >
                          {favorites.find((row) => row.id === center.id) ? (
                            <TouchableOpacity
                              onPress={() => RemoveFavorite(center.id)}
                            >
                              <AntDesign
                                name="star"
                                size={30}
                                color="#FFB100"
                              />
                            </TouchableOpacity>
                          ) : (
                            <TouchableOpacity
                              onPress={() =>
                                AddFavorite(
                                  center.id,
                                  center.title,
                                  center.neighbour,
                                  center.address,
                                  center.region,
                                  center.city,
                                  center.phone
                                )
                              }
                            >
                              <AntDesign
                                name="staro"
                                size={30}
                                color="#FFB100"
                              />
                            </TouchableOpacity>
                          )}
                        </View>
                        <View
                          style={{
                            flexDirection: "column",
                            flex: 5,
                            paddingLeft: 10,
                            paddingRight: 4,
                          }}
                        >
                          <Text numberOfLines={1} style={styles.cardTitle}>
                            {center.title}
                          </Text>
                          <Text style={styles.cardCity}>
                            {center.region} / {center.city}
                          </Text>
                          <Text style={styles.cardPoint}>Easy point</Text>
                        </View>
                        <View
                          style={{
                            flexDirection: "column",
                            flex: 2,
                            justifyContent: "space-around",
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              const scheme = Platform.select({
                                ios: "maps:0,0?q=",
                                android: "geo:0,0?q=",
                              });
                              const latLng = `${center.geo.latitude},${center.geo.longitude}`;
                              const label = `${center.title}`;
                              const url = Platform.select({
                                ios: `${scheme}${label}@${latLng}`,
                                android: `${scheme}${latLng}(${label})`,
                              });

                              Linking.openURL(url);
                            }}
                          >
                            <Text style={styles.cardMap}>Haritada Gör</Text>
                          </TouchableOpacity>
                          <TouchableOpacity
                            style={{ marginTop: 30 }}
                            onPress={() =>
                              Alert.alert("Açık Adres", `${center.address}`)
                            }
                          >
                            <Text style={styles.cardMap}>Açık Adres</Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    );
                  })}
                </View>
              </ScrollView>
            </ScrollView>
          </>
        )}
      </SafeAreaView>
    </>
  );
}

const styles = StyleSheet.create({
  filter: {
    flexDirection: "row",
    paddingHorizontal: 2,
    backgroundColor: "#DA3831",
    width: 141,
    height: 34,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 6,
  },
  filterText: {
    fontWeight: "600",
    fontSize: 14,
    lineHeight: 19.07,
    color: "white",
    paddingLeft: 6,
  },
  input: {
    width: "90%",
    height: 47,
    backgroundColor: "#FAF6F5",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 6,
    borderColor: "#E4E4E4",
    marginBottom: 10,
    paddingLeft: 11,
    fontSize: 14,
    fontWeight: "600",
  },
  card: {
    flex: 1,
    alignSelf: "center",
    width: "90%",
    height: 100,
    backgroundColor: "white",
    borderColor: "#F3F3F3",
    borderRadius: 6,
    marginTop: 15,
    flexDirection: "row",
    alignItems: "center",
  },
  cardTitle: {
    fontWeight: "700",
    fontSize: 16,
    lineHeight: 21.79,
    paddingBottom: 3,
  },
  cardCity: {
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 16.34,
    marginBottom: 13,
  },
  cardPoint: {
    color: "#DA3831",
    fontSize: 12,
    fontWeight: "700",
    lineHeight: 16.34,
  },
  cardMap: {
    color: "#2A72FD",
    fontSize: 12,
    fontWeight: "700",
    lineHeight: 16.34,
  },
});
