import { StyleSheet, Text, View, Platform } from "react-native";
import React, { useEffect, useState } from "react";
import WebView from "react-native-webview";
import Services from "../../services/Services";

export default function StepSeven(props) {
  const [html, setHtml] = useState(props.html);
  const [paymentId, setPaymentId] = useState(props.paymentId);
  const isSavedCard = props.isSavedCard;

  useEffect(() => {
    const interval = setInterval(() => {
      if (isSavedCard) {
        console.log("Kayıtlı kart çalıştı");
        Services.CheckSavedCardPayment(paymentId)
          .then((res) => {
            console.log(`paymentrefID: ${res.data.paymentId}`);
            if (res.data.status === "success") {
              console.log(`paymentrefID: ${res.data.paymentId}`);
              setPaymentId(res.data.paymentId);
              props.changePaymentReferenceID(res.data.paymentId);
              props.changeStep(8);
              return () => clearInterval(interval);
            }
          })
          .catch((err) => {
            console.log(err);
            alert("Ödeme işlemi başarısız oldu. Lütfen tekrar deneyiniz.");
            props.changeStep(6);
          });
      } else {
        Services.CheckPayment(paymentId)
          .then((res) => {
            console.log("Yeni kart çalıştı");
            if (res.data.paymentStatus === true) {
              console.log(`paymentId: ${paymentId}`);
              console.log(`paymentrefID: ${res.data.iyzicoPaymentId}`);
              setPaymentId(res.data.iyzicoPaymentId);
              props.changePaymentReferenceID(res.data.iyzicoPaymentId);
              props.changeStep(8);
              return () => clearInterval(interval);
            }
          })
          .catch((err) => {
            console.log(err);
            alert("Ödeme işlemi başarısız oldu. Lütfen tekrar deneyiniz.");
            props.changeStep(6);
          });
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {Platform.OS === "web" ? (
        <>
          <iframe srcdoc={html} height={"100%"} width={"100%"} />
        </>
      ) : (
        <>
          <WebView source={{ html: html }} />
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({});
