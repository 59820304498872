import {
  StyleSheet,
  Text,
  View,
  Image,
  BackHandler,
  Platform,
} from "react-native";
import React, { useEffect } from "react";
import Button from "../components/Button";
import { SafeAreaView } from "react-native-safe-area-context";
import { StackActions } from "@react-navigation/native";
import { StatusBar } from "expo-status-bar";

export default function SuccessPostPage({ route, navigation }) {
  useEffect(() => {
    const backAction = () => {
      return true;
    };
    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      backAction
    );

    return () => backHandler.remove();
  }, []);

  return (
    <SafeAreaView style={{ height: "100%" }}>
      <StatusBar style="auto" />
      <Image
        source={require("../assets/images/logo2.png")}
        style={{
          width: 120,
          marginTop: Platform.OS === "ios" ? 10 : 10,
          alignSelf: "center",
        }}
      />

      <View
        style={{
          height: "90%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          source={require("../assets/images/suffix.png")}
          resizeMode="contain"
          style={{ width: 80 }}
        />
        <Text
          style={{
            fontWeight: "700",
            fontSize: 18,
            lineHeight: 24.51,
          }}
        >
          GÖNDERİNİZİ KAYDETTİK!
        </Text>
        <Text
          style={{
            fontWeight: "400",
            fontSize: 14,
            lineHeight: 19.07,
            marginTop: 8,
            textAlign: "center",
            paddingHorizontal: 40,
            marginBottom: 50,
          }}
        >
          Gönderiniz{" "}
          <Text style={{ fontWeight: "700" }}>{route.params.pointTitle} </Text>
          noktasına ulaştığında size bilgilendirme mesajı ileteceğiz.
        </Text>

        <Button
          title={"Gönderileri Gör"}
          handleFunc={() =>
            // navigation.navigate("BottomTabNavigator", { screen: "HomePage" })
            navigation.dispatch(
              StackActions.replace("BottomTabNavigator", { screen: "HomePage" })
            )
          }
        />
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({});
