import {
  StyleSheet,
  Text,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import React from "react";

export default function Button({ active, title, handleFunc, isLoad }) {
  return (
    <>
      {active === false ? (
        <TouchableOpacity
          disabled
          style={[styles.button, { backgroundColor: "#E1E1E1" }]}
        >
          <Text style={{ fontWeight: "700", fontSize: 14, color: "white" }}>
            {title}
          </Text>
        </TouchableOpacity>
      ) : (
        <TouchableOpacity
          onPress={handleFunc}
          style={[styles.button, { backgroundColor: "#DA3831" }]}
        >
          {isLoad == true ? (
            <ActivityIndicator size={"small"} color="#fff" />
          ) : (
            <Text style={{ fontWeight: "700", fontSize: 14, color: "white" }}>
              {title}
            </Text>
          )}
        </TouchableOpacity>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  button: {
    width: "90%",
    height: 54,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30,
    alignSelf: "center",
  },
});
