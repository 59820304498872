import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
} from "react-native";
import React, { useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CustomHeader from "../../../components/CustomHeader";
import CountryPicker from "react-native-country-picker-modal";
import Button from "../../../components/Button";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Services from "../../../services/Services";
import { useDispatch } from "react-redux";
import { setPhone as setReduxPhone } from "../../../redux/registerReducer";

export default function EditPhone({ navigation }) {
  const dispatch = useDispatch();
  const [countryCode, setCountryCode] = useState("TR");
  const [callingCode, setCallingCode] = useState("90");
  const [phone, setPhone] = useState("");

  const ChangePhone = async (phone) => {
    const token = await AsyncStorage.getItem("token");
    const id = await AsyncStorage.getItem("userID");
    Services.SetPhone(id, phone, token)
      .then(() => {
        alert("Telefon numarası başarıyla değiştirildi");
        dispatch(setReduxPhone(phone));
        navigation.goBack();
      })
      .catch((err) => console.log(err));
  };

  return (
    <SafeAreaView>
      <CustomHeader
        image={false}
        title={"TELEFON NUMARAM"}
        handleFunc={() => navigation.goBack()}
      />
      <View style={{ marginTop: 140, alignItems: "center" }}>
        <Text
          style={{
            fontSize: 14,
            fontWeight: "400",
            lineHeight: 22.78,
            letterSpacing: -0.25,
          }}
        >
          Güncel telefon numaranızı girin.
        </Text>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 52,
          }}
        >
          <TouchableOpacity onPress={() => setIsOpen(true)}>
            <View style={styles.callingCode}>
              <CountryPicker
                countryCode={countryCode}
                withFlag
                withAlphaFilter={true}
                withCurrencyButton={false}
                withCallingCode
                onSelect={(country) => {
                  const { cca2, callingCode } = country;
                  setCountryCode(cca2);
                  setCallingCode(callingCode[0]);
                }}
              />
              <Text style={{ fontSize: 16, fontWeight: "600" }}>
                + {callingCode}
              </Text>
            </View>
          </TouchableOpacity>
          <TextInput
            value={phone}
            onChangeText={(x) => {
              setPhone(x);
            }}
            style={[
              styles.callingCode,
              {
                justifyContent: "flex-start",
                width: 227,
                paddingLeft: 15,
                marginLeft: 10,
                fontSize: 16,
                fontWeight: "300",
                marginBottom: 50,
              },
            ]}
            placeholder="GSM Numaran"
            maxLength={10}
            keyboardType="number-pad"
            returnKeyType={"done"}
          />
        </View>
        <Button
          handleFunc={() => ChangePhone(phone)}
          active={phone.length === 10}
          title={"Devam Et"}
        />
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  callingCode: {
    width: 120,
    height: 47,
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 6,
    flexDirection: "row",
  },
});
