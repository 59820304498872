import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  BackHandler,
  Alert,
  ActivityIndicator,
  ScrollView,
  Platform,
  RefreshControl,
} from "react-native";
import React, { useEffect } from "react";
import { Ionicons, AntDesign } from "@expo/vector-icons";
import { SafeAreaView } from "react-native-safe-area-context";
import CustomHeader from "../../components/CustomHeader";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useState } from "react";
import Services from "../../services/Services";
import { useDispatch } from "react-redux";
import { setName, setLastname } from "../../redux/registerReducer";
import { useIsFocused } from "@react-navigation/native";
import { StatusBar } from "expo-status-bar";

export default function HomePage({ navigation }) {
  const isFocused = useIsFocused();
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [onRoad, setOnRoad] = useState([]);
  const [ready, setReady] = useState([]);

  const FetchOrders = async () => {
    setRefresh(true);
    const token = await AsyncStorage.getItem("token");
    const id = await AsyncStorage.getItem("userID");
    Services.GetOrders(id, token)
      .then((res) => {
        setOrders(res.data.result);
        const roadItems = [];
        const readyItems = [];
        for (let i = 0; i < Object.keys(res.data.result).length; i++) {
          if (res.data.result[i].logs[0].status == "3") {
            roadItems.push(res.data.result[i]);
          } else if (res.data.result[i].logs[0].status == "5") {
            readyItems.push(res.data.result[i]);
          }
        }
        setReady(readyItems);
        setOnRoad(roadItems);
        setLoading(false);
        setRefresh(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setRefresh(false);
      });
  };

  useEffect(() => {
    FetchOrders();
  }, [isFocused]);

  useEffect(() => {
    const backAction = () => {
      Alert.alert(
        "Hesabınızdan çıkış yapılacaktır!",
        "Çıkış yapmak istediğinize emin misiniz?",
        [
          {
            text: "Hayır",
            onPress: () => null,
            style: "cancel",
          },
          {
            text: "Evet",
            onPress: async () => {
              await AsyncStorage.removeItem("userID");
              dispatch(setName(" "));
              dispatch(setLastname(" "));
              navigation.navigate("LoginPage");
            },
          },
        ]
      );
      return true;
    };
    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      backAction
    );

    return () => backHandler.remove();
  }, []);
  return (
    <SafeAreaView style={styles.container}>
      <StatusBar style="auto" />
      {loading === true ? (
        <>
          <View
            style={{
              height: 600,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator size={"large"} color="#DA3831" />
          </View>
        </>
      ) : (
        <>
          {/* <View style={{ height: 45 }} /> */}
          <CustomHeader image={true} />
          {orders && orders.length ? (
            <>
              <ScrollView
                showsVerticalScrollIndicator={false}
                style={{ marginBottom: -30 }}
              >
                <View style={{ marginTop: 20 }}>
                  <TouchableOpacity
                    style={[styles.button, { alignSelf: "center" }]}
                    onPress={() => navigation.jumpTo("CreatePostPage")}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: "700",
                        lineHeight: 19.07,
                        color: "white",
                      }}
                    >
                      Gönderi Oluştur
                    </Text>
                    <Ionicons
                      name="add-circle-outline"
                      size={23}
                      color={"white"}
                      style={{ marginRight: -8 }}
                    />
                  </TouchableOpacity>
                  <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{
                      marginBottom: Platform.OS === "ios" ? 0 : 120,
                    }}
                    refreshControl={
                      <RefreshControl
                        refreshing={refresh}
                        onRefresh={() => FetchOrders()}
                      />
                    }
                  >
                    <View style={{ alignItems: "center" }}>
                      <Text
                        style={{
                          fontWeight: "700",
                          fontSize: 12,
                          lineHeight: 16.34,
                          marginBottom: 5,
                          alignSelf: "flex-start",
                          paddingLeft: 25,
                        }}
                      >
                        Teslime Hazır Gönderiler
                      </Text>
                      {ready.length === 0 ? (
                        <Text
                          style={{
                            marginTop: 15,
                            alignSelf: "flex-start",
                            paddingLeft: 25,
                            color: "#717171",
                          }}
                        >
                          Teslime hazır gönderi bulunmamakta.
                        </Text>
                      ) : (
                        <>
                          {ready.map((order, index) => (
                            <TouchableOpacity
                              key={index}
                              onPress={() =>
                                navigation.navigate("OrderDetail", {
                                  id: order.post.id,
                                  point:
                                    order.post.sentToEasyPointDetails.title.substring(
                                      11
                                    ),
                                  sender: order.post.takenName,
                                  status: "Teslim Alınabilir",
                                })
                              }
                              style={{
                                width: "87%",
                                height: 75,
                                backgroundColor: "white",
                                borderRadius: 6,
                                marginTop: 15,
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Image
                                style={{
                                  width: 40,
                                  height: 40,
                                  borderRadius: 50 / 2,

                                  marginLeft: 10,
                                }}
                                source={require("../../assets/images/order.png")}
                              />
                              <View
                                style={{
                                  flexDirection: "column",
                                  flex: 3,
                                  paddingLeft: 10,
                                  justifyContent: "space-around",
                                  height: 45,
                                }}
                              >
                                <Text
                                  numberOfLines={1}
                                  style={{
                                    fontWeight: "700",
                                    lineHeight: 19.07,
                                  }}
                                >
                                  {order.post.title}
                                </Text>
                                <Text
                                  style={{
                                    lineHeight: 16.34,
                                    fontWeight: "600",
                                    color: "#19AE78",
                                    fontSize: 12,
                                  }}
                                >
                                  Teslim alınabilir
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: "column",

                                  marginRight: 10,
                                  justifyContent: "space-between",
                                  height: 45,
                                }}
                              >
                                <AntDesign
                                  name="right"
                                  size={16}
                                  color="black"
                                  style={{ alignSelf: "flex-end" }}
                                />
                                <Text
                                  numberOfLines={1}
                                  style={{
                                    fontSize: 12,
                                    fontWeight: "400",
                                    lineHeight: 16.34,
                                    alignSelf: "flex-end",
                                  }}
                                >
                                  {order.post.sentToEasyPointDetails.title.substring(
                                    11
                                  )}
                                </Text>
                              </View>
                            </TouchableOpacity>
                          ))}
                        </>
                      )}

                      <Text
                        style={{
                          fontWeight: "700",
                          fontSize: 12,
                          lineHeight: 16.34,
                          marginTop: 15,
                          marginBottom: 5,
                          alignSelf: "flex-start",
                          paddingLeft: 25,
                        }}
                      >
                        Yoldaki Gönderiler
                      </Text>
                      {onRoad.length === 0 ? (
                        <Text
                          style={{
                            marginVertical: 15,
                            alignSelf: "flex-start",
                            paddingLeft: 25,
                            color: "#717171",
                          }}
                        >
                          Yolda olan gönderi bulunmamakta.
                        </Text>
                      ) : (
                        <>
                          {onRoad.map((order, index) => (
                            <TouchableOpacity
                              key={index}
                              onPress={() =>
                                navigation.navigate("OrderDetail", {
                                  id: order.post.id,
                                  point:
                                    order.post.sentToEasyPointDetails.title.substring(
                                      11
                                    ),
                                  sender: order.post.dataJSON.sendBy,
                                  status: "Yolda",
                                })
                              }
                              style={{
                                width: "87%",
                                height: 75,
                                backgroundColor: "white",
                                borderRadius: 6,
                                marginTop: 15,
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Image
                                style={{
                                  width: 40,
                                  height: 40,
                                  borderRadius: 50 / 2,
                                  marginLeft: 10,
                                }}
                                source={require("../../assets/images/order.png")}
                              />
                              <View
                                style={{
                                  flexDirection: "column",
                                  flex: 3,
                                  paddingLeft: 10,
                                  justifyContent: "space-around",
                                  height: 45,
                                }}
                              >
                                <Text
                                  numberOfLines={1}
                                  style={{
                                    fontWeight: "700",
                                    lineHeight: 19.07,
                                  }}
                                >
                                  {order.post.title}
                                </Text>
                                <Text
                                  style={{
                                    lineHeight: 16.34,
                                    fontWeight: "600",
                                    color: "#FB8800",
                                    fontSize: 12,
                                  }}
                                >
                                  Yolda!
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: "column",
                                  marginRight: 10,
                                  justifyContent: "space-between",
                                  height: 45,
                                }}
                              >
                                <AntDesign
                                  name="right"
                                  size={16}
                                  color="black"
                                  style={{ alignSelf: "flex-end" }}
                                />
                                <Text
                                  numberOfLines={1}
                                  style={{
                                    fontSize: 12,
                                    fontWeight: "400",
                                    lineHeight: 16.34,
                                    alignSelf: "flex-end",
                                  }}
                                >
                                  {order.post.sentToEasyPointDetails.title.substring(
                                    11
                                  )}
                                </Text>
                              </View>
                            </TouchableOpacity>
                          ))}
                        </>
                      )}
                    </View>
                  </ScrollView>
                </View>
              </ScrollView>
            </>
          ) : (
            <>
              <View
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: 150,
                  marginBottom: 40,
                }}
              >
                <TouchableOpacity
                  style={styles.button}
                  onPress={() => navigation.jumpTo("CreatePostPage")}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: "700",
                      lineHeight: 19.07,
                      color: "white",
                    }}
                  >
                    Gönderi Oluştur
                  </Text>
                  <Ionicons
                    name="add-circle-outline"
                    size={23}
                    color={"white"}
                    style={{ marginRight: -8 }}
                  />
                </TouchableOpacity>
              </View>
              <Image
                style={styles.shoppingImage}
                resizeMode="contain"
                source={require("../../assets/images/empty.png")}
              />
            </>
          )}
        </>
      )}

      {/*  */}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#E5E5E5",
  },
  button: {
    backgroundColor: "#DA3831",
    width: "87%",
    height: 55,
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 25,
    justifyContent: "space-between",
    marginBottom: 20,
  },
  shoppingImage: {
    width: 250,
    alignSelf: "center",
  },
});
