import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CustomHeader from "../../../components/CustomHeader";
import {
  Ionicons,
  MaterialCommunityIcons,
  FontAwesome5,
  SimpleLineIcons,
} from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  setName,
  setLastname,
  setEmail,
  setPhone,
  setID,
} from "../../../redux/registerReducer";
import { useDispatch } from "react-redux";
import { StackActions } from "@react-navigation/native";

export default function MenuPage({ navigation }) {
  const windowHeight = Dimensions.get("window").height;
  const dispatch = useDispatch();
  // const popAction = StackActions.popToTop("LoginPage");

  const Logout = async () => {
    try {
      await AsyncStorage.removeItem("userID");
      dispatch(setName(" "));
      dispatch(setLastname(" "));
      dispatch(setEmail(" "));
      dispatch(setPhone(" "));
      dispatch(setID(" "));
    } catch (e) {
      console.log(e);
    }
    // navigation.dispatch(popAction);
    navigation.dispatch(
      StackActions.replace("StackNavigation", { screen: "LoginPage" })
    );
  };

  return (
    <SafeAreaView>
      <CustomHeader
        image={false}
        title={"İŞLEMLER"}
        handleFunc={() => navigation.goBack()}
      />
      <View style={{ height: windowHeight, backgroundColor: "white" }}>
        {/* Ödeme Yöntemleri */}
        <Text style={[styles.title, { paddingTop: 26 }]}>Ödeme Yöntemleri</Text>
        <TouchableOpacity
          onPress={() => navigation.navigate("PastPosts")}
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View style={{ alignItems: "center", flexDirection: "row" }}>
            <MaterialCommunityIcons
              name="cube-send"
              size={24}
              color="black"
              style={{ paddingLeft: 28.5, marginRight: 6 }}
            />
            <Text style={styles.buttonTitle}>Geçmiş Gönderiler</Text>
          </View>
          <Ionicons
            name="chevron-forward"
            size={15}
            color="black"
            style={{ paddingRight: 22 }}
          />
        </TouchableOpacity>
        <View style={styles.divider} />
        {/* Profile Bilgileri */}
        <Text style={styles.title}>Profil Bilgileri</Text>
        <TouchableOpacity
          onPress={() => navigation.navigate("EditProfile")}
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View style={{ alignItems: "center", flexDirection: "row" }}>
            <FontAwesome5
              name="user-circle"
              size={24}
              color="black"
              style={{ paddingLeft: 28.5, marginRight: 6 }}
            />
            <Text style={styles.buttonTitle}>Profil Bilgilerini Güncelle</Text>
          </View>
          <Ionicons
            name="chevron-forward"
            size={15}
            color="black"
            style={{ paddingRight: 22 }}
          />
        </TouchableOpacity>
        <View style={[styles.divider, { marginBottom: 14 }]} />
        <TouchableOpacity
          onPress={() => navigation.navigate("EditPassword")}
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View style={{ alignItems: "center", flexDirection: "row" }}>
            <Ionicons
              name="key-outline"
              size={24}
              color="black"
              style={{ paddingLeft: 28.5, marginRight: 6 }}
            />
            <Text style={styles.buttonTitle}>Şifre Yenileme</Text>
          </View>
          <Ionicons
            name="chevron-forward"
            size={15}
            color="black"
            style={{ paddingRight: 22 }}
          />
        </TouchableOpacity>
        <View style={styles.divider} />
        {/* Ödeme Yöntemleri */}
        <Text style={styles.title}>Ödeme Yöntemleri</Text>
        <TouchableOpacity
          onPress={() => navigation.navigate("Cards")}
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View style={{ alignItems: "center", flexDirection: "row" }}>
            <Ionicons
              name="card-outline"
              size={24}
              color="black"
              style={{ paddingLeft: 28.5, marginRight: 6 }}
            />
            <Text style={styles.buttonTitle}>Kart Bilgisi Ekleme</Text>
          </View>
          <Ionicons
            name="chevron-forward"
            size={15}
            color="black"
            style={{ paddingRight: 22 }}
          />
        </TouchableOpacity>
        <View style={styles.divider} />
        {/* Yasal Bilgiler */}
        <Text style={styles.title}>Yasal Bilgiler</Text>
        <TouchableOpacity
          onPress={() => navigation.navigate("Contracts")}
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View style={{ alignItems: "center", flexDirection: "row" }}>
            <Ionicons
              name="md-document-text-outline"
              size={24}
              color="black"
              style={{ paddingLeft: 28.5, marginRight: 6 }}
            />
            <Text style={styles.buttonTitle}>Sözleşmeler</Text>
          </View>
          <Ionicons
            name="chevron-forward"
            size={15}
            color="black"
            style={{ paddingRight: 22 }}
          />
        </TouchableOpacity>
        <View style={[styles.divider, { marginBottom: 14 }]} />
        <TouchableOpacity
          onPress={() => navigation.navigate("Contact")}
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View style={{ alignItems: "center", flexDirection: "row" }}>
            <SimpleLineIcons
              name="earphones-alt"
              size={24}
              color="black"
              style={{ paddingLeft: 28.5, marginRight: 6 }}
            />
            <Text style={styles.buttonTitle}>İletişim Merkezi</Text>
          </View>
          <Ionicons
            name="chevron-forward"
            size={15}
            color="black"
            style={{ paddingRight: 22 }}
          />
        </TouchableOpacity>
        <View style={styles.divider} />
        <Text style={[styles.title]}>Hesap</Text>
        <TouchableOpacity
          onPress={() => Logout()}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <View style={{ alignItems: "center", flexDirection: "row" }}>
            <Ionicons
              name="ios-log-out-outline"
              size={24}
              color="red"
              style={{ paddingLeft: 28.5, marginRight: 6 }}
            />
            <Text style={[styles.buttonTitle, { color: "red" }]}>
              Çıkış Yap
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  title: {
    paddingLeft: 26,
    fontSize: 12,
    fontWeight: "600",
    color: "#7D8290",
    marginBottom: 20.5,
    lineHeight: 16.34,
    paddingTop: 15.5,
  },
  buttonTitle: {
    paddingLeft: 9,
    fontWeight: "600",
    fontSize: 14,
    lineHeight: 19.07,
  },
  divider: {
    marginLeft: 37.5,
    backgroundColor: "#F2F2F2",
    height: 1,
    width: "83.5%",
    marginTop: 12,
    alignSelf: "flex-end",
  },
});
