import {
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  KeyboardAvoidingView,
  ScrollView,
} from "react-native";
import React, { useState } from "react";
import { StatusBar } from "expo-status-bar";
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from "react-native-confirmation-code-field";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import AuthService from "../../services/AuthService";
import {
  // setPhone as setReduxPhone,
  // setName as setReduxName,
  // setLastname as setReduxLastname,
  // setEmail as setReduxEmail,
  setSMS as setReduxSMS,
} from "../../redux/registerReducer";

export default function CreatePasswordPage({ navigation }) {
  const dispatch = useDispatch();
  // Redux'tan önceki sayfalardaki kullanıcı bilgilerini çekiyoruz
  const name = useSelector((state) => state.name);
  const lastname = useSelector((state) => state.lastname);
  const email = useSelector((state) => state.email);
  const phone = useSelector((state) => state.phone);

  const windowHeight = Dimensions.get("window").height;
  const [value, setValue] = useState("");
  const CELL_COUNT = 6;
  const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });
  const renderCell = ({ index, symbol, isFocused }) => {
    let textChild = null;

    if (symbol) {
      textChild = "•";
    } else if (isFocused) {
      textChild = <Cursor />;
    }

    return (
      <Text
        key={index}
        style={[styles.cell, isFocused && styles.focusCell]}
        onLayout={getCellOnLayoutHandler(index)}
      >
        {textChild}
      </Text>
    );
  };

  const Register = async (name, lastname, email, phone, password) => {
    const token = await AsyncStorage.getItem("token");
    AuthService.GetRegister(name, lastname, email, phone, password, token)
      .then(async (res) => {
        await AsyncStorage.setItem("userID", res.data.result.customerID);
        await AsyncStorage.setItem("name", name);
        await AsyncStorage.setItem("lastname", lastname);
        navigation.navigate("HomePage");
        setValue("");
        // dispatch(setReduxName(null));
        // dispatch(setReduxLastname(null));
        // dispatch(setReduxEmail(null));
        // dispatch(setReduxPhone(null));
        dispatch(setReduxSMS(null));
      })
      .catch((err) => {
        console.log(err);
        alert("Kayıt Oluşturulamadı.");
      });
  };

  return (
    <KeyboardAvoidingView style={styles.container} behavior={"height"}>
      <StatusBar style="auto" />
      <ScrollView>
        <View style={{ flexDirection: "column", height: windowHeight }}>
          <Image
            source={require("../../assets/images/logo.png")}
            style={styles.logo}
            resizeMode="contain"
          />
          <View style={{ alignItems: "center", justifyContent: "center" }}>
            <View>
              <Text style={styles.title}>Şifre Belirle</Text>
              <Text style={styles.context}>
                Hesabınıza hızlı ve güvenli erişim için şifre belirleyin.
              </Text>
            </View>

            <CodeField
              ref={ref}
              {...props}
              value={value}
              onChangeText={setValue}
              cellCount={CELL_COUNT}
              rootStyle={{ marginTop: 20, marginBottom: 40 }}
              keyboardType="number-pad"
              textContentType="oneTimeCode"
              renderCell={renderCell}
            />
            <Button
              title={"Devam Et"}
              active={value.length === 6}
              handleFunc={() => Register(name, lastname, email, phone, value)}
            />
          </View>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  logo: {
    width: 120,
    alignSelf: "center",
    marginTop: 30,
  },
  title: {
    fontSize: 22,
    fontWeight: "700",
    textAlign: "center",
    marginTop: 160,
  },
  context: {
    fontSize: 14,
    fontWeight: "400",
    textAlign: "center",
    color: "#5E596D",
    marginTop: 8,
    marginBottom: 20,
    paddingHorizontal: 60,
    lineHeight: 19.07,
  },
  cell: {
    width: 40,
    height: 47,
    fontSize: 20,
    fontWeight: "600",
    borderWidth: 1,
    borderColor: "#E4E4E4",
    textAlign: "center",
    marginHorizontal: 5,
    borderRadius: 6,
    backgroundColor: "#FAF6F5",
    lineHeight: 42,
  },
  focusCell: {
    borderColor: "#000",
    paddingHorizontal: 5,
  },
});
