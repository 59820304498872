import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Modal,
  TextInput,
  ActivityIndicator,
} from "react-native";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CustomHeader from "../../../components/CustomHeader";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import { useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Services from "../../../services/Services";

export default function OrderDetail({ route, navigation }) {
  const id = route.params.id;
  const point = route.params.point;
  const sender = route.params.sender;
  const status = route.params.status;
  const [reason, setReason] = useState("");
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const Cancel = async (id, reason) => {
    console.log({
      id: id,
      reason: reason,
    });
    setLoading(true);
    await AsyncStorage.getItem("token").then((token) => {
      Services.CancelOrder(token, id, reason)
        .then((res) => {
          if (res.data.status === false) {
            setLoading(false);
            alert("Siparişiniz iptal edilemedi.");
            setModal(false);
          } else if (res.data.status === true) {
            setLoading(false);
            alert("Siparişiniz başarıyla iptal edilmiştir.");
            setModal(false);
            navigation.goBack();
          }
          setModal(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setModal(false);
        });
    });
  };

  return (
    <SafeAreaView style={{ height: "100%", backgroundColor: "white" }}>
      <CustomHeader
        title={"GÖNDERİ DETAY"}
        image={false}
        handleFunc={() => navigation.goBack()}
      />
      <Image
        source={require("../../../assets/images/order.png")}
        style={styles.image}
      />
      <Text
        style={{
          paddingLeft: 21,
          fontWeight: "600",
          lineHeight: 19.07,
        }}
      >
        Teslimat Bilgisi
      </Text>
      <View style={styles.divider} />
      <View style={styles.row}>
        <Text style={styles.rowTitle}>Teslimat Durumu:</Text>
        <Text
          style={{
            paddingRight: 18,
            fontWeight: "600",
            lineHeight: 19.07,
            letterSpacing: -0.25,
            color: status === "Yolda" ? "#FB8800" : "#2BC6B1",
          }}
        >
          {status}
        </Text>
      </View>
      <View style={styles.divider} />
      <View style={styles.row}>
        <Text style={styles.rowTitle}>Teslimat Şifresi:</Text>
        {status === "Yolda" ? (
          <Text
            style={{
              fontWeight: "600",
              lineHeight: 19.07,
              letterSpacing: -0.25,
              paddingRight: 18,
            }}
          >
            ******
          </Text>
        ) : (
          <View
            style={{
              flexDirection: "row",
              paddingRight: 18,
              alignItems: "center",
            }}
          >
            <Ionicons name="lock-open-outline" size={15} color="black" />
            <Text
              style={{
                fontWeight: "600",
                lineHeight: 19.07,
                letterSpacing: -0.25,
                marginLeft: 3,
              }}
            >
              234 - 244
            </Text>
          </View>
        )}
      </View>
      <View style={styles.divider} />
      <View style={styles.row}>
        <Text style={styles.rowTitle}>Son Teslim Tarihi:</Text>
        <Text style={styles.rowContent}>
          {status === "Yolda" ? "******" : "12.06.2023"}
        </Text>
      </View>
      <View style={styles.divider} />
      <View style={styles.row}>
        <Text style={styles.rowTitle}>Gönderici:</Text>
        <Text style={styles.rowContent}>{sender}</Text>
      </View>
      <View style={styles.divider} />
      <View style={styles.row}>
        <Text style={styles.rowTitle}>Easy Point Noktası:</Text>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Ionicons name="location-outline" size={15} color="black" />
          <Text style={styles.rowContent}>{point}</Text>
        </View>
      </View>
      <View style={styles.divider} />
      <View style={styles.row}>
        <Text style={styles.rowTitle}>Ödenen Tutar:</Text>
        <Text style={styles.rowContent}>15,00TL</Text>
      </View>
      <View style={styles.divider} />
      <TouchableOpacity onPress={() => setModal(true)}>
        <Text
          style={{
            paddingLeft: 21,
            color: "#DA3831",
            textDecorationLine: "underline",
          }}
        >
          İptal Et
        </Text>
      </TouchableOpacity>
      <Modal onRequestClose={() => setModal(false)} transparent visible={modal}>
        <View style={styles.modal}>
          <TouchableOpacity onPress={() => setModal(false)}>
            <MaterialIcons
              style={{ alignSelf: "flex-end", paddingRight: 4, paddingTop: 4 }}
              name="cancel"
              size={26}
              color="black"
            />
          </TouchableOpacity>
          {loading === true ? (
            <>
              <View
                style={{
                  height: 150,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ActivityIndicator size={"large"} color="#DA3831" />
              </View>
            </>
          ) : (
            <>
              <Text style={{ paddingLeft: 15, fontWeight: "600" }}>
                İptal Sebebi (opsiyonel)
              </Text>
              <TextInput
                placeholder="İptal Nedeni"
                value={reason}
                placeholderTextColor={"gray"}
                style={styles.input}
                onChangeText={(e) => {
                  setReason(e);
                }}
              />
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 30,
                  justifyContent: "space-around",
                }}
              >
                <TouchableOpacity onPress={() => Cancel(id, reason)}>
                  <View
                    style={{
                      backgroundColor: "green",
                      width: 130,
                      height: 30,
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 6,
                    }}
                  >
                    <Text style={{ color: "white", fontWeight: "600" }}>
                      Onayla
                    </Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => setModal(false)}>
                  <View
                    style={{
                      backgroundColor: "#DA3831",
                      width: 130,
                      height: 30,
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 6,
                    }}
                  >
                    <Text style={{ color: "white", fontWeight: "600" }}>
                      Çıkış
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </>
          )}
        </View>
      </Modal>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  image: {
    width: 80,
    height: 80,
    borderRadius: 80 / 2,
    overflow: "hidden",
    marginVertical: 20,
    alignSelf: "center",
  },
  divider: {
    width: "100%",
    borderWidth: 0.7,
    borderColor: "#F2F2F2",
    marginVertical: 17,
  },
  row: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rowTitle: {
    paddingLeft: 21,
    fontSize: 12,
    fontWeight: "600",
    color: "#7D8290",
    lineHeight: 16.34,
    letterSpacing: -0.25,
  },
  rowContent: {
    paddingRight: 18,
    fontWeight: "600",
    letterSpacing: -0.25,
    lineHeight: 19.07,
  },
  modal: {
    backgroundColor: "white",
    height: 200,
    width: "80%",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#7D8290",
    marginTop: 150,
    marginLeft: 40,
  },
  input: {
    height: 50,
    width: "90%",
    borderColor: "#E4E4E4",
    borderWidth: 1,
    alignSelf: "center",
    borderRadius: 6,
    marginTop: 10,
    paddingLeft: 10,
    backgroundColor: "#FAF6F5",
  },
});
