import {
  KeyboardAvoidingView,
  ScrollView,
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  Alert,
  RefreshControl,
} from "react-native";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CustomHeader from "../../../components/CustomHeader";
import { useEffect } from "react";
import Services from "../../../services/Services";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useState } from "react";
import { Ionicons, FontAwesome } from "@expo/vector-icons";
import { useIsFocused } from "@react-navigation/native";

export default function Cards({ navigation }) {
  const isFocused = useIsFocused();
  const windowHeight = Dimensions.get("window").height;
  const [status, setStatus] = useState();
  const [refresh, setRefresh] = useState(false);
  const [cards, setCards] = useState([]);
  const [cardUserKey, setCardUserKey] = useState("");
  const [userId, setUserId] = useState("");

  const FetchCards = (id) => {
    setRefresh(true);
    Services.GetCards(id)
      .then((res) => {
        setCards(res.data.cardDetails);
        setCardUserKey(res.data.cardUserKey);
        setRefresh(false);
      })
      .catch((err) => {
        console.log(err);
        setRefresh(false);
      });
  };

  const deleteCard = (cardToken, cardUserKey) => {
    Services.DeleteCard(userId, cardToken, cardUserKey).then((res) => {
      console.log(res.data.status);
      if (res.data.status === "success") {
        Alert.alert("Başarılı", "Kart silindi");
        FetchCards(userId);
      } else {
        Alert.alert("Hata", "Kart silinemedi");
      }
    });
  };

  useEffect(() => {
    if (userId === "") {
      AsyncStorage.getItem("userID").then((id) => {
        setUserId(id);
        FetchCards(id);
      });
    } else {
      FetchCards(userId);
    }
  }, [isFocused]);
  return (
    <SafeAreaView style={styles.container}>
      <KeyboardAvoidingView behavior="height">
        <CustomHeader
          image={false}
          title={"TÜM KARTLAR"}
          handleFunc={() => navigation.goBack()}
        />
        <ScrollView
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={refresh}
              onRefresh={() => FetchCards(userId)}
            />
          }
        >
          <View>
            {cards.length === 0 ? (
              <View>
                <TouchableOpacity
                  style={[styles.button, { alignSelf: "center" }]}
                  onPress={() => navigation.navigate("AddCard")}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: "700",
                      lineHeight: 19.07,
                      color: "white",
                    }}
                  >
                    Yeni Kart Ekle
                  </Text>
                  <Ionicons
                    name="add-circle-outline"
                    size={23}
                    color={"white"}
                    style={{ marginRight: -8 }}
                  />
                </TouchableOpacity>
              </View>
            ) : (
              <View>
                {cards.map((card, index) => (
                  <TouchableOpacity
                    onPress={() => {
                      Alert.alert("Uyarı", "Bu kartı silmek ister misiniz?", [
                        {
                          text: "Evet",
                          onPress: () =>
                            deleteCard(card.cardToken, cardUserKey),
                          style: "cancel",
                        },
                        {
                          text: "Hayır",
                        },
                      ]);
                    }}
                    style={styles.cardInfo}
                    key={index}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingTop: 13,
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "600",
                          lineHeight: 19.07,
                          letterSpacing: -0.25,
                          paddingLeft: 16,
                        }}
                      >
                        {card.cardAlias}
                      </Text>
                      {card.cardAssociation === "VISA" ? (
                        <FontAwesome
                          style={{ paddingRight: 15 }}
                          name="cc-visa"
                          size={15}
                          color="black"
                        />
                      ) : (
                        <FontAwesome
                          style={{ paddingRight: 15 }}
                          name="cc-mastercard"
                          size={15}
                          color="black"
                        />
                      )}
                    </View>
                    <Text
                      style={{
                        paddingTop: 16,
                        paddingLeft: 16,
                        fontWeight: "600",
                        lineHeight: 19.07,
                        letterSpacing: -0.25,
                      }}
                    >
                      {card.binNumber.substring(0, 4)}{" "}
                      {card.binNumber.substring(4, 8)} **** ****
                    </Text>
                  </TouchableOpacity>
                ))}
                <TouchableOpacity
                  style={[styles.button, { alignSelf: "center" }]}
                  onPress={() => navigation.navigate("AddCard")}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: "700",
                      lineHeight: 19.07,
                      color: "white",
                    }}
                  >
                    Yeni Kart Ekle
                  </Text>
                  <Ionicons
                    name="add-circle-outline"
                    size={23}
                    color={"white"}
                    style={{ marginRight: -8 }}
                  />
                </TouchableOpacity>
              </View>
            )}
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    height: "100%",
  },
  button: {
    backgroundColor: "#DA3831",
    width: "87%",
    height: 55,
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 25,
    justifyContent: "space-between",
    marginTop: 20,
    marginBottom: 70,
  },
  cardInfo: {
    height: 93,
    width: "87%",
    backgroundColor: "#FAF6F5",
    borderRadius: 6,
    alignSelf: "center",
    marginVertical: 5,
  },
});
