import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
} from "react-native";
import React, { useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CustomHeader from "../../../components/CustomHeader";
import Button from "../../../components/Button";
import { useDispatch } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Services from "../../../services/Services";
import { setEmail as setReduxEmail } from "../../../redux/registerReducer";

export default function EditEmail({ navigation }) {
  const dispath = useDispatch();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(true);

  const validateMail = (text) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      setEmail(text.toLowerCase());
      setError(true);
    } else {
      setEmail(text.toLowerCase());
      setError(false);
    }
  };

  const ChangeEmail = async (email) => {
    const token = await AsyncStorage.getItem("token");
    const id = await AsyncStorage.getItem("userID");
    Services.SetEmail(id, email, token)
      .then(() => {
        dispath(setReduxEmail(email));
        alert("Email adresiniz başarıyla güncellenmiştir.");
        navigation.goBack();
      })
      .catch(() => alert("Bir hata oluştu lütfen tekrar deneyiniz"));
  };

  return (
    <SafeAreaView>
      <CustomHeader
        image={false}
        title={"E-POSTA ADRESİM"}
        handleFunc={() => navigation.goBack()}
      />
      <View style={{ marginTop: 140, alignItems: "center" }}>
        <Text
          style={{
            fontSize: 14,
            fontWeight: "400",
            lineHeight: 22.78,
            letterSpacing: -0.25,
          }}
        >
          Lütfen güncel e-posta adresinizi girin.
        </Text>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 52,
          }}
        >
          <TextInput
            value={email}
            onChangeText={(x) => {
              validateMail(x);
            }}
            style={[styles.input, { fontWeight: email === "" ? "300" : "500" }]}
            placeholder="E-posta Adresin"
            keyboardType="email-address"
            returnKeyType={"done"}
            placeholderTextColor="#B4B0C0"
          />
        </View>
        <Button
          active={error === false}
          title={"Devam Et"}
          handleFunc={() => ChangeEmail(email)}
        />
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  input: {
    width: "90%",
    height: 47,
    backgroundColor: "white",
    alignItems: "center",
    borderRadius: 6,
    marginBottom: 50,
    paddingLeft: 11,
    fontSize: 14,
    color: "black",
  },
});
