import {
  KeyboardAvoidingView,
  ScrollView,
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  TextInput,
} from "react-native";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CustomHeader from "../../../../components/CustomHeader";
import Services from "../../../../services/Services";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useState } from "react";
import { Ionicons, FontAwesome } from "@expo/vector-icons";
import { MaskedTextInput } from "react-native-mask-text";

export default function AddCard({ navigation }) {
  const windowHeight = Dimensions.get("window").height;
  const [cardAlias, setCardAlias] = useState("");
  const [cardHoldername, setCardHoldername] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expireMonth, setExpireMonth] = useState("");
  const [expireYear, setExpireYear] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");

  const Save = async (
    cardAlias,
    cardHoldername,
    cardNumber,
    expireMonth,
    expireYear,
    email
  ) => {
    const userId = await AsyncStorage.getItem("userID");
    Services.SaveCard(
      cardAlias,
      cardHoldername,
      cardNumber,
      expireMonth,
      expireYear,
      email,
      userId
    ).then((res) => {
      if (res.data.status === "success") {
        console.log(res.data);
        navigation.navigate("Cards");
      } else {
        alert(`${res.data.errorMessage}`);
      }
    });
  };

  return (
    <SafeAreaView style={styles.container}>
      <KeyboardAvoidingView behavior="height" style={{ height: "100%" }}>
        <CustomHeader
          image={false}
          title={"KART EKLEME"}
          handleFunc={() => navigation.goBack()}
        />
        <ScrollView showsVerticalScrollIndicator={false}>
          <View style={{ marginTop: 20 }}>
            <TextInput
              value={cardAlias}
              onChangeText={(e) => setCardAlias(e)}
              placeholder="Kartınızın İsmi"
              style={styles.input}
              maxLength={25}
            />
            <TextInput
              value={cardHoldername}
              onChangeText={(e) =>
                setCardHoldername(e.replace(/[^a-zA-ZğçşüöıĞÇŞÜÖİ ]/g, ""))
              }
              placeholder="Kart Üzerindeki İsim/Soyad"
              style={styles.input}
              maxLength={50}
              keyboardType="default"
            />
            <View style={[styles.input, { flexDirection: "row" }]}>
              <FontAwesome name="credit-card" size={18} color="black" />
              <MaskedTextInput
                mask="9999 9999 9999 9999"
                value={cardNumber}
                onChangeText={(formatted, extracted) => {
                  setCardNumber(extracted);
                }}
                style={{
                  paddingLeft: 10,
                  color: "black",
                  height: 47,
                }}
                maxLength={19}
                placeholder="Kart Numarası"
                keyboardType="number-pad"
                returnKeyType={"done"}
              />
            </View>
            <View style={{ flexDirection: "row" }}>
              <TextInput
                value={expireMonth}
                onChangeText={(text) => {
                  setExpireMonth(text);
                }}
                style={[
                  styles.input,
                  {
                    width: "20%",
                    alignSelf: "flex-start",
                    marginLeft: "5%",
                  },
                ]}
                placeholder="Ay"
                maxLength={2}
                keyboardType="number-pad"
                returnKeyType={"done"}
              />
              <TextInput
                value={expireYear}
                onChangeText={(text) => {
                  setExpireYear(text);
                }}
                style={[
                  styles.input,
                  {
                    width: "20%",
                    alignSelf: "flex-start",
                    marginLeft: 15,
                  },
                ]}
                placeholder="Yıl"
                maxLength={4}
                keyboardType="number-pad"
                returnKeyType={"done"}
              />
            </View>
            <TextInput
              value={email}
              onChangeText={(e) => setEmail(e)}
              keyboardType="email-address"
              placeholder="E-posta"
              style={styles.input}
            />
          </View>
          <TouchableOpacity
            disabled={
              cardAlias === "" ||
              cardHoldername === "" ||
              cardNumber.length !== 16 ||
              expireMonth.length !== 2 ||
              expireYear.length !== 4 ||
              email === ""
            }
            style={
              cardAlias === "" ||
              cardHoldername === "" ||
              cardNumber.length !== 16 ||
              expireMonth.length !== 2 ||
              expireYear.length !== 4 ||
              email === ""
                ? styles.disabled
                : styles.button
            }
            onPress={() =>
              Save(
                cardAlias,
                cardHoldername,
                cardNumber,
                expireMonth,
                expireYear,
                email
              )
            }
          >
            <Text style={{ fontWeight: "700", fontSize: 14, color: "white" }}>
              KAYDET
            </Text>
          </TouchableOpacity>
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    height: "100%",
  },
  input: {
    width: "90%",
    height: 47,
    backgroundColor: "#FAF6F5",
    alignItems: "center",
    borderRadius: 6,
    paddingLeft: 11,
    marginBottom: 17,
    alignSelf: "center",
  },
  button: {
    marginTop: 20,
    width: "90%",
    height: 54,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30,
    alignSelf: "center",
    backgroundColor: "#DA3831",
  },
  disabled: {
    marginTop: 20,
    width: "90%",
    height: 54,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30,
    alignSelf: "center",
    backgroundColor: "#E1E1E1",
  },
});
