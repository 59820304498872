import AsyncStorage from "@react-native-async-storage/async-storage";
import Navigation from "./src/route/Navigation";
import TokenService from "./src/services/TokenService";
import { useEffect } from "react";
import { Platform, View } from "react-native";

export default function App() {
  global.XMLHttpRequest = global.originalXMLHttpRequest
    ? global.originalXMLHttpRequest
    : global.XMLHttpRequest;
  useEffect(() => {
    TokenService.GetToken().then(async (res) => {
      await AsyncStorage.setItem("token", res.data.result.token);
    });
  }, []);

  return (
    <>
      {Platform.OS === "web" ? (
        <View
          style={{
            width: 400,
            alignSelf: "center",
            height: "100%",
            borderWidth: 1,
            borderColor: "black",
          }}
        >
          <Navigation />
        </View>
      ) : (
        <>
          <Navigation />
        </>
      )}
    </>
  );
}
