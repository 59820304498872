import {
  StyleSheet,
  Text,
  View,
  TextInput,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
} from "react-native";
import React, { useState, useEffect } from "react";
import SelectDropdown from "react-native-select-dropdown";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import Button from "../Button";
import { data } from "./citiesAndRegions";
import ShortUniqueId from "short-unique-id";
import { useSelector } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Services from "../../services/Services";
import RadioForm from "react-native-simple-radio-button";

export default function StepFive(props) {
  const userID = useSelector((state) => state.id);
  const isCargo = props.isCargo;
  const [buttonLoading, setButtonLoading] = useState(false);

  const [invoice, setInvoice] = useState(props.invoice); // Fatura kesilecek kişi veya kurum
  const [corporate, setCorporate] = useState(props.corporate);
  const [identityNo, setIdentityNo] = useState(props.identityNo);
  const [billingCity, setBillingCity] = useState(props.billingCity);
  const [billingRegion, setBillingRegion] = useState(props.billingRegion);
  const [billingNeighbour, setBillingNeighbour] = useState(
    props.billingNeighbour
  );
  const [billingAddress, setBillingAddress] = useState(props.billingAddress);
  const [billingPhone, setBillingPhone] = useState(props.billingPhone);
  const [taxNo, setTaxNo] = useState(props.taxNo);
  const [taxAdministration, setTaxAdministration] = useState(
    props.taxAdministration
  );
  const [email, setEmail] = useState(props.email);
  const [billingName, setBillingName] = useState(props.billingName);
  const [billingLastName, setBillingLastName] = useState(props.billingLastName);
  const [orderID, setOrderID] = useState(props.orderID);

  const [datas, setDatas] = useState([]);
  const [cities, setCities] = useState([]);
  const [regions, setRegions] = useState([]);

  const radio_props = [
    { label: "Kişisel", value: 0 },
    { label: "Kurumsal", value: 1 },
  ];

  const createOrderID = () => {
    const sessionId = new ShortUniqueId({
      dictionary: "number",
      length: 9,
    });
    setOrderID(`${sessionId()}`);
    props.changeOrderID(`${sessionId()}`);
    console.log("orderID: ", `${sessionId()}`);
    props.changeStep(6);
  };

  const editRegions = (cityName) => {
    const regionItems = [];
    for (let i = 0; i < datas.length; i++) {
      if (datas[i].il === cityName) {
        for (let j = 0; j < datas[i].ilceleri.length; j++) {
          regionItems.push(datas[i].ilceleri[j]);
        }
      }
    }
    setRegions(regionItems);
  };

  useEffect(() => {
    setDatas(data);
    const cityItems = [];
    for (let i = 0; i < data.length; i++) {
      cityItems.push(data[i].il);
    }
    setCities(cityItems);
  }, []);

  return (
    <View style={{ flex: 1 }}>
      <View>
        {/* <View style={{ marginLeft: 20 }}>
          <RadioForm
            radio_props={radio_props}
            initial={0}
            onPress={(value) => setCorporate(value)}
            buttonColor={"green"}
            selectedButtonColor={"red"}
            buttonSize={10}
            buttonOuterSize={20}
            labelStyle={{
              fontSize: 12,
              lineHeight: 16.34,
              fontWeight: "600",
              paddingRight: 10,
            }}
            formHorizontal={true}
            labelHorizontal={true}
            style={{ marginTop: 20 }}
          />
        </View> */}

        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              props.changeCorporate(false);
              setCorporate(false);
            }}
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View
                style={{
                  width: 20,
                  height: 20,
                  borderWidth: 1,
                  borderRadius: 10,
                  borderColor: corporate === false ? "#DA3831" : "#000",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: 20,
                }}
              >
                <View
                  style={{
                    height: 13,
                    width: 13,
                    borderRadius: 7,
                    backgroundColor: corporate == false ? "#DA3831" : "white",
                  }}
                />
              </View>
              <Text
                style={{
                  fontWeight: "600",
                  fontSize: 12,
                  lineHeight: 16.34,
                  paddingLeft: 5,
                  color: corporate === false ? "#DA3831" : undefined,
                }}
              >
                Bireysel
              </Text>
            </View>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              props.changeCorporate(true);
              setCorporate(true);
            }}
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View
                style={{
                  width: 20,
                  height: 20,
                  borderWidth: 1,
                  borderRadius: 10,
                  borderColor: corporate === true ? "#DA3831" : "#000",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: 20,
                }}
              >
                <View
                  style={{
                    height: 13,
                    width: 13,
                    borderRadius: 7,
                    backgroundColor: corporate == true ? "#DA3831" : "white",
                  }}
                />
              </View>
              <Text
                style={{
                  fontWeight: "600",
                  fontSize: 12,
                  lineHeight: 16.34,
                  paddingLeft: 5,
                  color: corporate === true ? "#DA3831" : undefined,
                }}
              >
                Kurumsal
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        <Text
          style={{
            fontWeight: "700",
            fontSize: 14,
            lineHeight: 19.07,
            marginBottom: 20,
            paddingLeft: 20,
            marginTop: 18,
          }}
        >
          Fatura Bilgileri
        </Text>

        <View style={{ alignItems: "center" }}>
          {corporate == false ? (
            <>
              <TextInput
                value={billingName}
                onChangeText={(e) => {
                  props.changeBillingName(
                    e.replace(/[^a-zA-ZğçşüöıĞÇŞÜÖİ ]/g, "")
                  );
                  setBillingName(e.replace(/[^a-zA-ZğçşüöıĞÇŞÜÖİ ]/g, ""));
                }}
                style={styles.input}
                placeholder="Ad"
                maxLength={50}
              />
              <TextInput
                value={billingLastName}
                onChangeText={(e) => {
                  props.changeBillingLastName(
                    e.replace(/[^a-zA-ZğçşüöıĞÇŞÜÖİ]/g, "")
                  );
                  setBillingLastName(e.replace(/[^a-zA-ZğçşüöıĞÇŞÜÖİ]/g, ""));
                }}
                style={styles.input}
                placeholder="Soyad"
                maxLength={50}
              />
              <TextInput
                value={identityNo}
                onChangeText={(e) => {
                  props.changeIdentityNo(e.replace(/[^0-9]/g, ""));
                  setIdentityNo(e.replace(/[^0-9]/g, ""));
                }}
                style={styles.input}
                keyboardType="number-pad"
                maxLength={11}
                returnKeyType="done"
                placeholder="T.C. Kimlik Numaranız"
              />
              <TextInput
                value={billingPhone}
                onChangeText={(e) => {
                  props.changeBillingPhone(e.replace(/[^0-9]/g, ""));
                  setBillingPhone(e.replace(/[^0-9]/g, ""));
                }}
                keyboardType="number-pad"
                returnKeyType="done"
                maxLength={10}
                style={styles.input}
                placeholder="Telefon Numaranız"
              />
              <SelectDropdown
                data={cities}
                onSelect={(selectedItem, index) => {
                  setBillingCity(selectedItem);
                  props.changeBillingCity(selectedItem);
                  setBillingRegion("");
                  editRegions(selectedItem);
                }}
                buttonStyle={styles.input}
                buttonTextStyle={{
                  textAlign: "left",
                  fontWeight: "600",
                  fontSize: 14,
                  lineHeight: 19.07,
                  color: billingCity === "" ? "#848484" : "#000",
                }}
                defaultButtonText="İl"
                renderDropdownIcon={() => {
                  return (
                    <Ionicons name="chevron-down" size={20} color="black" />
                  );
                }}
              />
              <SelectDropdown
                data={regions}
                onSelect={(selectedItem, index) => {
                  setBillingRegion(selectedItem);
                  props.changeBillingRegion(selectedItem);
                }}
                buttonStyle={styles.input}
                buttonTextStyle={{
                  textAlign: "left",
                  fontWeight: "600",
                  fontSize: 14,
                  lineHeight: 19.07,
                  color: billingRegion === "" ? "#848484" : "#000",
                }}
                defaultButtonText="İlçe"
                renderDropdownIcon={() => {
                  return (
                    <Ionicons name="chevron-down" size={20} color="black" />
                  );
                }}
              />
              <TextInput
                value={billingNeighbour}
                onChangeText={(e) => {
                  props.changeBillingNeighbour(
                    e.replace(
                      /[^a-zA-Z0-9ğçşüöıĞÇŞÜÖİ.,\/#!$%\^&\*;:{}=\-_`~() ]/g,
                      ""
                    )
                  );
                  setBillingNeighbour(
                    e.replace(
                      /[^a-zA-Z0-9ğçşüöıĞÇŞÜÖİ.,\/#!$%\^&\*;:{}=\-_`~() ]/g,
                      ""
                    )
                  );
                }}
                style={styles.input}
                placeholder="Mahalle"
                maxLength={50}
              />
              <TextInput
                value={billingAddress}
                onChangeText={(e) => {
                  props.changeBillingAddress(
                    e.replace(
                      /[^a-zA-Z0-9ğçşüöıĞÇŞÜÖİ.,\/#!$%\^&\*;:{}=\-_`~() ]/g,
                      ""
                    )
                  );
                  setBillingAddress(
                    e.replace(
                      /[^a-zA-Z0-9ğçşüöıĞÇŞÜÖİ.,\/#!$%\^&\*;:{}=\-_`~() ]/g,
                      ""
                    )
                  );
                }}
                blurOnSubmit={true}
                multiline
                style={[
                  styles.input,
                  {
                    height: 113,
                    paddingTop: 15,
                    textAlignVertical: "top",
                    paddingLeft: 15,
                    paddingRight: 15,
                  },
                ]}
                placeholder="Fatura Adresi"
                maxLength={200}
              />
            </>
          ) : (
            <>
              <TextInput
                value={invoice}
                onChangeText={(e) => {
                  props.changeInvoice(
                    e.replace(
                      /[^a-zA-Z0-9ğçşüöıĞÇŞÜÖİ.,\/#!$%\^&\*;:{}=\-_`~() ]/g,
                      ""
                    )
                  );
                  setInvoice(
                    e.replace(
                      /[^a-zA-Z0-9ğçşüöıĞÇŞÜÖİ.,\/#!$%\^&\*;:{}=\-_`~() ]/g,
                      ""
                    )
                  );
                }}
                style={styles.input}
                placeholder="Fatura Kesilecek Kurum"
                maxLength={50}
              />
              <TextInput
                value={taxNo}
                onChangeText={(e) => {
                  props.changeTaxNo(e.replace(/[^0-9]/g, ""));
                  setTaxNo(e.replace(/[^0-9]/g, ""));
                }}
                style={styles.input}
                placeholder="Vergi Numarası"
                keyboardType="number-pad"
                returnKeyType="done"
                maxLength={11}
              />
              <TextInput
                value={taxAdministration}
                onChangeText={(e) => {
                  props.changeTaxAdministration(
                    e.replace(/[^a-zA-ZğçşüöıĞÇŞÜÖİ. ]/g, "")
                  );
                  setTaxAdministration(
                    e.replace(/[^a-zA-ZğçşüöıĞÇŞÜÖİ. ]/g, "")
                  );
                }}
                style={styles.input}
                placeholder="Vergi İdaresi"
              />
              <TextInput
                value={email}
                onChangeText={(e) => {
                  props.changeEmail(e);
                  setEmail(e);
                }}
                style={styles.input}
                placeholder="E-posta"
                keyboardType="email-address"
              />
              <TextInput
                value={billingPhone}
                onChangeText={(e) => {
                  props.changeBillingPhone(e.replace(/[^0-9]/g, ""));
                  setBillingPhone(e.replace(/[^0-9]/g, ""));
                }}
                keyboardType="number-pad"
                returnKeyType="done"
                maxLength={10}
                style={styles.input}
                placeholder="Telefon Numaranız"
              />
              <SelectDropdown
                data={cities}
                onSelect={(selectedItem, index) => {
                  setBillingCity(selectedItem);
                  props.changeBillingCity(selectedItem);
                  setBillingRegion("");
                  editRegions(selectedItem);
                }}
                buttonStyle={styles.input}
                buttonTextStyle={{
                  textAlign: "left",
                  fontWeight: "600",
                  fontSize: 14,
                  lineHeight: 19.07,
                  color: billingCity === "" ? "#848484" : "#000",
                }}
                defaultButtonText="İl"
                renderDropdownIcon={() => {
                  return (
                    <Ionicons name="chevron-down" size={20} color="black" />
                  );
                }}
              />
              <SelectDropdown
                data={regions}
                onSelect={(selectedItem, index) => {
                  setBillingRegion(selectedItem);
                  props.changeBillingRegion(selectedItem);
                }}
                buttonStyle={styles.input}
                buttonTextStyle={{
                  textAlign: "left",
                  fontWeight: "600",
                  fontSize: 14,
                  lineHeight: 19.07,
                  color: billingRegion === "" ? "#848484" : "#000",
                }}
                defaultButtonText="İlçe"
                renderDropdownIcon={() => {
                  return (
                    <Ionicons name="chevron-down" size={20} color="black" />
                  );
                }}
              />
              <TextInput
                value={billingNeighbour}
                onChangeText={(e) => {
                  props.changeBillingNeighbour(e);
                  setBillingNeighbour(e);
                }}
                style={styles.input}
                placeholder="Mahalle"
              />
              <TextInput
                value={billingAddress}
                onChangeText={(e) => {
                  props.changeBillingAddress(e);
                  setBillingAddress(e);
                }}
                blurOnSubmit={true}
                multiline
                style={[
                  styles.input,
                  {
                    height: 113,
                    paddingTop: 15,
                    textAlignVertical: "top",
                    paddingLeft: 15,
                    paddingRight: 15,
                  },
                ]}
                placeholder="Fatura Adresi"
              />
            </>
          )}
        </View>
      </View>
      <View style={{ marginTop: Platform.OS === "android" ? 30 : 30 }}>
        <Button
          title={"Devam Et"}
          active={
            corporate == true
              ? invoice != "" &&
                taxNo.length == 11 &&
                taxAdministration != "" &&
                email != "" &&
                billingPhone.length == 10 &&
                billingCity != "" &&
                billingRegion != "" &&
                billingNeighbour != "" &&
                billingAddress != ""
              : billingName != "" &&
                billingLastName != "" &&
                identityNo.length == 11 &&
                billingPhone.length == 10 &&
                billingCity != "" &&
                billingRegion != "" &&
                billingNeighbour != "" &&
                billingAddress != ""
          }
          isLoad={buttonLoading}
          handleFunc={() => createOrderID()}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    width: "90%",
    height: 47,
    backgroundColor: "#FAF6F5",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 6,
    borderColor: "#E4E4E4",
    paddingLeft: 11,
    fontSize: 14,
    fontWeight: "600",
    marginBottom: 10,
  },
  outter: {
    width: 20,
    height: 20,
    borderWidth: 1,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 20,
  },
  inner: {
    width: 10,
    height: 10,
    borderRadius: 25,
  },
});
