const SET_NAME = "SET_NAME";
const SET_LASTNAME = "SET_LASTNAME";
const SET_EMAIL = "SET_EMAIL";
const SET_PHONE = "SET_PHONE";
const SET_SMS = "SET_SMS";
const SET_ID = "SET_ID";
// const ADD_PASSWORD = "ADD_PASSWORD";

export const setName = (name) => ({ type: SET_NAME, payload: name });
export const setLastname = (surname) => ({
  type: SET_LASTNAME,
  payload: surname,
});
export const setEmail = (email) => ({ type: SET_EMAIL, payload: email });
export const setPhone = (phone) => ({ type: SET_PHONE, payload: phone });
export const setSMS = (sms) => ({ type: SET_SMS, payload: sms });
export const setID = (id) => ({ type: SET_ID, payload: id });
// export const addPassword = () => ({ type: ADD_PASSWORD });

const registerState = {
  name: "E",
  lastname: "P",
  email: "",
  phone: "",
  sms: "",
  id: "",
  // password: null
};

const registerReducer = (state = registerState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_NAME:
      // console.log({ ...state, name: payload });
      return { ...state, name: payload };
    case SET_LASTNAME:
      // console.log({ ...state, lastname: payload });
      return { ...state, lastname: payload };
    case SET_PHONE:
      // console.log({ ...state, phone: payload });
      return { ...state, phone: payload };
    case SET_EMAIL:
      // console.log({ ...state, email: payload });
      return { ...state, email: payload };
    case SET_SMS:
      // console.log({ ...state, sms: payload });
      return { ...state, sms: payload };
    case SET_ID:
      return { ...state, id: payload };
    default:
      return state;
  }
};

export default registerReducer;
