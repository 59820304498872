import { Platform, StyleSheet, Text, View } from "react-native";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CustomHeader from "../../../components/CustomHeader";
import WebView from "react-native-webview";

export default function Contact({ navigation }) {
  const URL = "https://www.easypoint.com.tr/iletisim-merkezi/";
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <CustomHeader
        image={false}
        title={"İLETİŞİM MERKEZİ"}
        handleFunc={() => navigation.goBack()}
      />
      {Platform.OS === "web" ? (
        <iframe src={URL} height={"100%"} width={"100%"} />
      ) : (
        <View style={{ height: "100%" }}>
          <WebView source={{ uri: URL }} />
        </View>
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({});
