import {
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  Dimensions,
  Platform,
  TouchableOpacity,
  KeyboardAvoidingView,
  ScrollView,
} from "react-native";
import React, { useEffect, useState } from "react";
import { StatusBar } from "expo-status-bar";
import { MaterialIcons } from "@expo/vector-icons";
import Button from "../../components/Button";
import { useSelector, useDispatch } from "react-redux";
import AuthService from "../../services/AuthService";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useBackHandler } from "@react-native-community/hooks";

export default function MobileConfirmationPage({ navigation }) {
  const windowHeight = Dimensions.get("window").height;
  const [time, setTime] = useState(121);
  const phone = useSelector((state) => state.phone);
  const [code, setCode] = useState("");
  const [SMS, setSMS] = useState(`${useSelector((state) => state.sms)}`);

  const SendingSMS = async (phone) => {
    const newSMS = `${Math.floor(Math.random() * (999999 - 100000)) + 100000}`;
    setSMS(newSMS);
    setTime(121);
    setCode("");
    const token = await AsyncStorage.getItem("token");
    AuthService.SendSMS(phone, newSMS, token)
      .then((res) => {
        console.log(res.data.status);
      })
      .catch((err) => console.log(err));
  };

  const Control = (code, SMS) => {
    if (time > 0) {
      if (code === SMS) {
        setTime(0);
        navigation.navigate("PersonalInformationPage");
      } else {
        alert(`Girilen kod hatalıdır.`);
      }
    } else {
      alert(`Süre dolmuştur, tekrar deneyiniz.`);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [time]);

  return (
    <KeyboardAvoidingView style={styles.container} behavior={"height"}>
      <StatusBar style="auto" />
      <ScrollView>
        <View style={{ flexDirection: "column", height: windowHeight }}>
          <Image
            source={require("../../assets/images/logo.png")}
            style={styles.logo}
            resizeMode="contain"
          />
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              marginTop: Platform.OS === "ios" ? 120 : 60,
            }}
          >
            <View>
              <Text style={styles.title}>Mobil Onay</Text>
              <Text style={styles.context}>
                Lütfen cep telefonu numaranızı giriniz.
              </Text>
            </View>
            <View style={styles.input}>
              <TextInput
                value={code}
                onChangeText={(x) => setCode(x)}
                style={{
                  flex: 1,
                  justifyContent: "flex-start",
                  paddingLeft: 11,
                  fontSize: 16,
                  fontWeight: "600",
                }}
                placeholder="Onay Kodu"
                maxLength={6}
                keyboardType="number-pad"
                placeholderTextColor={"#A7A7A7"}
                returnKeyType={"done"}
              />
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text style={{ fontWeight: "600", fontSize: 16 }}>
                  {time}sn
                </Text>
                <MaterialIcons
                  style={{ paddingRight: 15, paddingLeft: 10 }}
                  name="access-time"
                  size={20}
                  color="black"
                />
              </View>
            </View>
            <Button
              active={time !== 0}
              title={"Devam Et"}
              handleFunc={() => Control(code, SMS)}
            />
            <TouchableOpacity
              onPress={() => {
                SendingSMS(phone);
              }}
            >
              <Text
                style={{
                  color: "#DA3831",
                  fontSize: 14,
                  fontWeight: "700",
                  lineHeight: 19,
                  marginTop: 40,
                }}
              >
                Tekrar Kod Gönder
              </Text>
            </TouchableOpacity>

            <View style={{ width: 310 }}>
              <Text style={styles.tryAgainContext}>
                Doğrulama kodu telefonunuza ulaşmadıysa tekrar kod gönder
                butonuna basarak yeni kod alabilirsiniz
              </Text>
            </View>
          </View>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  logo: {
    width: 120,
    alignSelf: "center",
    marginTop: 30,
  },
  title: {
    fontSize: 22,
    fontWeight: "700",
    textAlign: "center",
    marginTop: 50,
  },
  context: {
    fontSize: 14,
    fontWeight: "400",
    textAlign: "center",
    color: "#5E596D",
    marginTop: 8,
    lineHeight: 19.07,
  },
  input: {
    width: 337,
    height: 47,
    backgroundColor: "#FAF6F5",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 6,
    borderColor: "#E4E4E4",
    flexDirection: "row",
    marginTop: 52,
    marginBottom: 40,
  },
  tryAgainContext: {
    fontWeight: "300",
    fontSize: 12,
    color: "#5E596D",
    textAlign: "center",
    paddingTop: 9,
    lineHeight: 16.5,
    paddingHorizontal: 29,
  },
});
