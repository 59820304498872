import { ScrollView, StyleSheet, View, Text } from "react-native";
import React, { useEffect, useState } from "react";
import CampaignCard from "../../../components/CampaignCard";
import { SafeAreaView } from "react-native-safe-area-context";
import CustomHeader from "../../../components/CustomHeader";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Services from "../../../services/Services";

export default function CampaignPage({ navigation }) {
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    AsyncStorage.getItem("token").then((token) => {
      Services.GetCampaigns(token).then((res) => {
        // if (res.status === false) {
        //   setCampaigns(null)
        // }
        if (res.data.status !== false) {
          const datas = res.data.result.map((x) => {
            return {
              title: x.title,
              description: x.description,
              images: JSON.parse(x.images),
            };
          });
          setCampaigns(datas);
        }
      });
    });
  }, []);

  return (
    <SafeAreaView style={styles.container}>
      <CustomHeader image={true} />
      {campaigns.length === 0 ? (
        <View
          style={{
            height: "100%",
            // alignSelf: "flex-start",
          }}
        >
          <Text
            style={{
              marginTop: 25,
              // paddingLeft: 21,
            }}
          >
            Kampanya bulunmamaktadır.
          </Text>
        </View>
      ) : (
        <ScrollView
          style={{ width: "100%", height: "100%" }}
          showsVerticalScrollIndicator={false}
        >
          <View style={{ marginBottom: 40 }}>
            {campaigns.map((campaign, index) => {
              return (
                <CampaignCard
                  key={index}
                  uri={{
                    uri: campaign.images[0],
                  }}
                  title={campaign.title}
                  _navigation={() => {
                    navigation.push("CampaignDetail", {
                      title: campaign.title,
                      description: campaign.description,
                      images: campaign.images,
                    });
                  }}
                />
              );
            })}
          </View>
        </ScrollView>
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#E5E5E5",
    alignItems: "center",
  },
});
